import mithril from "mithril"
import Navbar from "../../../component/navbar"
import LeftMenu from "../../../component/sidebar_warehouse"
import Footer from "../../../component/footer"
import Joi from "@hapi/joi"

import dc_products_model from "../../../models/dc_products"

let product_id = null;
let inserted_product_data = {
    stock:null,
    comment:null
};
let data = [];

module.exports = {
    oninit:(vnode)=>{

        product_id = vnode.attrs.id;

        window.scrollTo(0,0);
    },
    view:()=>{
        return mithril("div", [
            mithril("section", {class:"section"}, [
                mithril(Navbar)
            ]),
            mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                mithril("div", {class:"columns"}, [
                    mithril("div", {class:"column is-one-fifth"}, [
                        mithril(LeftMenu, {title: "products"})
                    ]),
                    mithril("div", {class:"column"}, [
                        mithril("h1", {class:"title"}, "Add Stock Changes"),
                        mithril("button", {"class":"button is-info is-outlined is-pulled-right", onclick: function(){
                            mithril.route.set(`/warehouse/products/${product_id}/stocks`);
                        }},[
                            mithril("span", {"class":"icon is-small"}, 
                                mithril("i", {"class":"fas fa-angle-left"})
                            ),
                            mithril("span", "Back to List")
                        ]),
                        mithril("div",{class:"is-clearfix"}),
                        mithril("form",[
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Current Stock"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"number",
                                        min:0, 
                                        placeholder:"Stock",
                                        oninput: function(e){
                                            inserted_product_data.stock = e.target.value;
                                        }, 
                                        value: inserted_product_data.stock
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Changes Note"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"text", 
                                        placeholder:"Changes Note",
                                        oninput: function(e){
                                            inserted_product_data.comment = e.target.value;
                                        }, 
                                        value: inserted_product_data.comment
                                    })
                                ])
                            ]),
                            //
                            mithril("div", {class:"field"}, [
                                mithril("div", {class:"control"}, [
                                    mithril("button", {type:"button",class:"button is-link is-primary is-pulled-right",
                                        onclick:function(){

                                            const rule = {
                                                stock: Joi.number().greater(0).required(),
                                                comment: Joi.string().required()
                                            };
                                            
                                            const validation = Joi.object(rule).validate(inserted_product_data);
                                            
                                            if (validation.error === undefined) {

                                                dc_products_model.updateProductStockHistory(product_id,inserted_product_data).then(()=>{

                                                    alert('Stock Changes Created Successfully');
                                                    mithril.route.set("/warehouse/products");
                                                }).catch((err)=>{

                                                    alert(err.message);
                                                });
                                            }else{
                                                
                                                alert('Invalid Stock Data, Check Again Your Input');
                                            }
                                        }
                                    }, "Set Changes")
                                ])
                            ])
                            //
                        ])
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
}