import mithril from "mithril"
import Navbar from "../../../component/navbar"
import LeftMenu from "../../../component/leftmenu"
import Footer from "../../../component/footer"
import moment from 'moment';

import product_attributes_model from "../../../models/product_attributes"

let page = 1;
let limit = 10;
let filter_status = undefined;
let datas = null;
let paging = [];

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{
                
                let status_label = null;
                switch(data.status){
                    case 'active':
                        status_label = 'Active';
                        break;
                    case 'inactive':
                        status_label = 'Inactive';
                        break;
                }
                
                let created_at = new Date(data.created_at);
                created_at = moment(created_at);
                let updated_at = (data.updated_at) ? new Date(data.updated_at) : null;
                if(updated_at){

                    updated_at = moment(updated_at);
                }

                return mithril("tr", {}, [
                    mithril('td', data.name),
                    mithril('td', [
                        mithril('span',{class:(data.status == 'active') ? "has-text-success" : "has-text-danger"}, status_label)
                    ]),
                    mithril('td', {}, [
                        mithril("p",created_at.format("DD-MM-YYYY HH:mm")),
                        // mithril("p","staff_name")
                    ]),
                    mithril('td', {}, [
                        mithril("p",(updated_at) ? updated_at.format("DD-MM-YYYY HH:mm") : '-'),
                        // mithril("p","staff_name")
                    ]),
                    mithril("td", 
                        mithril("button", {"class":(data.status == 'active') ? "button is-small is-outlined is-danger" : "button is-small is-outlined is-success",
                            onclick:()=>{

                                if(data.status == 'active'){

                                    product_attributes_model.inactivateAttribute(data._id).then(()=>{

                                        alert('Attribute Inactivated');
                                        _redraw_data(()=>{

                                            mithril.redraw();
                                        });
                                    }).catch((err)=>{
    
                                        alert(err.message);
                                    });
                                }else{

                                    product_attributes_model.activateAttribute(data._id).then(()=>{

                                        alert('Attribute Activated');
                                        _redraw_data(()=>{

                                            mithril.redraw();
                                        });
                                    }).catch((err)=>{
    
                                        alert(err.message);
                                    });
                                }
                            }
                        }, [
                          mithril("span", {"class":"icon"}, 
                            mithril("i", {"class":(data.status == 'active') ? "fa fa-pause" : "fa fa-play"})
                          ),
                          mithril("span", 
                            (data.status == 'active') ? "Inactivate" : "Activate"
                          )
                        ])
                    )
                ])
            })
        }else{

            return mithril("tr", {}, [
                mithril('td', {align:"center",colspan:5},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return mithril("tr", {}, [
            mithril('td', {align:"center",colspan:5},"Loading New Data ...")
        ]);
    }
}

const _redraw_data = (cb)=>{
    
    product_attributes_model.loadDatas(page,limit,filter_status).then(([attribute_datas,paging_data])=>{
    
        datas = attribute_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return mithril("li", [
                mithril("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    mithril.redraw();
                    _redraw_data(()=>{
                        
                        mithril.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return mithril("li", [
                mithril("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

module.exports = {
    oninit:()=>{
        
        window.scrollTo(0,0);
        product_attributes_model.loadDatas(page,limit,filter_status).then(([attribute_datas,paging_data])=>{
            
            datas = attribute_datas;
            paging = paging_data;
        }).catch(()=>{});
    },
    view:()=>{
        return mithril("div", [
            mithril("section", {class:"section"}, [
                mithril(Navbar)
            ]),
            mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                mithril("div", {class:"columns"}, [
                    mithril("div", {class:"column is-one-fifth"}, [
                        mithril(LeftMenu, {title: "product_attributes"})
                    ]),
                    mithril("div", {class:"column"}, [
                        mithril("h1", {class:"title"}, "Product Attributes"),
                        mithril("a", {class:"button is-link is-pulled-right", onclick: function(){
                            mithril.route.set('/products/attributes/add');
                        }}, "Add New"),
                        mithril('div', {class: 'field is-pulled-left'}, [
                            mithril('div', {class: 'control'}, [
                                mithril('div', {class:'select'}, [
                                    mithril('select', {onchange: function(e){
                                    
                                        page = 1;
                                        datas = null;
                                        mithril.redraw();
                                        filter_status = e.target.value;
                                        _redraw_data(()=>{
                                            
                                            mithril.redraw();
                                        });
                                    }}, [
                                        mithril('option', {value: '0', selected: (filter_status === undefined) ? 'true' : ''}, 'All Status'),
                                        mithril('option', {value: 'active', selected: (filter_status == 'active') ? 'true' : ''}, 'Active'),
                                        mithril('option', {value: 'inactive', selected: (filter_status == 'inactive') ? 'true' : ''}, 'Inactive'),
                                    ])
                                ])
                            ])
                        ]),
                        mithril("div",{class:"is-clearfix"}),
                        mithril("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                            mithril("thead", [
                                mithril("tr", [
                                    mithril("th", "Name"),
                                    mithril("th", "Status"),
                                    mithril("th", "Created At"),
                                    mithril("th", "Updated At"),
                                    mithril("th", "")
                                ])
                            ]),
                            mithril("tbody", [
                                handle_data()
                            ])
                        ]),
                        mithril('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                            mithril('a', {class:'pagination-previous', onclick: function(){
                                if(page > 1){

                                    page--;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Previous'),
                            mithril('a', {class:'pagination-next', onclick: function(){
                                if(page < paging.total_page){

                                    page++;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Next page'),
                            mithril('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                        ])
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
};