import m from "mithril"
import Modal from "./modal"

var Navbar = {
    oninit: function(vnode) {
        let self = this;
        this.username = '';
        let url = '/api/auth/get';
        m.request({
            method: "POST",
            url: url,
            body: {},
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data);
                self.username = data.data.username;
            }
            else
            {
                //alert(data.error[0].message);
                if(data.error[0].message == "You're not login")
                {
                    m.request({
                        method: "POST",
                        url: "/api/auth/logout",
                        withCredentials: true,
                    })
                    .then(function(data) {
                        location.href = '/';
                    });
                }
            }
        });
    },
    oncreate: function(){
        
    },
    view: function(vnode) {
        let self = this;
        return m("nav", {class: "navbar has-shadow is-fixed-top", role:"navigation", "aria-label":"main navigation"}, [
            m("div", {class: "navbar-brand"}, [
                m("a", {class: "navbar-item", href:"/"}, [
                    m("img", {src:"/assets/images/logo-bukanagara.png", width:"112", height:"28"})
                ]),
                m("a", {role:"button", class:"navbar-burger burger", 
                onclick: function(el){
                    //console.log(el);
                    const target = el.target.dataset.target;
                    const $target = document.getElementById(target);
                    //console.log(target);
                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    //el.classList.toggle('is-active');
                    $target.classList.toggle('is-hidden-touch');
                }, "aria-label":"menu", "aria-expanded":"false", "data-target":"leftmenu"}, [
                    m("span", {"aria-hidden": "true"}),
                    m("span", {"aria-hidden": "true"}),
                    m("span", {"aria-hidden": "true"})
                ])
            ]),
            m("div", {class:"navbar-menu"}, [
                m("div", {class:"navbar-start"}, [
                    m("a", {class:"navbar-item", href:"/"}, "Bukanagara"),
                    m("a", {class:"navbar-item", href:"/#!/yoyic/report/summary"}, "YoyiC"),
                    m("a", {class:"navbar-item", href:"/#!/warehouse/report/sales"}, "Warehouse"),
                    m("a", {class:"navbar-item"}, "Documentation"),
                    m("div", {class:"navbar-item has-dropdown is-hoverable"}, [
                        m("a", {class:"navbar-link"}, "More"),
                        m("div", {class:"navbar-dropdown"}, [
                            m("a", {class:"navbar-item"}, "About"),
                            m("hr", {class:"navbar-divider"}),
                            m("a", {class:"navbar-item"}, "Help"),
                        ])
                    ])
                ]),
                m("div", {class:"navbar-end"}, [
                    m("div", {class:"navbar-item has-dropdown is-hoverable"}, [
                        m("a", {class:"navbar-link"}, self.username),
                        m("div", {class:"navbar-dropdown"}, [
                            m("a", {class:"navbar-item", onclick: function(){
                                m.route.set('/profile', {});
                            }}, "Profile"),
                            m("hr", {class:"navbar-divider"}),
                            m("a", {class:"navbar-item", onclick: function(){
                                Modal.setTitle("Logout");
                                Modal.setOk("Logout");
                                Modal.setBody("Anda Yakin Akan Logout");
                                Modal.show(function(ok){
                                    if(ok)
                                    {
                                        m.request({
                                            method: "POST",
                                            url: "/api/auth/logout",
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            Modal.close();
                                            location.href = '/';
                                        });
                                        
                                        //m.route.set('/customers', {})
                                    }
                                });
                            }}, "Logout"),
                        ])
                    ])
                ])
            ])
        ])
    }
}
module.exports = Navbar;

