import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"

function outletOptions(datas) {

    return datas.map(function(u) {

        return m('option', {value: u._id}, u.name)
    })
}

module.exports = {
    oninit:function(){

        let self = this;

        self.outlets = [];
        m.request({
            method: "POST",
            url: "/api/outlets/getall",
            body: { limit: 25, page: 1 },
            withCredentials: true,
        }).then(function(data) {
            if(data.result){
                
                self.outlets = [];
                for(let i=0;i<data.data.length;i++){
                    self.outlets.push(data.data[i]);
                }
            }else{
                alert('get outlet gagal');
            }
        });
    },
    view:function(){
        
        let self = this;

        return m("div", [
            m("section", {class:"section"}, [
                m(Navbar)
            ]),
            m("section", {class:"section", style:"margin-top: -120px; "}, [
                m("div", {class:"columns"}, [
                    m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "Export POS Transactions"})
                    ]),
                    m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Export POS Transactions"),
                      m('form',{onsubmit:function(e){

                        e.preventDefault();

                        const frmdata = new FormData(this);
                        
                        window.location.href = `/api/pos_transactions/generate_excel/monthly_tax?outletid=${frmdata.get('outletid')}&month=${frmdata.get('month')}&year=${frmdata.get('year')}`;
                      }},[
                        m("div", {"class":"field is-horizontal"},[
                            m("div", {"class":"field-body"}, [
                                m("div", {"class":"field"}, 
                                    m("p", {"class":"control is-expanded has-icons-left"}, [
                                            m("div", {"class":"select is-fullwidth"}, 
                                                m("select",{name:'year',required:'required'},[
                                                    m("option",{value:2019}, "2019"),
                                                    m("option",{value:2020}, "2020"),
                                                    m("option",{value:2021}, "2021")
                                                ])
                                            ),
                                            m("span", {"class":"icon is-small is-left"}, 
                                                m("i", {"class":"fas fa-calendar"})
                                            )
                                    ])
                                ),
                                m("div", {"class":"field"}, 
                                    m("p", {"class":"control is-expanded has-icons-left"}, [
                                            m("div", {"class":"select is-fullwidth"}, 
                                                m("select",{name:'month',required:'required'},[
                                                    m("option",{value:1}, "Januari"),
                                                    m("option",{value:2}, "Februari"),
                                                    m("option",{value:3}, "Maret"),
                                                    m("option",{value:4}, "April"),
                                                    m("option",{value:5}, "Mei"),
                                                    m("option",{value:6}, "Juni"),
                                                    m("option",{value:7}, "Juli"),
                                                    m("option",{value:8}, "Agustus"),
                                                    m("option",{value:9}, "September"),
                                                    m("option",{value:10}, "Oktober"),
                                                    m("option",{value:11}, "November"),
                                                    m("option",{value:12}, "Desember")
                                                ])
                                            ),
                                            m("span", {"class":"icon is-small is-left"}, 
                                                m("i", {"class":"fas fa-calendar-day"})
                                            )
                                    ])
                                ),
                                m("div", {"class":"field"}, 
                                    m("p", {"class":"control is-expanded has-icons-left has-icons-right"}, [
                                        m("div", {"class":"select is-fullwidth"}, 
                                            m("select",{name:'outletid',required:'required'},outletOptions(self.outlets))
                                        ),
                                        m("span", {"class":"icon is-small is-left"}, 
                                            m("i", {"class":"fas fa-briefcase"})
                                        )
                                    ])
                                ),
                                m("div", {"class":"field"}, 
                                    m("div", {"class":"control"}, 
                                        m("button", {"class":"button is-primary",id:"btn-download"}, 
                                            " Download "
                                        )
                                    )
                                )
                            ])
                        ])
                      ])
                  ])
                ])
            ]),
            m("section", {class:"section"}, [
                m(Footer)
            ])
          ]);
    }
};