import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Notification from "../../component/notification"
import moment from "moment"
import "moment/locale/id"

function rowsInputs(self, cust) {
    return cust.map(function(u) {
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<self.datarows.length;i++)
                {
                    self.datarows[i].selected = false;
                }
                for(var i=0;i<self.datarows.length;i++)
                {
                    if(self.datarows[i]._id == this.getAttribute("strid"))
                    self.datarows[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [u.no]),
            m("td", [ moment(u.created_at).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a')]),
            m("td", [u.nama]),
            m("td", [u.email]),
            m("td", [u.telp]),
            m("td", [u.pekerjaan]),
            m("td", [u.alamat]),
        ])
    })
}

var Soul = {
    oninit: function(vnode) {
        this.datarows = [];
        let self = this;
        this.data = {
            filter: 'All'
        }

        m.request({
            method: "POST",
            url: "/api/sementara/soul/getall",
            body: { limit: 10, page: 1, filter: this.data.filter },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.datarows = [];
                
                for(let i=0;i<data.data.length;i++)
                {
                    data.data[i].no = i+1;
                    self.datarows.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('/api/sementara/soul/getall gagal');
            }
        });
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "Form Pengajian Soul"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Form Pengajian Soul"),
                      m('div', {class: 'field'}, [
                        m('div', {class: 'control'}, [
                            m('div', {class:'select'}, [
                                m('select', {onchange: function(e){
                                    //console.log(e);
                                    self.data.filter = e.target.value;
                                }}, [
                                   // m('option', {value: 'gram'}, 'Gram'),
                                   // m('option', {value: 'kilogram'}, 'KiloGram'),
                                   m('option', {value: 'All', selected: (self.data.filter == 'All') ? 'true' : ''}, 'All'),
                                   m('option', {value: 'Kajian Soul:Merasakan Islam', selected: (self.data.filter == 'Kajian Soul:Merasakan Islam') ? 'true' : ''}, 'Kajian Soul: Merasakan Islam'),
                                   m('option', {value: 'Kajian Soul: Merasakan Al-Quran', selected: (self.data.filter == 'Kajian Soul: Merasakan Al-Quran') ? 'true' : ''}, 'Kajian Soul: Merasakan Al-Quran'),
                                   m('option', {value: 'Kajian Soul: Merasakan Perbedaan dalam Islam', selected: (self.data.filter == 'Kajian Soul: Merasakan Perbedaan dalam Islam') ? 'true' : ''}, 'Kajian Soul: Merasakan Perbedaan dalam Islam'),
                                   m('option', {value: 'Kajian Soul: Membincangkan Tasawuf, Merasakan Islam', selected: (self.data.filter == 'Kajian Soul: Membincangkan Tasawuf, Merasakan Islam') ? 'true' : ''}, 'Kajian Soul: Membincangkan Tasawuf, Merasakan Islam'),
                                   m('option', {value: 'Merasakan Cinta Ilahi dalam pernikahan', selected: (self.data.filter == 'Merasakan Cinta Ilahi dalam pernikahan') ? 'true' : ''}, 'Merasakan Cinta Ilahi dalam pernikahan'),
                                   m('option', {value: 'Merasakan Cinta Ilahi dalam Keluarga', selected: (self.data.filter == 'Merasakan Cinta Ilahi dalam Keluarga') ? 'true' : ''}, 'Merasakan Cinta Ilahi dalam Keluarga'),
                                   m('option', {value: 'Resolusi Awal Tahun: antara Harapan dan Kegalauan', selected: (self.data.filter == 'Resolusi Awal Tahun: antara Harapan dan Kegalauan') ? 'true' : ''}, 'Resolusi Awal Tahun: antara Harapan dan Kegalauan'),
                                   m('option', {value: 'Merasakan Hikmah Perbedaan dalam Islam', selected: (self.data.filter == 'Merasakan Hikmah Perbedaan dalam Islam') ? 'true' : ''}, 'Merasakan Hikmah Perbedaan dalam Islam'),
                                   m('option', {value: 'Merasakan Peran Keluarga di Era Pandemi', selected: (self.data.filter == 'Merasakan Peran Keluarga di Era Pandemi') ? 'true' : ''}, 'Merasakan Peran Keluarga di Era Pandemi'),
                                   m('option', {value: 'Pandemic Parenting: Merasakan Peran Orang Tua di Era Pandemi', selected: (self.data.filter == 'Pandemic Parenting: Merasakan Peran Orang Tua di Era Pandemi') ? 'true' : ''}, 'Pandemic Parenting: Merasakan Peran Orang Tua di Era Pandemi'),
                                   m('option', {value: 'Merasakan Hikmah Peredaran benda-benda langit: refleksi akhir tahun', selected: (self.data.filter == 'Merasakan Hikmah Peredaran benda-benda langit: refleksi akhir tahun') ? 'true' : ''}, 'Merasakan Hikmah Peredaran benda-benda langit: refleksi akhir tahun')
                                ])
                            ]),
                            m('button', {onclick: function(){
                                self.datarows = [];

                                m.request({
                                    method: "POST",
                                    url: "/api/sementara/soul/getall",
                                    body: { limit: 10, page: 1, filter: self.data.filter },
                                    withCredentials: true,
                                })
                                .then(function(data) {
                                    if(data.result)
                                    {
                                        console.log(data.data);
                                        self.datarows = [];
                                        
                                        for(let i=0;i<data.data.length;i++)
                                        {
                                            data.data[i].no = i+1;
                                            self.datarows.push(data.data[i]);
                                        }
                                        //self.categories = data.data;
                                    }
                                    else
                                    {
                                        alert('/api/sementara/soul/getall gagal');
                                    }
                                });
                            },class:'button', style:'margin-left: 5px; '}, 'Filter'),
                            m('button', {onclick: function(){
                                window.location.href = '/api/sementara/soul/getall/trx/excel?filter='+self.data.filter;
                            },class:'button', style:'margin-left: 5px; '}, 'Export')
                        ])
                    ]),
                      m("table", { class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", "No"),
                                m("th", "Tanggal"),
                                m("th", "Name"),
                                m("th", "Email"),
                                m("th", "Phone"),
                                m("th", "Pekerjaan"),
                                m("th", "Alamat")
                            ])
                        ]),
                        m("tbody", [
                          rowsInputs(self, self.datarows)
                        ])
                      ]),
                  ])
                ])
            ])
        ]);
    }
}

module.exports = Soul;