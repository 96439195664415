import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import moment from "moment"
import "moment/locale/id"
import Global from "../../global"
//let glob = Global.getInstance();
var trxdetils = [];



function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

function trxdetiProducts(cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        return m("li", {class: 'list-item', style: 'white-space: nowrap; '}, [ //formatNumber(u.price)
            u.productName + ', Rp ' + glob.number_format(u.price, 0, ',', '.') + ' qty: ' + u.qty + ((u.discount > 0) ? ', diskon: '+ u.discount + '%' : '') 
        ])
    })
}

function trxdetilInputs(cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        if(!u.member)
        {
            u.member = {};
            u.member.name = '';
        }
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<trxdetils.length;i++)
                {
                    trxdetils[i].selected = false;
                }
                for(var i=0;i<trxdetils.length;i++)
                {
                    if(trxdetils[i]._id == this.getAttribute("strid"))
                        trxdetils[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [
                m("label", {class:"checkbox"}, [
                    m("input", {type: "checkbox"})
                ])
            ]),
            m("td", [u.no]),
            m("td", [u.rkode]),
            m("td", [moment(u.enddate).format('dddd, Do MMMM YYYY, h:mm:ss a')]),
            m("td", [u.member.name]),
            m("td", {class: 'has-text-right'}, [u.totalqty]),
            m("td", {class: 'has-text-right'}, [glob.number_format(u.total, 0, ',', '.')]), //formatNumber(u.total)
            m("td", {class: 'has-text-right'}, [glob.number_format(u.pajak, 0, ',', '.')]), //formatNumber(u.pajak)
            m("td", {class: 'has-text-right'}, [glob.number_format(u.totpajak, 0, ',', '.')]), //formatNumber(u.totpajak)
            m("td", {style: 'white-space: nowrap;'}, [ 
                /*m.trust( ((u.cash>0) ? 'CASH : ' + formatNumber(u.cash) + '<br />' : '') + 
                ((u.creditcard>0) ? 'CREDIT CARD : ' + formatNumber(u.creditcard) + '<br />' : '') + 
                ((u.debit>0) ? 'DEBIT CARD : ' + formatNumber(u.debit) + '<br />' : '') +
                ((u.gopay>0) ? 'GOPAY : ' + formatNumber(u.gopay) : '') )*/
                ((u.cash>0) ? m('span', ['CASH : ' + glob.number_format(u.cash, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.cash)
                ((u.creditcard>0) ? m('span', ['CREDIT CARD : ' + glob.number_format(u.creditcard, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.creditcard)
                ((u.debit>0) ? m('span', ['DEBIT CARD : ' + glob.number_format(u.debit, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.debit)
                ((u.gopay>0) ? m('span', ['GOPAY : ' + glob.number_format(u.gopay, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.gopay)
                ((u.ovo>0) ? m('span', ['OVO : ' + glob.number_format(u.ovo, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.ovo)
                ((u.dana>0) ? m('span', ['DANA : ' + glob.number_format(u.dana, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.dana)
                ((u.goresto>0) ? m('span', ['GORESTO : ' + glob.number_format(u.goresto, 0, ',', '.'), m('br')])  : ''),
                ((u.qrcimb>0) ? m('span', ['QR CIMB : ' + glob.number_format(u.qrcimb, 0, ',', '.'), m('br')])  : ''),
                ((u.linkaja>0) ? m('span', ['LinkAja : ' + glob.number_format(u.linkaja, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.linkaja)
                ((u.whatsapp>0) ? m('span', ['WhatsApp : ' + glob.number_format(u.whatsapp, 0, ',', '.'), m('br')])  : ''),
                ((u.tokopedia>0) ? m('span', ['Tokopedia : ' + glob.number_format(u.tokopedia, 0, ',', '.'), m('br')])  : ''),
                ((u.qrbca>0) ? m('span', ['QR BCA : ' + glob.number_format(u.qrbca, 0, ',', '.'), m('br')])  : ''),
                ((u.shopeepay>0) ? m('span', ['ShopeePay : ' + glob.number_format(u.shopeepay, 0, ',', '.'), m('br')])  : ''),
                ((u.jenius>0) ? m('span', ['Jenius : ' + glob.number_format(u.jenius, 0, ',', '.'), m('br')])  : ''),
                ((u.qrmandiri>0) ? m('span', ['QR Mandiri : ' + glob.number_format(u.qrmandiri, 0, ',', '.'), m('br')])  : ''),
                ((u.qrbsi>0) ? m('span', ['QR BSI : ' + glob.number_format(u.qrbsi, 0, ',', '.'), m('br')])  : ''),
                ((u.qrsinarmas>0) ? m('span', ['QR Sinar Mas : ' + glob.number_format(u.qrsinarmas, 0, ',', '.'), m('br')])  : ''),
                ((u.qrcommonwealth>0) ? m('span', ['QR Commonwealth : ' + glob.number_format(u.qrcommonwealth, 0, ',', '.'), m('br')])  : ''),
                ((u.qrjakone>0) ? m('span', ['QR Jakone : ' + glob.number_format(u.qrjakone, 0, ',', '.'), m('br')])  : ''),
                ((u.qrbni>0) ? m('span', ['QR BNI : ' + glob.number_format(u.qrbni, 0, ',', '.'), m('br')])  : ''),
            ]),
            m("td", [
                m('ul', {class:"list"}, trxdetiProducts(u.trxdetail))
            ])
        ])
    })
}

var Detiltransactions = {
    sessionid: '',
  oninit: function(vnode) {
      let self = this;
      this.sessionid = vnode.attrs.id;
      m.request({
          method: "POST",
          url: "/api/postrx/getall/trx",
          body: { sessionid: this.sessionid },
          withCredentials: true,
      })
      .then(function(data) {
          if(data.result)
          {
              console.log(data.data);
              trxdetils = data.data;
              for(var i=0;i<trxdetils.length;i++)
              {
                trxdetils[i].selected = false;
                //categories[i].start = moment(categories[i].start).format('DD-MM-YYYY HH:mm:ss');
              }
          }
          else
          {
              alert('insert categories gagal');
          }
      });
  },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [ 
                    m(LeftMenu, {title: "POS Transactions"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Detail Transaction"),
                    m('button', {class:'button', onclick:function(){
                        //alert('ok');
                        window.location.href = '/api/postrx/getall/trx/excel?sessionid='+self.sessionid;
                    }}, 'Export To Excel'),
                    m('button', {style:"margin-left:1rem;",class:'button', onclick:function(){
                        //alert('ok');
                        window.location.href = '/api/postrx/getall/trx/excel_pajak?sessionid='+self.sessionid;
                    }}, 'Excel Tax Summary'),
                    m('div', {style:'overflow-x: auto;'}, [
                        m("table", {class: "table is-fullwidth is-narrow is-hoverable", style:'font-size: 13px; '}, [
                            m("thead", [
                                m("tr", [
                                    m("th", ""),
                                    m("th", "No"),
                                    m("th", "Kode"),
                                    m("th", "Date"),
                                    m("th", "Member"),
                                    m("th", "Total Qty"),
                                    m("th", "Total"),
                                    m("th", "Pajak"),
                                    m("th", "Total + Pajak"),
                                    m("th", "Payment"),
                                    m("th", "detil")
                                ])
                            ]),
                            m("tbody", [
                                trxdetilInputs(trxdetils)
                            ])
                        ])
                    ]),
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Detiltransactions;