import mithril from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"
import Global from "../../global"
import qs from 'querystring';

import purchase_order_model from "../../models/purchase_order"

let page = 1;
let limit = 10;
let filter_status = 'pay';
let filter_dc = undefined;
let filter_type = undefined;
let datas = null;
let dc_datas = [];
let paging = [];
let glob = Global.getInstance();

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{
                                    
                let total = 0;
                for(let i=0;i<data.detail.length;i++){
                    total += parseInt(data.detail[i].price)*data.detail[i].jumlah;
                }
                
                return mithril("tr", {}, [
                    mithril('td', data.no),
                    mithril('td', data.tanggal),
                    mithril('td', data.kode),
                    mithril('td', data.dc.name),
                    mithril('td', data.outlet),
                    mithril('td', glob.number_format(total, 0)),
                    mithril('td', data.status),
                    mithril("td", [
                        mithril("a", {class:"icon", strid: data._id,
                        onclick: function(){
                            const xhr = new XMLHttpRequest()
                            xhr.open('GET', '/api/distributor/po/get/pdf?id='+data._id, true)
                            xhr.responseType = 'arraybuffer'

                            xhr.onload = function(e) {
                                const blob = new Blob([xhr.response], { type: 'application/pdf' })
                                const url = window.URL.createObjectURL(blob)
                                var a = document.createElement('a');
                                a.target="_blank";
                                a.href=url;
                                a.click();
                            }

                            xhr.send()

                            
                        }}, [
                            mithril("i", {style:'color: red;', class:"far fa-file-pdf"})
                        ])
                    ])
                ])
            })
        }else{

            return mithril("tr", {}, [
                mithril('td', {align:"center",colspan:8},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return mithril("tr", {}, [
            mithril('td', {align:"center",colspan:8},"Loading New Data ...")
        ]);
    }
}

const _redraw_data = (cb)=>{
    
    purchase_order_model.loadData(page,limit,filter_status,filter_dc,filter_type).then(([po_datas,paging_data])=>{
    
        datas = po_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

const _create_query_param = ()=>{

    const query_param = {};

    if(filter_dc !== undefined){

        query_param.dc_id = filter_dc;
    }

    if(filter_status !== undefined){

        query_param.status = filter_status;
    }

    if(filter_type !== undefined){

        query_param.type = filter_type;
    }

    return qs.encode(query_param);
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return mithril("li", [
                mithril("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    mithril.redraw();
                    _redraw_data(()=>{
                        
                        mithril.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return mithril("li", [
                mithril("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

module.exports = {
    oninit:()=>{
        
        window.scrollTo(0,0);
        Promise.all([
            purchase_order_model.loadData(page,limit,filter_status,filter_dc,filter_type),
            purchase_order_model.loadAllDc()
        ]).then(([[po_datas,paging_data],dcs])=>{
            
            datas = po_datas;
            paging = paging_data;
            dc_datas = dcs;
        }).catch(()=>{});
    },
    view:()=>{
        return mithril("div", [
                mithril("section", {class:"section"}, [
                    mithril(Navbar)
                ]),
                mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                    mithril("div", {class:"columns"}, [
                        mithril("div", {class:"column is-one-fifth"}, [
                            mithril(LeftMenu, {title: "purchase_orders"})
                        ]),
                        mithril("div", {class:"column"}, [
                            mithril("h1", {class:"title"}, "Purchase Order"),
                            mithril("a", {class:"button is-link is-pulled-right", href:`/api/purchase_orders/report?${_create_query_param()}`,target:"_blank"}, "Download Excel"),
                            mithril("div",{class:"is-clearfix"}),
                            mithril('div', {class: 'field'}, [
                                mithril('div', {class: 'control'}, [
                                    mithril('div', {class:'select'}, [
                                        mithril('select', {onchange: function(e){
                                            
                                            page = 1;
                                            datas = null;
                                            mithril.redraw();
                                            filter_type = (e.target.value != '0') ? e.target.value : undefined;
                                            _redraw_data(()=>{
                                                
                                                mithril.redraw();
                                            });
                                        }}, [
                                            mithril('option', {value: '0', selected: (filter_type === undefined) ? 'true' : ''}, 'All Type'),
                                            mithril('option', {value: 'order', selected: (filter_type == 'order') ? 'true' : ''}, 'Order'),
                                            mithril('option', {value: 'retur', selected: (filter_type == 'retur') ? 'true' : ''}, 'Retur')
                                        ])
                                    ]),
                                    mithril('div', {class:'select'}, [
                                        mithril('select', {onchange: function(e){
                                            
                                            page = 1;
                                            datas = null;
                                            mithril.redraw();
                                            filter_status = e.target.value;
                                            _redraw_data(()=>{
                                                
                                                mithril.redraw();
                                            });
                                        }}, [
                                            mithril('option', {value: 'pay', selected: (filter_status == 'pay') ? 'true' : ''}, 'Paid'),
                                            mithril('option', {value: 'new', selected: (filter_status == 'new') ? 'true' : ''}, 'New')
                                        ])
                                    ]),
                                    mithril('div', {class:'select'}, [
                                        mithril('select', {onchange: function(e){
                                            
                                            page = 1;
                                            datas = null;
                                            mithril.redraw();
                                            filter_dc = (e.target.value != '0') ? e.target.value : undefined;
                                            _redraw_data(()=>{
                                                
                                                mithril.redraw();
                                            });
                                        }}, [
                                            mithril('option', {value: '0', selected: (filter_dc === undefined) ? 'true' : ''}, 'All DC'),
                                            dc_datas.map((dc_data)=>{

                                                return mithril('option', {value: dc_data._id, selected: (filter_dc == dc_data._id) ? 'true' : ''}, dc_data.name);
                                            })
                                        ])
                                    ])
                                ])
                            ]),
                            mithril("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                            mithril("thead", [
                                mithril("tr", [
                                    mithril("th", ""),
                                    mithril("th", "Tanggal"),
                                    mithril("th", "Kode"),
                                    mithril("th", "DC"),
                                    mithril("th", "Outlet"),
                                    mithril("th", "Total"),
                                    mithril("th", "Status"),
                                    mithril("th", "")
                                ])
                            ]),
                            mithril("tbody", [
                                handle_data()
                            ])
                        ]),
                        mithril('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                            mithril('a', {class:'pagination-previous', onclick: function(){
                                if(page > 1){

                                    page--;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Previous'),
                            mithril('a', {class:'pagination-next', onclick: function(){
                                if(page < paging.total_page){

                                    page++;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                                
                            }}, 'Next page'),
                            mithril('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                        ]),
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
};