import m from "mithril"
import Footer from "../../component/footer"
import Navbar from "../../component/navbar"
import General from "./general"
import Setting from "./setting"
import Role from "./role"

var tabpilih;
var Profile = {
    onClick: function(e){
        //alert('click');
        var elem = document.querySelector('#profiletabs');
        var allElems = elem.querySelectorAll('.itb3');

        for(var i=0;i<allElems.length;i++)
        {
            allElems[i].classList.remove('is-active');
        }

        
        if(e.target)
        {
            tabpilih = e.srcElement.text;
            e.target.parentElement.classList.add("is-active");

            if(tabpilih.toLowerCase() == 'general')
            {
                m.mount(document.getElementById("profiletabcontent"), {view: function () {return m(General, {id: 'sid'})}});
            }
            else if(tabpilih.toLowerCase() == 'setting')
            {
                m.mount(document.getElementById("profiletabcontent"), {view: function () {return m(Setting, {id: 'sid'})}});
            }
            else if(tabpilih.toLowerCase() == 'role')
            {
                m.mount(document.getElementById("profiletabcontent"), {view: function () {return m(Role, {id: 'sid'})}});
            }
        }
            
    },
    oninit: function(vnode) {
      
  
    },
    oncreate: function(vnode){
        tabpilih = "general";
        m.mount(document.getElementById("profiletabcontent"), {view: function () {return m(General, {id: 'sid'})}});
    },
    view: function() {
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px;"}, [
              m("div", {class: 'tabs'}, [
                  m('ul', {id:'profiletabs'}, [
                      m('li', {class: 'itb3 is-active'}, [
                          m('a', {onclick: this.onClick}, 'General')
                      ]),
                      m('li', {class: 'itb3'}, [
                          m('a', {onclick: this.onClick}, 'Setting')
                      ]),
                      m('li', {class: 'itb3'}, [
                          m('a', {onclick: this.onClick}, 'Role')
                      ])
                  ])
              ]),
              m('div', {id:'profiletabcontent'})
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
  }
  
  module.exports = Profile;
