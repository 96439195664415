import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import moment from "moment"
import "moment/locale/id"
import Notification from "../../component/notification"
import Global from "../../global"
//var locale = window.navigator.userLanguage || window.navigator.language;
//console.log('locale : ',locale);

//var trxs = [];



function paginationOld(c, m) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

function pagination(currentPage, nrOfPages) {

	var pagesToShow = 3,
	    page = currentPage - pagesToShow > 0 ?  (currentPage - pagesToShow) : 1,
	    first = 0,
	    pageList = [];

		 for (let i = 0; i < (pagesToShow * 2) && page < nrOfPages; i++) {
			pageList.push(page);
                        page++;

		}
          if(pagesToShow + 2  < currentPage)
	     pageList.unshift('...');
	     pageList.unshift(1); //add first page
	
	  if(nrOfPages > 1){
	         if(nrOfPages - pagesToShow  >  currentPage)
		 pageList.push('...');
	         pageList.push(nrOfPages); //add last page
	  }

	 
    return pageList;

}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  var loadTrxSessions = function(self){

    m.request({
        method: "GET",
        url: "/api/postrx/getall/"+self.limit+"/"+self.current,
        //data: { limit: 10, page: 1 },
        params: {tipe: self.tipe},
        withCredentials: true,
    })
    .then(function(data) {
        if(data.result)
        {
            let trxst = data.data;
            //console.log('trxs ', trxs);
            let mx = Math.ceil(data.count/10);
            self.max = mx;
            //console.log('data.current ', data.current);
            //console.log('mx ', mx);
            let arrp = pagination(data.current, mx);
            let ada1 = false;
            let ada1double = false;
            for(let ii=0;ii<arrp.length;ii++)
            {
                if(ada1)
                {
                    if(arrp[ii] == 1)
                    {
                        ada1double= true;
                    }
                }
                if(!ada1)
                {
                    if(arrp[ii] == 1)
                    {
                        ada1= true;
                    }
                }
            }
            if(ada1double)
            {
                arrp.splice(0, 1);
            }
            //console.log('arrp ', arrp);
            pages = [];
            for(let i=0;i<arrp.length;i++)
            {
              let a = 'a';
              let cl = 'pagination-link';
              let pg = arrp[i];
              let href = '/postransactions/'+self.limit+'/'+pg+'/'+self.store;
              if(arrp[i] == '...')
              {
                  a = 'span';
                  cl = 'pagination-ellipsis';
                  href = '';
              }  
              if(data.current == arrp[i])
              {
                  cl += ' is-current';
                  href = '';
              }
              pages.push({
                  a: a,
                  class: cl,
                  page: pg+'',
                  href: href
              });
            }
            //console.log(pages);
              //console.log(`Selected page ${data.current}:`, pagination(data.current, mx));

            for(var i=0;i<trxst.length;i++)
            {
                trxst[i].selected = false;
            }
            self.trxs = trxst;
            console.log('KESINI ==== 1 ', self.trxs.length);
        }
        else
        {
            console.log("/api/postrx/getall/"+self.limit+"/"+self.current);
            Notification.setBody('Failed to load page');
            Notification.setType('is-danger');
            Notification.show(function(){
                Notification.close();
            });
        }
    });
}

function trxInputs(self, trxs) {
    //console.log('KESINI ==== 2 ', trxs);
    let glob = Global.getInstance();
    return trxs.map(function(u) {
        let stop = moment(u.stop);
        if(stop.isValid())
        {
            stop = moment(u.stop).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a');
        }
        else
        {
            stop = '';
        }
        //console.log('ok ', u);
        //console.log('u ', ((u.cash>0) ? 'CASH : ' + formatNumber(u.cash) + '<br />' : ''));

        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<trxs.length;i++)
                {
                    trxs[i].selected = false;
                }
                for(var i=0;i<trxs.length;i++)
                {
                    if(trxs[i]._id == this.getAttribute("strid"))
                        trxs[i].selected = true;
                }
                //console.log(trxs);
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            /*m("td", [
                m("label", {class:"checkbox"}, [
                    m("input", {type: "checkbox"})
                ])
            ]),*/
            m("td", [u.no]),
            m("td", [moment(u.start).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a')]),
            m("td", [stop]),
            m("td", [u.kasir_name]),
            m("td", [u.store_name]),
            m("td", {class: 'has-text-right'}, [u.jumlah]),
            m("td", {class: 'has-text-right'}, [ glob.number_format(u.total, 0, ',', '.') ]), //; formatNumber(u.total)
            m("td", {class: 'has-text-right'}, [u.totalqty]),
            m("td", {style: 'white-space: nowrap;'}, [
                ((u.cash>0) ? m('span', ['CASH : ' + glob.number_format(u.cash, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.cash)
                ((u.creditcard>0) ? m('span', ['CREDIT CARD : ' + glob.number_format(u.creditcard, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.creditcard)
                ((u.debit>0) ? m('span', ['DEBIT CARD : ' + glob.number_format(u.debit, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.debit)
                ((u.gopay>0) ? m('span', ['GOPAY : ' + glob.number_format(u.gopay, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.gopay)
                ((u.ovo>0) ? m('span', ['OVO : ' + glob.number_format(u.ovo, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.ovo)
                ((u.dana>0) ? m('span', ['DANA : ' + glob.number_format(u.dana, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.dana)
                ((u.goresto>0) ? m('span', ['GORESTO : ' + glob.number_format(u.goresto, 0, ',', '.'), m('br')])  : ''),
                ((u.qrcimb>0) ? m('span', ['QR CIMB : ' + glob.number_format(u.qrcimb, 0, ',', '.'), m('br')])  : ''),
                ((u.whatsapp>0) ? m('span', ['WhatsApp : ' + glob.number_format(u.whatsapp, 0, ',', '.'), m('br')])  : ''),
                ((u.tokopedia>0) ? m('span', ['Tokopedia : ' + glob.number_format(u.tokopedia, 0, ',', '.'), m('br')])  : ''),
                ((u.linkaja>0) ? m('span', ['LinkAja : ' + glob.number_format(u.linkaja, 0, ',', '.'), m('br')])  : ''), //formatNumber(u.linkaja)
                ((u.qrbca>0) ? m('span', ['QR BCA : ' + glob.number_format(u.qrbca, 0, ',', '.'), m('br')])  : ''),
                ((u.shopeepay>0) ? m('span', ['ShopeePay : ' + glob.number_format(u.shopeepay, 0, ',', '.'), m('br')])  : ''),
                ((u.jenius>0) ? m('span', ['Jenius : ' + glob.number_format(u.jenius, 0, ',', '.'), m('br')])  : ''),
                ((u.qrmandiri>0) ? m('span', ['QR Mandiri : ' + glob.number_format(u.qrmandiri, 0, ',', '.'), m('br')])  : ''),
                ((u.qrbsi>0) ? m('span', ['QR BSI : ' + glob.number_format(u.qrbsi, 0, ',', '.'), m('br')])  : ''),
                ((u.qrsinarmas>0) ? m('span', ['QR Sinar Mas : ' + glob.number_format(u.qrsinarmas, 0, ',', '.'), m('br')])  : ''),
                ((u.qrcommonwealth>0) ? m('span', ['QR Commonwealth : ' + glob.number_format(u.qrcommonwealth, 0, ',', '.'), m('br')])  : ''),
                ((u.qrjakone>0) ? m('span', ['QR Jakone : ' + glob.number_format(u.qrjakone, 0, ',', '.'), m('br')])  : ''),
                ((u.qrbni>0) ? m('span', ['QR BNI : ' + glob.number_format(u.qrbni, 0, ',', '.'), m('br')])  : ''),
            ]),
            /*m("td", [ 
                m.trust(((u.cash>0) ? 'CASH : ' + formatNumber(u.cash) + '<br />' : '') + 
                ((u.creditcard>0) ? 'CREDIT CARD : ' + formatNumber(u.creditcard) + '<br />' : '') + 
                ((u.debit>0) ? 'DEBIT CARD : ' + formatNumber(u.debit) + '<br />' : '') +
                ((u.gopay>0) ? 'GOPAY : ' + formatNumber(u.gopay) : '') )
            ]),*/
            m("td", [
                m("a", {class:"icon", strid: u.uuid,
                onclick: function(){
                    console.log(this.getAttribute("strid"));
                    m.route.set('/postransactions/view/:id', {id: this.getAttribute("strid")})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

var pages = [];
function SessionsPages(self, cust) {
    return cust.map(function(u) {
        return m("li", [
            m(u.a, {onclick: function(){
                if(u.href.length>0)
                {
                    //console.log(u.href);
                    m.route.set(u.href);
                    //loadProduct(self);
                    //m.redraw();
                    //alert('test');
                    location.reload();
                    //m.redraw();
                    //location.hash = u.href;
                }
            }, class:u.class}, m.trust(u.page))
            //m('a', {class:'pagination-link'}, m.trust('1'))
        ])
    })
}

function categoryInputs(selected, cust) {
    return cust.map(function(u) {
        //return m('option', {value: u._id}, u.name)
        return m('option', {value: u._id, selected: (selected == u._id) ? 'true' : ''}, u.name)
    })
}

var Postransactions = {
    current: 1,
    limit: 10,
    max: 1,
  oninit: function(vnode) {
    let self = this;
    self.trxs = [];
    self.tipe = vnode.attrs.store;
    self.store = vnode.attrs.store;
    self.current = parseInt(vnode.attrs.page);
    self.limit = parseInt(vnode.attrs.limit);

    self.categories = [];
      m.request({
            method: "POST",
            url: "/api/outlets/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.categories = [];
                self.categories.push({
                    _id: 'ALL',
                    name: 'ALL'
                });
                for(let i=0;i<data.data.length;i++)
                {
                    self.categories.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('insert categories gagal');
            }
        });
        
    loadTrxSessions(self);

      /*m.request({
          method: "POST",
          url: "/api/postrx/getall",
          data: { limit: 10, page: 1 },
          withCredentials: true,
      })
      .then(function(data) {
          if(data.result)
          {
              console.log(data.data);
              trxs = data.data;
              for(var i=0;i<trxs.length;i++)
              {
                trxs[i].selected = false;
                //categories[i].start = moment(categories[i].start).format('DD-MM-YYYY HH:mm:ss');
              }
          }
          else
          {
              alert('insert categories gagal');
          }
      });*/
  },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "POS Transactions"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "POS Transactions"),
                    m("div", {class:"field is-grouped"}, [
                        m("div", {class:"control"}, [
                            m('div', {class:'select'}, [
                                m('select', {
                                    onchange: function(e){
                                        //console.log(e);
                                        self.tipe = e.target.value;
                                        self.store = e.target.value;
                                        loadTrxSessions(self);
                                        console.log('self.tipe ', self.tipe);
                                        //loadProduct(self.tipe, self);
                                    }}, [
                                    categoryInputs(self.store, self.categories)
                                ])
                            ])
                        ])
                    ]),
                    m('div', {style:'overflow-x: auto;'}, [
                        m("table", {class: "table is-fullwidth is-narrow is-hoverable", style:'font-size: 13px; '}, [
                            m("thead", [
                                m("tr", [
                                    //m("th", ""),
                                    m("th", "No"),
                                    m("th", "Mulai"),
                                    m("th", "Selesai"),
                                    m("th", "Kasir"),
                                    m("th", "Store"),
                                    m("th", "Qty"),
                                    m("th", "Total"),
                                    m("th", "Total Qty"),
                                    m("th", "Payment"),
                                    m("th", "")
                                ])
                            ]),
                            m("tbody", [
                              trxInputs(self, self.trxs)
                            ])
                          ]),
                    ]),
                    m('nav', {class: 'pagination', style: 'margin-top: 30px; ', role:"navigation", "aria-label": 'pagination'}, [
                        m('a', {class:'pagination-previous', onclick: function(){
                            if(self.current > 1)
                            {
                                m.route.set('/postransactions/'+self.limit+'/'+(self.current-1)+'/'+self.store);
                                location.reload();
                            }
                            
                        }}, 'Previous'),
                        m('a', {class:'pagination-next', onclick: function(){
                            if(self.current < self.max)
                            {
                                m.route.set('/postransactions/'+self.limit+'/'+(self.current+1)+'/'+self.store);
                                location.reload();
                            }
                            
                        }}, 'Next page'),
                        m('ul', {class: 'pagination-list'}, [ SessionsPages(self, pages) ] )
                    ]),
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Postransactions;