import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"
import Global from "../../global"
import flatpickr from "flatpickr";
import moment from 'moment';

function categoryInputs(cust) {
    return cust.map(function(u) {
        return m('option', {value: u._id}, u.name)
    })
  }

function ListTableDetail(self, cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        return m("div", {class:'columns'}, [
            m("div", {class:'column is-half'}, 'bill : ' + glob.number_format(u.bill, 0, ',', '.')),
            m("div", {class:'column is-half'}, 'Pay : ' + glob.number_format(u.pay, 0, ',', '.'))
        ])
    })
}

function ListTable(self, cust) {
    return cust.map(function(u) {
        return m("tr", {}, [
            m('td', ''),
            m('td', u.tanggal),
            m('td', u.name),
            m('td', u.number),
            m('td', u.tipe),
            m('td', ListTableDetail(self, u.data)),
            /*m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    //m.route.set('/dc/salesman/edit/:pid/:id/:name', {pid: u._id, id: u.dcid, name: self.name})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])*/
        ])
    })
}

function loadData(self){
    m.request({
        method: "POST",
        url: "/api/payment/getall",
        body: { 
            limit: 10, 
            page: 1,
            dcid: self.tipe,
            from: self.DateFrom.format('DD-MM-YYYY'),
            to: self.DateTo.format('DD-MM-YYYY')
        },
        withCredentials: true,
    })
    .then(function(data) {
        if(data.result)
        {
            //console.log(data.data);
            self.datas = data.data;
            let total = 0;
            for(var i=0;i<self.datas.length;i++)
            {
                self.datas[i].selected = false;
                for(let x= 0;x<self.datas[i].data.length;x++)
                {
                    total += self.datas[i].data[x].pay;
                }
            }

            self.total_jumlah = self.datas.length;
            self.total_rupiah = total;
            console.log(self.total_rupiah)
        }
        else
        {
            alert('insert outlets gagal');
        }
    });
}
var Payment = {
    oncreate: function(){
        let self = this;
        this.DateFrom =  moment().subtract(1, 'weeks');
        this.DateTo = moment();
        flatpickr('#tanggalan', {
            mode: "range",
            minDate: "2018-01", //"today",
            dateFormat: "d-m-Y",
            //minDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
            defaultDate: [this.DateFrom.format('DD-MM-YYYY'), this.DateTo.format('DD-MM-YYYY')],
            onReady: function(){
                console.log('ok ready tanggalan');
                loadData(self);
                //self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
            },
            onChange: function(selectedDates, dateStr, instance) {
            if(selectedDates.length == 2)
            {
                self.DateFrom =  moment(selectedDates[0]);
                self.DateTo = moment(selectedDates[1]);
            }
            //console.log(selectedDates);
            //console.log(dateStr);
            }
        });
    },
    oninit: function(vnode){
        let self = this;
        this.total_jumlah = 0;
        this.total_rupiah = 0;
        this.filteroutletname = '';
        this.datas = [];
        this.tipe = 'ALL';

        this.categories = [];
        m.request({
              method: "POST",
              url: "/api/dc/getall",
              body: { limit: 10, page: 1 },
              withCredentials: true,
          })
          .then(function(data) {
              if(data.result)
              {
                  console.log(data.data);
                  self.categories = [];
                  self.categories.push({
                      _id: 'ALL',
                      name: 'ALL'
                  });
                  for(let i=0;i<data.data.length;i++)
                  {
                      self.categories.push(data.data[i]);
                  }
                  //self.categories = data.data;
                    
              }
              else
              {
                  alert('insert categories gagal');
              }
          });

        
    },
    view: function() {
        let self = this;
        let glob = Global.getInstance();
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "payment"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Payment"),
                    m('div', {class: 'control'}, [

                        m('div', {class:'columns'}, [
                            m('div', {class:'column is-one-thirds'}, [
                              m('input', {id:'tanggalan', type:'text', class:'input', placeholder:'Pilih Tanggal'})
                            ]),
                            m('div', {class:'column'}, [
                              m("div", {class:"field is-grouped"}, [
                                  m("div", {class:"control"}, [
                                      m('div', {class:'select'}, [
                                          m('select', {
                                              onchange: function(e){
                                                  //console.log(e);
                                                  self.tipe = e.target.value;
                                                  //loadProduct(self.tipe, self);
                                              }}, [
                                              categoryInputs(self.categories)
                                          ])
                                      ])
                                  ]),
                                  m("div", {class:"control"}, [
                                    m('a', {class:'button', onclick: function(){
                                        loadData(self);
                                      //self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                                    }}, 'Generate'),

                                    
                                  ]),
                                  m('div', {class: 'field is-grouped is-pulled-right'}, [
                                    m("a", {class:"button is-link", onclick: function(){
                                        //mithril.route.set('/dc/products/add');
                                        //session id tidak di set
                                        if(self.filteroutletname.length == 0)
                                        {
                                            window.location.href = '/api/payment/getall/excel?sessionid=sessionid';
                                        }
                                        else
                                        {
                                            window.location.href = '/api/payment/getall/excel/filter/outlet?sessionid=sessionid&outletname='+self.filteroutletname;
                                        }
                                        
                                    }}, "Export Excels"),
                                ]),
                              ]),
                              /*m('a', {class:'button', onclick: function(){
                                self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                              }}, 'Generate')*/
                            ])
                          ]),

                        /*('div', {class: 'field is-grouped is-pulled-left'}, [
                            m('p', {class: 'control is-expanded'}, [
                                m('input', {oninput: function(e){
                                    self.filteroutletname = e.target.value;
                                }, type:'text', class:'input', placeholder:'Pilih Outlet'}),
                            ]),
                            m('p', {class: 'control'}, [
                                m('a', {onclick: function(){
                                    
                                    if(self.filteroutletname.length == 0)
                                    {
                                        m.request({
                                            method: "POST",
                                            url: "/api/payment/getall",
                                            body: { limit: 10, page: 1 },
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            if(data.result)
                                            {
                                                console.log(data.data);
                                                self.datas = data.data;
                                                for(var i=0;i<self.datas.length;i++)
                                                {
                                                    self.datas[i].selected = false;
                                                }
                                            }
                                            else
                                            {
                                                alert('insert outlets gagal');
                                            }
                                        });
                                    }
                                    else
                                    {
                                        m.request({
                                            method: "POST",
                                            url: "/api/payment/getall/filter/outlet",
                                            body: { outletname: self.filteroutletname },
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            if(data.result)
                                            {
                                                console.log(data.data);
                                                self.datas = data.data;
                                                for(var i=0;i<self.datas.length;i++)
                                                {
                                                    self.datas[i].selected = false;
                                                }
                                            }
                                            else
                                            {
                                                alert('insert outlets gagal');
                                            }
                                        });
                                    }
                                    

                                }, class: 'button'}, 'Filter'),
                            ]),
                        ]),*/
                        
                        /*m('div', {class: 'field is-grouped is-pulled-right'}, [
                            m("a", {class:"button is-link", onclick: function(){
                                //mithril.route.set('/dc/products/add');
                                //session id tidak di set
                                if(self.filteroutletname.length == 0)
                                {
                                    window.location.href = '/api/payment/getall/excel?sessionid=sessionid';
                                }
                                else
                                {
                                    window.location.href = '/api/payment/getall/excel/filter/outlet?sessionid=sessionid&outletname='+self.filteroutletname;
                                }
                                
                            }}, "Export Excels"),
                        ]),*/
                    ]),
                    m('hr'),
                    m('div', [
                        m('div', 'Total Jumlah : ' + self.total_jumlah),
                        m('div', 'Total Rupiah : ' + glob.number_format(self.total_rupiah, 0, ',', '.'))
                    ]),
                    m('br'),
                    m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                      m("thead", [
                          m("tr", [
                              m("th", ""),
                              m("th", "Tanggal"),
                              m("th", "Name"),
                              m("th", "VA"),
                              m("th", "Tipe"),
                              m("th", "Jumlah"),
                              //m("th", "")
                          ])
                      ]),
                      m("tbody", [
                        ListTable(self, self.datas)
                      ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = Payment;