import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"

var outlets = [];

function outletInputs(cust) {
    return cust.map(function(u) {
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<outlets.length;i++)
                {
                  outlets[i].selected = false;
                }
                for(var i=0;i<outlets.length;i++)
                {
                    if(outlets[i]._id == this.getAttribute("strid"))
                    outlets[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [
                m("label", {class:"checkbox"}, [
                    m("input", {type: "checkbox"})
                ])
            ]),
            m("td", [u.no]),
            m("td", [u.name]),
            m("td", [u.address]),
            m("td", [u.phone]),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    console.log(this.getAttribute("strid"));
                    m.route.set('/users/edit/:id', {id: this.getAttribute("strid")})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

var Users = {
  oninit: function(vnode) {
      m.request({
          method: "POST",
          url: "/api/users/getall",
          body: { limit: 10, page: 1 },
          withCredentials: true,
      })
      .then(function(data) {
          if(data.result)
          {
              console.log(data.data);
              outlets = data.data;
              for(var i=0;i<outlets.length;i++)
              {
                outlets[i].selected = false;
              }
          }
          else
          {
              alert('insert outlets gagal');
          }
      });
  },
    view: function() {
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "users"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Users"),
                    m("div", {class:"field is-grouped"}, [
                        m("p", {class:"control"}, [
                            m("a", {class:"button is-link", href: "#!/users/add"}, "Add New")
                        ])
                    ]),
                    m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                      m("thead", [
                          m("tr", [
                              m("th", ""),
                              m("th", "No"),
                              m("th", "Name"),
                              m("th", "Address"),
                              m("th", "Phone"),
                              m("th", "")
                          ])
                      ]),
                      m("tbody", [
                        outletInputs(outlets)
                      ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Users;