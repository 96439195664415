import mithril from 'mithril';
import qs from 'querystring';

module.exports = {
    loadDatas:(page,limit,status)=>{

        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page
            };

            if(status !== undefined){

                query_param.status = status;
            }

            mithril.request({
                method: "GET",
                url: `/api/product_attributes?${qs.encode(query_param)}`,
                withCredentials: true
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve([res_attributes.data,res_attributes.pagination]);
                }else if(res_attributes.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadAll:()=>{

        return new Promise((resolve, reject) => {

            mithril.request({
                method: "GET",
                url: `/api/product_attributes/all`,
                withCredentials: true
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve(res_attributes.data);
                }else if(res_attributes.code == 404){

                    resolve([]);
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    addAttribute:(attribute_data)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "POST",
                url: `/api/product_attributes`,
                withCredentials: true,
                body:attribute_data
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    updateAttribute:(attribute_id,update_data)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "PUT",
                url: `/api/product_attributes/${attribute_id}`,
                withCredentials: true,
                body:update_data
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    activateAttribute:(attribute_id)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "PUT",
                url: `/api/product_attributes/${attribute_id}/activate`,
                withCredentials: true
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    inactivateAttribute:(attribute_id)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "PUT",
                url: `/api/product_attributes/${attribute_id}/inactivate`,
                withCredentials: true
            }).then((res_attributes)=>{

                if(res_attributes.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_attributes.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    }
};