var m = require("mithril")

var mDc = {
    list: [],
    loadList: function() {
        return m.request({
            method: "POST",
            url: "/api/dc/getall",
            withCredentials: true,
            body: {
                limit: 10,
                page: 0
            }
        })
        .then(function(result) {
            if(result.result)
            {
                mDc.list = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    },

    current: {},
    load: function(id) {
        if(id == 'new')
        {
            mDc.current = {
                kode: '',
                name: '',
                city: '',
                address: '',
                phone: '',
                contact: '',
                email: '',
                latitude: '',
                longitude: ''
            }
        }
        else
        {
            return m.request({
                method: "POST",
                url: "/api/dc/get", 
                withCredentials: true,
                body: {
                    id: id
                }
            })
            .then(function(result) {
                if(result.result)
                {
                    mDc.current = result.data
                }
                else{
                    if(result.error[0].message == "You're not login")
                    {
                        m.route.set('/login');
                    }
                }
            })
        }
        
    },

    save: function() {
        return m.request({
            method: "POST",
            url: "/api/dc/insert",
            body: mDc.current,
            withCredentials: true,
        })
        .then(function(result) {
            if(result.result)
            {
                //
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    }
}

module.exports = mDc