import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"
import Global from "../../global"

function ListTable(self, cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        return m("tr", {}, [
            m('td', u.no),
            m('td', u.name),
            m('td', {class:'has-text-right'}, glob.number_format(u.outlets, 0)),
            m('td', {class:'has-text-right'}, glob.number_format(u.poretur, 0)),
            m('td', {class:'has-text-right'}, glob.number_format(u.po, 0)),
            m('td', {class:'has-text-right'}, glob.number_format(u.popaid, 0)),
            m('td', {class:'has-text-right'}, glob.number_format(u.popaidqty, 0)),
            m('td', {class:'has-text-right'}, glob.number_format(u.popaidrp, 0)),
            m('td', [
                m("a", {class:"icon",
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    m.route.set('/dc/summarydetail/:dcname/:dcid', {dcname: u.name, dcid: u._id})
                }}, [
                    m("i", {class:"fas fa-eye"})
                ])
            ])
        ])
    })
}

var DcSummary = {
    oninit: function(vnode){
        let self = this;
        this.data = [];
        //mDc.loadList()
        m.request({
            method: "POST",
            url: "/api/dc/get/summary",
            withCredentials: true,
            body: {
                limit: 10,
                page: 0
            }
        })
        .then(function(result) {
            if(result.result)
            {
                self.data = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "dcsummary"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "DC Summary"),
                      /*m("div", {class:"field is-grouped"}, [
                          m("p", {class:"control"}, [
                              m("a", {class:"button is-link", href: "#!/dc/add/new"}, "Add New")
                          ])
                      ]),*/
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "No"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Name"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Outlet"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Total Po Retur"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Total Po"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Po Paid"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Po Paid Qty"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "Po Paid Rp"),
                                m("th", {style:'background-color: #95a5a6; color: white; '}, "")
                            ])
                        ]),
                        m("tbody", [
                            ListTable(self, self.data)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = DcSummary;