import m from "mithril"

var Footer = {
    view: function(vnode) {
        return m("footer", {class: "footer"}, [
            m("div", {class:"content has-text-centered"}, [
                m("p", [
                    m("strong", "Bukanagara"),
                    " by ",
                    m("a", {href:"https://bukanagara.coffee"}, "BUKANAGARA"),
                    ". coffee cup "
                ])
            ])
        ])
    }
}

module.exports = Footer;

