import mithril from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"
import '../../../node_modules/flatpickr/dist/flatpickr.min.css';
import '../../../node_modules/tabulator-tables/dist/css/tabulator.min.css';
import flatpickr from "flatpickr";
// import Tabulator from "tabulator-tables";
import Chart from 'chart.js';
import {each,eachSeries} from 'async';
import moment from 'moment';

import purchase_order_model from "../../models/purchase_order"

const _numberWithCommas = (x) => {
    if(x == undefined)
        return x;
    if (typeof x === 'string' || x instanceof String)
        return x;
    x = Math.round(x);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const _getRandomColor = ()=>{
    
    return "#"+Math.floor(Math.random()*16777215).toString(16);
}

const _redraw_data = (cb)=>{
    
    purchase_order_model.loadReport(start_date,end_date,dc_id).then((report_datas)=>{
    
        summary_datas = report_datas;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

let chart_instance = null;
// let table_instance = null;
let start_date = moment(new Date()).subtract(4,'days').format('DD-MM-YYYY');
let end_date = moment(new Date()).format('DD-MM-YYYY');
let dc_id = undefined;
let summary_datas = [];
let all_dc_datas = [];

const _generateChart = (chart_data)=>{
    
    if(chart_instance){

        chart_instance.destroy();
    }
    
    let ctx = document.getElementById('report_chart');
    let labels = [];
    let datas = [];
    
    let dc_datas = [];

    each(chart_data[0].dc_datas,(dc_data,cb)=>{

        dc_datas.push({
            dcid:dc_data._id,
            name:dc_data.name
        });

        cb();
    },()=>{

        let details = {};
        let value_datas = {};
        
        let retur_details = {};
        let retur_value_datas = {};
        
        eachSeries(chart_data,(item,cb)=>{
            
            labels.push(item.date);

            eachSeries(item.dc_datas,(dc_data,cb2)=>{

                if(details[dc_data._id] === undefined){

                    details[dc_data._id] = [];
                    value_datas[dc_data._id] = [];
                    retur_details[dc_data._id] = [];
                    retur_value_datas[dc_data._id] = [];
                }

                if(dc_data.order){
                
                    details[dc_data._id].push({
                        date:dc_data.order.date,
                        type:dc_data.order.type,
                        transactions:dc_data.order.total_transaction,
                        items:dc_data.order.total_item,
                        qty:dc_data.order.total_qty
                    });
                    value_datas[dc_data._id].push(dc_data.order.total_transaction);
                }else{
    
                    details[dc_data._id].push({
                        date:item.date,
                        type:'order',
                        transactions:0,
                        items:0,
                        qty:0
                    });
                    value_datas[dc_data._id].push(0);
                }
                
                if(dc_data.retur){
                    
                    retur_details[dc_data._id].push({
                        date:dc_data.retur.date,
                        type:dc_data.retur.type,
                        transactions:dc_data.retur.total_transaction,
                        items:dc_data.retur.total_item,
                        qty:dc_data.retur.total_qty
                    });
                    retur_value_datas[dc_data._id].push(dc_data.retur.total_transaction);
                }else{
    
                    retur_details[dc_data._id].push({
                        date:item.date,
                        type:'retur',
                        transactions:0,
                        items:0,
                        qty:0
                    });
                    retur_value_datas[dc_data._id].push(0);
                }

                cb2();
            },()=>{
            
                cb();
            });
        },()=>{
            
            eachSeries(dc_datas,(dc_data,cb)=>{

                datas.push({
                    dcid:dc_data.dcid,
                    dc_name:dc_data.name,
                    label:`${dc_data.name}[Order]`,
                    backgroundColor: _getRandomColor(),
                    borderColor:_getRandomColor(),
                    borderWidth:1,
                    datadetail:details[dc_data.dcid],
                    data:value_datas[dc_data.dcid]
                });

                datas.push({
                    dcid:dc_data.dcid,
                    dc_name:dc_data.name,
                    label:`${dc_data.name}[Retur]`,
                    backgroundColor: _getRandomColor(),
                    borderColor:_getRandomColor(),
                    borderWidth:1,
                    datadetail:retur_details[dc_data.dcid],
                    data:retur_value_datas[dc_data.dcid]
                });

                cb();
            },()=>{
                
                chart_instance = new Chart(ctx,{
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: datas
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        tooltips: {
                          callbacks: {
                              label: function(tooltipItem) {
                                  
                                  return 'Rp '+_numberWithCommas(tooltipItem.yLabel);
                              }
                          }
                      },
                      maintainAspectRatio: false,
                      scales:{
                          xAxes: [{
                              ticks: {
                                  beginAtZero:true,
                                  callback: function(value) {
                                      return value ;
                                  }
                              }
                          }],
                          yAxes: [{
                              ticks: {
                                  beginAtZero:true,
                                  callback: function(value) {
                                      return 'Rp '+_numberWithCommas(value);
                                  }
                              }
                          }]
                      }
                    }
                });
            });
        });
    });
}

module.exports = {
    oninit:()=>{
        
        window.scrollTo(0,0);
        purchase_order_model.loadAllDc().then((dcs)=>{
            
            all_dc_datas = dcs;
        }).catch(()=>{});
    },
    oncreate:()=>{
        
        flatpickr('#datepicker', {
            mode: "range",
            minDate: "2018-01", //"today",
            dateFormat: "d-m-Y",
            //minDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
            defaultDate: [start_date, end_date],
            onReady: function(){
                
                _redraw_data(()=>{

                    _generateChart(summary_datas);
                });
            },
            onChange: function(selectedDates) {
                
                if(selectedDates.length == 2){
                    start_date =  moment(selectedDates[0]).format('DD-MM-YYYY');
                    end_date = moment(selectedDates[1]).format('DD-MM-YYYY');
                    
                    _redraw_data(()=>{
                        
                        _generateChart(summary_datas);
                    });
                }
            }
        });
    },
    view: ()=>{
        
        return mithril("div", [
            mithril("section", {class:"section"}, [
                mithril(Navbar)
            ]),
            mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                mithril("div", {class:"columns"}, [
                    mithril("div", {class:"column is-one-fifth"}, [
                      mithril(LeftMenu, {title: "po_report"})
                    ]),
                    mithril("div", {class:"column"}, [
                      mithril("h1", {class:"title"}, "Purchase Order"),
                      mithril('div', {class:'columns'}, [
                        mithril('div', {class:'column is-one-thirds'}, [
                          mithril('input', {id:'datepicker', type:'text', class:'input', placeholder:'Pilih Tanggal'}),
                        ]),
                        mithril('div', {class:'column'}, [
                          mithril("div", {class:"field"}, [
                              mithril("div", {class:"control"}, [
                                  mithril('div', {class:'select'}, [
                                      mithril('select', {onchange: function(e){
                                          
                                          dc_id = (e.target.value != '0') ? e.target.value : undefined;
                                          _redraw_data(()=>{
                                              
                                            _generateChart(summary_datas);
                                          });
                                      }}, [
                                          mithril('option', {value: '0', selected: (dc_id === undefined) ? 'true' : ''}, 'All DC'),
                                          all_dc_datas.map((dc_data)=>{
        
                                              return mithril('option', {value: dc_data._id, selected: (dc_id == dc_data._id) ? 'true' : ''}, dc_data.name);
                                          })
                                      ])
                                  ])
                              ])
                          ])
                        ])
                    ]),
                    mithril('div', {class:'columns'}, [
                      mithril('div', {class:'column'}, [
                        mithril('canvas', {id:'report_chart', width:'400', height:'300'})
                      ])
                    ]),
                    // mithril('div', {id:'tablesummary'})
                  ])
              ])
          ]),
          mithril("section", {class:"section"}, [
              mithril(Footer)
          ])
        ]);
    }
};