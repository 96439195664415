import mithril from 'mithril';
import qs from 'querystring';

module.exports = {
    loadData:(page,limit,status,dc_id,type)=>{

        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page
            };

            if(dc_id !== undefined){

                query_param.dc_id = dc_id;
            }

            if(status !== undefined){

                query_param.status = status;
            }

            if(type !== undefined){

                query_param.type = type;
            }

            mithril.request({
                method: "GET",
                url: `/api/purchase_orders?${qs.encode(query_param)}`,
                withCredentials: true
            }).then((res_po)=>{

                if(res_po.code == 200){

                    resolve([res_po.data,res_po.pagination]);
                }else if(res_po.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_po.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadAllDc:()=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "GET",
                url: `/api/distribution_centers/all`,
                withCredentials: true
            }).then((res_dc)=>{

                if(res_dc.code == 200){

                    resolve(res_dc.data);
                }else if(res_dc.code == 404){

                    resolve([]);
                }else{

                    reject(new Error(`API Response ${res_dc.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadReport:(start_date,end_date,dc_id)=>{
        
        return new Promise((resolve, reject) => {
            
            let query_param = {
                start_date:start_date,
                end_date:end_date
            };

            if(dc_id !== undefined){

                query_param.dc_id = dc_id;
            }
            
            mithril.request({
                method: "GET",
                url: `/api/purchase_orders/summary?${qs.encode(query_param)}`,
                withCredentials: true
            }).then((res_po)=>{

                if(res_po.code == 200){

                    resolve(res_po.data);
                }else{

                    reject(new Error(`API Response ${res_po.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    }
};