import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"
import classNames from "classnames";
import Validator from "validator"
var mDc = require("../../models/salesman")

var DcAddNewSalesman = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.salesmanid = vnode.attrs.pid;
        this.name = vnode.attrs.name;

        let self = this;
        this.error = {
            kode: undefined,
            name: undefined,
            city: undefined,
            address: undefined,
            phone: undefined,
            password: undefined,
            email: undefined
        }

        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        this.pid = vnode.attrs.pid;
        mDc.load(vnode.attrs.pid, function(){
            self.tmpva = mDc.current.va;
        })
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "salesman", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, self.title+ " Add Salesman"),
                      m("form", {onsubmit: function(e){
                        e.preventDefault();
                        if(!mDc.current._id)
                            mDc.current._id = '';
                        if(!mDc.current.created_at)
                            mDc.current.created_at = '';
                        if(!mDc.current.updated_at)
                            mDc.current.updated_at = '';
                        if(!mDc.current.clientid)
                            mDc.current.clientid = '';
                        if(!mDc.current.email)
                            mDc.current.email = '';
                        if(!mDc.current.password)
                            mDc.current.password = '';
                        mDc.current.dcid = self.id;
                            
                        mDc.save(); //"#!/dc/outlets/"+self.id+"/"+self.name
                        m.route.set('/dc/salesman/'+self.id+'/'+self.name);

                        return false;
                    }}, [
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kode"),
                                    m("div", {class:"control has-icons-left"+((self.error.kode) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.kode, class:"input"+((self.error.kode) ? ' is-danger' : ''), type:"text", placeholder:"kode",
                                        oninput: function(e){
                                            mDc.current.kode = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.kode) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.kode) ? m("p", {class:"help is-danger"}, "This kode is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Nama"),
                                    m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.name, class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"name",
                                        oninput: function(e){
                                            mDc.current.name = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.name) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kota"),
                                    m("div", {class:"control has-icons-left"+((self.error.city) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.city, class:"input"+((self.error.city) ? ' is-danger' : ''), type:"text", placeholder:"kota",
                                        oninput: function(e){
                                            mDc.current.city = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.city) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.city) ? m("p", {class:"help is-danger"}, "This city is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Alamat"),
                                    m("div", {class:"control has-icons-left"+((self.error.address) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.address, class:"input"+((self.error.address) ? ' is-danger' : ''), type:"text", placeholder:"alamat",
                                        oninput: function(e){
                                            mDc.current.address = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.address) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.address) ? m("p", {class:"help is-danger"}, "This address is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Phone"),
                                    m("div", {class:"control has-icons-left"+((self.error.phone) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.phone, class:"input"+((self.error.phone) ? ' is-danger' : ''), type:"text", placeholder:"phone",
                                        oninput: function(e){
                                            mDc.current.phone = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.phone) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.phone) ? m("p", {class:"help is-danger"}, "This phone is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "email"),
                                    m("div", {class:"control has-icons-left"+((self.error.email) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.email, class:"input"+((self.error.email) ? ' is-danger' : ''), type:"text", placeholder:"email",
                                        oninput: function(e){
                                            mDc.current.email = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-envelope"})
                                        ]),
                                        ((self.error.email) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Password"),
                                    m("div", {class:"control has-icons-left"+((self.error.password) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.password, class:"input"+((self.error.password) ? ' is-danger' : ''), type:"password", placeholder:"password",
                                        oninput: function(e){
                                            mDc.current.password = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.password) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.password) ? m("p", {class:"help is-danger"}, "This password is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                //
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m('button', {class:'button is-link', type:'submit'}, 'Simpan')
                            ]),
                        ])
                        
                    ]),
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcAddNewSalesman;