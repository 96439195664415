import m from "mithril"

// version 0.0.1.1

let dom = document.createElement('div')
dom.className = 'overlay';

var modal = {};
modal.title = "Perhatian";
modal.body = "Anda Yakin akan menghapus data ini ?";
modal.ok = "Delete";
modal.cancel = "cancel";
modal.view = function(body) {
    console.log('panggil');
    let self = this;
  return m("div", {class: "modal is-active"},[
        m("div", {class:"modal-background"}),
        m("div", {class: "modal-card"}, [
            m("header", {class:"modal-card-head"}, [
                m("p", {class:"modal-card-title"}, modal.title),
                m("button", {class:"delete", "aria-label":"close",
                onclick: function(){
                    m.mount(dom, null);
                    // Destroy the overlay dom tree. Using m.mount with
                    // null triggers any modal children removal hooks.
                    document.body.removeChild(dom);
                    if(modal.callback != undefined)
                    {
                        modal.callback(false);
                    }
                }})
            ]),
            m("section", {class:"modal-card-body"}, modal.body),
            m("footer", {class:"modal-card-foot"}, [
                m("button", {class:"button is-success", onclick: function(){
                    if(modal.callback != undefined)
                    {
                        modal.callback(true);
                    }
                }}, modal.ok),
                m("button", {class:"button", onclick: function(){
                    m.mount(dom, null);
                    document.body.removeChild(dom);
                    if(modal.callback != undefined)
                    {
                        modal.callback(false);
                    }
                }}, modal.cancel)
            ])
        ])
    ])
}
modal.callback;

module.exports = {
    show: function(callback){
        document.body.appendChild(dom);
        m.mount(dom, modal);
        modal.callback = callback;
    },
    close: function(callback){
        m.mount(dom, null);
        document.body.removeChild(dom);
    },
    setTitle: function(str){
        modal.title=str;
    },
    setBody: function(str){
        modal.body=str;
    },
    setOk: function(str){
        modal.ok=str;
    },
    setCancel: function(str){
        modal.cancel=str;
    }
};