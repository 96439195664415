import m from "mithril"
import Navbar from "../../../component/navbar"
import Sidebar from "../../../component/sidebar_warehouse"
import Footer from "../../../component/footer"
import '../../../../node_modules/flatpickr/dist/flatpickr.min.css';
import '../../../../node_modules/tabulator-tables/dist/css/tabulator.min.css';
import flatpickr from "flatpickr";
import Tabulator from "tabulator-tables";
import Chart from 'chart.js';
import moment from 'moment';
import Global from '../../../global';

let glob = Global.getInstance();

const _getOutlet = ()=>{

    return new Promise((resolve,reject)=>{
        
        m.request({
            method: "GET",
            url: "/api/distributor/outlets_v1/all",
            withCredentials: true,
            params: {
                dcid: '6007dbc58f4a5508a356f7e4'
            }
        }).then(function(result) {
            if(result.result){

                resolve(result.data);
            }else{

                reject(new Error(result.error[0].message));
            }
        }).catch((err)=>{

            reject(err);
        });
    });
}

function _outletInputs(cust) {
    return cust.map(function(u) {
        return m('option', {value: u._id}, u.name)
    })
}

module.exports = {
    chart: undefined,
    table: undefined,
    generateChart: function(data){

        if(this.chart){

            this.chart.destroy();
        }

        let ctx = document.getElementById('mychart');
        let labels = [];
        let dataok = [];

        for(let i=0;i<data.length;i++){
        
            let adatoko = false;
            for(let x=0;x<dataok.length;x++){
          
                if (data[i].outletid == dataok[x].outletid){
            
                    adatoko = true;
                    if (data[i].date != dataok[x].date){
                        
                        let datadetail = dataok[x].datadetail;
                        let dataokd = dataok[x].data;
                        datadetail.push({
                            qty: data[i].qty,
                            trx: data[i].trx,
                            value: data[i].value,
                            date: data[i].date
                        });
                        dataokd.push(data[i].value);
                    }
                }
            }

            if(!adatoko){
          
                let datadetail = [];
                let dataokd = [];
                datadetail.push({
                    qty: data[i].qty,
                    trx: data[i].trx,
                    value: data[i].value,
                    date: data[i].date
                });
                dataokd.push(data[i].value);
          
                let color = '';
                switch(i){
                    case 0:
                        color = 'rgba(244, 67, 54,1.0)';
                        break;
                    case 1:
                        color = 'rgba(33, 150, 243,1.0)';
                        break;
                    case 2:
                        color = 'rgba(139, 195, 74,1.0)';
                        break;
                    case 3:
                        color = 'rgba(255, 152, 0,1.0)';
                        break;
                }

                dataok.push({
                    outletid: data[i].outletid,
                    outlet_name: data[i].outlet_name,
                    label: data[i].outlet_name,
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1,
                    datadetail: datadetail,
                    data: dataokd
                });
            }
        }

        for(let i=0;i<data.length;i++){

            let ada = false;
            for(let x=0;x<labels.length;x++){

                if(data[i].date == labels[x]){
                    ada = true;
                }
            }

            if(!ada){

                labels.push(data[i].date);
            }
        }
        
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataok
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            
                            return 'Rp '+glob.numberWithCommas(tooltipItem.yLabel);
                        }
                    }
                },
                maintainAspectRatio: false,
                scales:{
                    xAxes: [{
                        ticks: {
                            beginAtZero:true,
                            callback: function(value, index, values) {
                                return value ;
                            }
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero:true,
                            callback: function(value, index, values) {
                                return 'Rp '+glob.numberWithCommas(value);
                            }
                        }
                    }]
                }
            }
        });
    },
    loadData: function(from, to){

        let self = this;

        m.request({
            method: "GET",
            url: "/api/warehouse/report/sales",
            params: {
              date_from: from,
              date_to: to,
              outlet_id: self.outlet_id
            },
            withCredentials: true
        }).then(function(data) {
            if(data.code == 200){

                for(let i=0;i<data.data.length;i++){

                    data.data[i].no = i +1;
                }
                self.generateChart(data.data);
                self.table.setData(data.data);
            }else{

                alert('No Data');
                // location.reload();
            }
        }).catch((err)=>{

            console.log(err);
            // alert(err.message);
        });
    },
    DateFrom: '',
    DateTo: '',
    oninit: function(){

        let self = this;
        self.outlet_id = 'ALL';
        self.outlet_datas = [];

        _getOutlet().then((outlet_datas)=>{

            self.outlet_datas.push({
                _id:'ALL',
                name:'ALL'
            });
            self.outlet_datas = [...self.outlet_datas,...outlet_datas];
        }).catch((err)=>{

            if(err.message == "You're not login"){
                
                m.route.set('/login');
            }else{

                console.log(err);
                alert(err.message);
            }
        });
    },
    oncreate: function(){

        let self = this;

        self.DateFrom =  moment().subtract(1, 'weeks');
        self.DateTo = moment();

        flatpickr('#tanggalan', {
            mode: "range",
            minDate: "2018-01", //"today",
            dateFormat: "d-m-Y",
            //minDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
            defaultDate: [this.DateFrom.format('DD-MM-YYYY'), this.DateTo.format('DD-MM-YYYY')],
            onReady: function(){
                
                self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
            },
            onChange: function(selectedDates, dateStr, instance) {
                if(selectedDates.length == 2){

                    self.DateFrom =  moment(selectedDates[0]);
                    self.DateTo = moment(selectedDates[1]);
                }
            }
        });

        self.table = new Tabulator("#tablesummary", {
            height:"311px",
            columns:[
                {title:"No", field:"no"},
                {title:"Tanggal", field:"date", sorter:"date"},
                {title:"Outlet", field:"outlet_name"},
                {title:"Jumlah Transaksi", field:"trx", align:"right"},
                {title:"Jumlah Qty", field:"qty", align:"right"},
                {title:"Total Rp", field:"value", align:"right", sorter:"number", formatter:function(cell, formatterParams){
                    var value = cell.getValue();
                    return glob.numberWithCommas(value);
                }}
            ]
        });
    },
    view: function(){

        let self = this;
        
        return m("div", [
            m("section", {class:"section"}, [
                m(Navbar)
            ]),
            m("section", {class:"section", style:"margin-top: -120px; "}, [
                m("div", {class:"columns"}, [
                    m("div", {class:"column is-one-fifth"}, [
                        m(Sidebar, {title: "sales"})
                    ]),
                    m("div", {class:"column"}, [
                        m("h1", {class:"title"}, "Sales Summary"),
                        m('div', {class:'columns'}, [
                            m('div', {class:'column is-one-thirds'}, [
                                m('input', {id:'tanggalan', type:'text', class:'input', placeholder:'Pilih Tanggal'})
                            ]),
                            m('div', {class:'column'}, [
                                m("div", {class:"field is-grouped"}, [
                                    m("div", {class:"control"}, [
                                        m('div', {class:'select'}, [
                                            m('select', {
                                                onchange: function(e){
                                                    self.outlet_id = e.target.value;
                                                }
                                            }, [
                                                _outletInputs(self.outlet_datas)
                                            ])
                                        ])
                                    ]),
                                    m("div", {class:"control"}, [
                                        m('a', {class:'button', onclick: function(){
                                            self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                                        }}, 'Generate')
                                    ])
                                ]),
                            ])
                        ]),
                        m('div', {class:'columns'}, [
                            m('div', {class:'column'}, [
                                m('canvas', {id:'mychart', width:'400', height:'300'})
                            ])
                        ]),
                        m('div', {id:'tablesummary'})
                    ])
                ])
            ]),
            m("section", {class:"section"}, [
                m(Footer)
            ])
        ]);
    }
};