import m from "mithril"
import animate from "animate.css"

// version 0.0.1.1

let dom = document.createElement('div')
//dom.className = 'animated slideInRight faster';

var notif = {};
var closeNotif = function(){
    var element = document.getElementById("notificationid");
    element.classList.remove("animated");
    element.classList.remove("slideInRight");
    element.classList.remove("faster");

    element.classList.add("animated");
    element.classList.add("slideOutRight");
    element.classList.add("faster");

    setTimeout(function(){ 
        m.mount(dom, null);
        document.body.removeChild(dom);
        if(notif.callback != undefined)
        {
            notif.callback(false);
        }
    }, 1000);
}
notif.body = "body";
notif.type = 'is-primary';
notif.view = function() {
    let self = this;
    return m('div', {id:'notificationid', class:'notification notify animated slideInRight faster '+self.type}, [
        m('button', {class:'delete', onclick: function(){
            
            closeNotif();
        }}),
        self.body
    ])
}
notif.callback;

module.exports = {
    show: function(callback){
        let self = this;
        document.body.appendChild(dom);
        m.mount(dom, notif);
        notif.callback = callback;

        setTimeout(function(){ 
            closeNotif();
        }, 4000);
    },
    close: function(callback){
        m.mount(dom, null);
        document.body.removeChild(dom);
    },
    setBody: function(str){
        notif.body=str;
    },
    setType: function(str){
        notif.type=str;
    }
};