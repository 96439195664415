import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Validator from "validator"
import Modal from "../../component/modal"

var EditCustomers = {
    oninit: function(vnode) {
        //let path = m.route.get();
        //console.log(vnode.attrs.id);
        let self = this;
        m.request({
            method: "POST",
            url: "/api/customers/get",
            body: { id: vnode.attrs.id },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                self.data.email= data.data.email;
                self.data.name= data.data.name;
                self.data.phone= data.data.phone;
                self.data.address= data.data.address;
                self.data.active= data.data.active;
            }
            else
            {
                alert('get customer gagal');
            }
        });
        this.data = {
            email: "",
            name: "",
            phone: "",
            address: "",
            active: false
        };
        this.error = {
            email: undefined,
            name: undefined,
            phone: undefined,
            address: undefined,
            active: false,
        };
    },
    view: function(vnode) {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "customers"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Add Customers"),
                      m("form",{
                        onsubmit: function(e) {
                        e.preventDefault()
                        //User.save()
                        console.log(self.data);
                        self.error.email = !Validator.isEmail(self.data.email);;
                        self.error.name = !Validator.isLength(self.data.name, {min:6, max: 20});
                        
                        console.log(self.error);
                        if(!self.error.email &&
                            !self.error.name)
                            {
                                m.request({
                                    method: "POST",
                                    url: "/api/customers/insert",
                                    body: self.data
                                })
                                .then(function(data) {
                                    if(data.result)
                                    {
                                        location.href = '#!/customers';
                                    }
                                    else
                                    {
                                        alert('insert customer gagal');
                                    }
                                });
                            }
                    }},[

                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Name"),
                            m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"Name",
                                oninput: function(e){
                                    self.data.name = e.target.value;
                                }, value: self.data.name}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-user"})
                                ]),
                                ((self.error.name) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class: "label"}, "Email"),
                            m("div", {class:"control has-icons-left"+((self.error.email) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.email) ? ' is-danger' : ''), type: "email", placeholder:"Email",
                                oninput: function(e){
                                    self.data.email = e.target.value;
                                }, value: self.data.email}),
                                m("span", {class:"icon is-small is-left"}, [
                                    m("i", {class:"fas fa-envelope"})
                                ]),
                                ((self.error.email) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Phone"),
                            m("div", {class:"control has-icons-left"}, [
                                m("input", {class: "input is-success", type: "text", placeholder: "Phone",
                                oninput: function(e){
                                    self.data.phone = e.target.value;
                                }, value: self.data.phone}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-phone"})
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class:"label"}, "Address"),
                            m("div", {class:"control"}, [
                                m("textarea", {class:"textarea", placeholder:"Address",
                                oninput: function(e){
                                    self.data.address = e.target.value;
                                }, value: self.data.address})
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.active = e.target.checked;
                                    }, checked: self.data.active}),
                                    " Active"
                                ])
                            ])
                        ]),
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m("button", {class:"button is-link"}, "Submit")
                            ]),
                            m("div", {class:"control"}, [
                                m("a", {class:"button is-text", href: "#!/customers"}, "Cancel")
                            ]),
                            m("div", {class:"control"}, [
                                m("a", {class:"button", 
                                onclick: function(){
                                    console.log('ok');
                                    
                                    Modal.show(function(ok){
                                        if(ok)
                                        {
                                            Modal.close();
                                            m.route.set('/customers', {})
                                        }
                                    });
                                }}, [
                                    m("span", {class:"icon"}, [
                                        m("i", {class:"fas fa-trash"})
                                    ])
                                ])
                            ])
                        ])

                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = EditCustomers;