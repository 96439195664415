var m = require("mithril")

var datakosong = {
    kode: '',
    outlet: '',
    outletid: '',
    address: '',
    phone: '',
    contact: '',
    va: '',
    email: '',
    detail: [],
    tanggal: ''
}
var mDcPo = {
    list: [],
    loadDatas:(page,limit,dcid,outlet_id=undefined,type=undefined,status=undefined,search=undefined)=>{

        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page,
                dcid:dcid
            };

            if(search !== undefined){

                if(search.length > 2){

                    query_param.search = search;
                }
            }
            
            if(outlet_id !== undefined){

                query_param.outlet_id = outlet_id;
            }
            
            if(type !== undefined){

                query_param.type = type;
            }
            
            if(status !== undefined){

                query_param.status = status;
            }

            m.request({
                method: "GET",
                url: "/api/distributor/purchase_orders_v1",
                withCredentials: true,
                params:query_param
            }).then((res_po_datas)=>{

                if(res_po_datas.code == 200){

                    resolve([res_po_datas.data,res_po_datas.pagination]);
                }else if(res_po_datas.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_po_datas.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadList: function(dcid) {
        return m.request({
            method: "POST",
            url: "/api/distributor/po/getall",
            withCredentials: true,
            body: {
                limit: 10,
                page: 1,
                dcid: dcid
            }
        })
        .then(function(result) {
            if(result.result)
            {
                mDcPo.list = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
                
        })
    },
    current: datakosong,
    load: function(id, cb) {
        if(id == 'new')
        {
            mDcPo.current = datakosong;
            cb();
        }
        else
        {
            return m.request({
                method: "POST",
                url: "/api/distributor/po/get", 
                withCredentials: true,
                body: {
                    id: id
                }
            })
            .then(function(result) {
                if(result.result)
                {
                    mDcPo.current = result.data;
                    //console.log(mDcOutlet.current);
                }
                else{
                    if(result.error[0].message == "You're not login")
                    {
                        m.route.set('/login');
                    }
                }
                cb();
            })
        }
        
    },

    save: function() {
        return m.request({
            method: "POST",
            url: "/api/distributor/po/insert",
            body: mDcPo.current,
            withCredentials: true,
        })
        .then(function(result) {
            if(result.result)
            {
                //
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    }
}

module.exports = mDcPo