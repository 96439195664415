import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Validator from "validator"
import Modal from "../../component/modal"
import '../../../node_modules/flatpickr/dist/flatpickr.min.css';
import flatpickr from "flatpickr";
import moment from 'moment';
import Global from "../../global"
import classNames from "classnames";
import {eachSeries,each} from 'async';
import Hashids  from 'hashids';

import product_attributes_model from "../../models/product_attributes"

var categories = [];
const hashids = new Hashids('pengenngopiii')

function categoryInputs(selected, cust) {
    return cust.map(function(u) {
        return m('option', {value: u._id, selected: (selected == u._id) ? 'true' : ''}, u.name)
    })
}

function outletLists(self, cust) {
    return cust.map(function(u) {
        return m("tr", [
            m('td', [
                m('input', {type: 'checkbox', onclick: function(e){
                    u.set = e.target.checked;
                }, checked: (u.set) ? 'checked' : ''})
            ]),
            m('td', u.name),
            m('input', {class:'input', type: 'text', oninput: function(e){
                //u.stock = e.target.value;
                u.price = e.target.value
            }, value: u.price})
        ])
    })
}

function ListSatuan(self, cust) {
    return cust.map(function(u) {
        return m('option', {value: u.value}, u.name)
    })
}

var ShowAddStock = {
    initTangglan: function(){
        let self = this;
        flatpickr('.tanggalan', {
            dateFormat: "d-m-Y H:i:s",
            enableTime: true,
            enableSeconds: true,
            defaultDate: moment().format('DD-MM-YYYY HH:mm:ss'),
            onReady: function(){
              console.log('ok ready tanggalan');
              //self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
            },
            onChange: function(selectedDates, dateStr, instance) {
                //console.log('ok onChange tanggalan ');
              //console.log(selectedDates);
              //console.log(dateStr);
              self.date = dateStr;
            }
          });
    },
    onupdate: function(vnode) {
        if(this.add_stock)
        {
            this.initTangglan();
        }
    },
    oninit: function(vnode){
        this.add_stock = vnode.attrs.u.add_stock;
        this.description = vnode.attrs.u.stock.description;
        this.satuan = vnode.attrs.u.stock.satuan;
        this.master_satuan = vnode.attrs.u.master_satuan;
        this.list_satuan = [];
        if(this.master_satuan == 'piece')
        {
            this.list_satuan.push({name: 'piece', value: 'Piece'});
            this.list_satuan.push({name: 'lusin', value: 'Lusin'});
            this.list_satuan.push({name: 'kodi', value: 'Kodi'});
        }
        if(this.master_satuan == 'gram')
        {
            this.list_satuan.push({name: 'gram', value: 'Gram'});
            this.list_satuan.push({name: 'ons', value: 'Ons'});
            this.list_satuan.push({name: 'Kg', value: 'kilogram'});
        }
        if(!vnode.attrs.u.stock.satuan)
            this.satuan = 'piece';
        this.jumlah = vnode.attrs.u.stock.jumlah;
        this.date = moment().format('DD-MM-YYYY HH:mm:ss');
    },
    oncreate: function(){
        console.log('oncreate');
    },
    view: function(vnode) {
        let self = this;
        let ret;
        if(this.add_stock)
        {
            ret =  m('div', [
                m('div', {class: 'field'}, [
                    m('label', {class:'label'}, 'Jumlah Stock'),
                    m('div', {class: 'control'}, [
                        m('input', {class:'input', oninput: function(e){
                            self.jumlah = e.target.value;
                        }, value: self.jumlah, type:'text', placeholder:'Jumlah Stock'})
                    ])
                ]),
                m('div', {class: 'field'}, [
                    m('label', {class:'label'}, 'Satuan'),
                    m('div', {class: 'control'}, [
                        m('div', {class:'select'}, [
                            m('select', {onchange: function(e){
                                //console.log(e);
                                self.satuan = e.target.value;
                            }}, [
                                ListSatuan(self, self.list_satuan)
                               // m('option', {value: 'gram'}, 'Gram'),
                               // m('option', {value: 'kilogram'}, 'KiloGram'),
                                //m('option', {value: 'piece'}, 'Piece')
                            ])
                        ])
                    ])
                ]),
                m('div', {class: 'field'}, [
                    m('label', {class:'label'}, 'Tanggal Stock'),
                    m('div', {class: 'control'}, [
                        m('input', {class:'input tanggalan', type:'text', placeholder:'Tanggal Stock'})
                    ])
                ]),
                m("div", {class:"field"}, [
                    m("label", {class:"label"}, "Description"),
                    m("div", {class:"control"}, [
                        m("textarea", {class:"textarea", placeholder:"Description",
                        oninput: function(e){
                            self.description = e.target.value;
                        }}, self.description)
                    ])
                ]),
                m('div', {class: 'field is-grouped'}, [
                    m('div', {class:'control'}, [
                        m('button', {class:'button is-link', onclick: function(){
                            self.add_stock = false;

                            vnode.attrs.u.add_stock = self.add_stock;
                            vnode.attrs.u.stock.description = self.description;
                            vnode.attrs.u.stock.satuan = self.satuan;
                            vnode.attrs.u.stock.jumlah = self.jumlah;
                            vnode.attrs.u.stock.date = self.date;
                            let q = {
                                description : self.description,
                                satuan : self.satuan,
                                jumlah : self.jumlah,
                                date : self.date
                            }
                            vnode.attrs.u.stock_save.push(q);

                            
                        }}, 'Simpan')
                    ]),
                    m('div', {class:'control'}, [
                        m('button', {class:'button is-link', onclick: function(){
                            self.add_stock = false;
                        }}, 'Cancel')
                    ])
                ])
            ]);
        }
        else{
            ret =  m('button', {class:'button', onclick: function(){
                self.add_stock = true;
                //self.initTangglan();
            }}, 'Add Stock');
        }
        return ret;
    }
}

function StockSave(self, cust) {
    return cust.map(function(u) {
        return m("ul", [ 
            m('li', [
                m('a', {class: 'delete is-pulled-right'})
            ]),
            m('li', [
                'Jumlah : ' + u.jumlah
            ]),
            m('li', [
                'Satuan : ' + u.satuan
            ]),
            m('li', [
                'Description : ' + u.description
            ]),
            m('li', [
                'Date : ' + u.date
            ]),
            m('li', [
                m('hr')
            ])
            
        ])
    })
}

function outletStockListsAda(self, cust) {
    return cust.map(function(u) {
        if(u.set)
        {
            return m("tr", [
                m('td', u.name),
                m('td', [
                    StockSave(self, u.stock_save)
                    
                ]),
                
            ])
        }
        else
        {
            return '';
        }
    })
}

function outletStockLists(self, cust, master_satuan) {
    return cust.map(function(u) {
        if(u.set)
        {
            u.master_satuan = master_satuan;
            return m("tr", [
                m('td', u.name),
                m('td', [
                    /*m('input', {class:'input', type: 'text', onclick: function(e){
                        //u.stock = e.target.value;
                        u.stock = {
                            storeid: u._id,
                            stock: e.target.value
                        }
                    }, value: u.stock.stock})*/
                    //showAddStock(u)
                    m(ShowAddStock, {u:u})
                    
                ]),
                
            ])
        }
        else
        {
            return '';
        }
    })
}

function ListsSourceRoastery(self, cust) {
    return cust.map(function(u) {
        return m('a', {onclick: function(e){
            self.showselect = !self.showselect;
            for(let i=0;i<self.datasourceroastery.length;i++)
            {
                self.datasourceroastery[i].active = false;
            }
            u.active = true;
            self.data.id_roastery = u._id;
            self.namesourceroastery = u.name;
        },class: classNames("list-item", {"is-active": u.active})}, u.name)
    })
}

function ListsOptionSatuan(self, cust, pilih) {
    return cust.map(function(u) {
        return m('option', {value: u.value, selected: (u.value == pilih) ? 'true' : ''}, u.value)
    })
}

// function ListsVariant(self, cust) {
//     let list_satuan = [];
//     if(self.master_satuan == 'piece')
//     {
//         list_satuan.push({name: 'piece', value: 'piece'});
//         list_satuan.push({name: 'lusin', value: 'lusin'});
//         list_satuan.push({name: 'kodi', value: 'kodi'});
//     }
//     if(self.master_satuan == 'gram')
//     {
//         list_satuan.push({name: 'gram', value: 'gram'});
//         list_satuan.push({name: 'ons', value: 'ons'});
//         list_satuan.push({name: 'Kg', value: 'kilogram'});
//     }

//     return cust.map(function(u) {
//         return m('div', {class:'columns'},[
//             m('div',{class:'column is-2'},[
//                 m('label', {class: 'checkbox', style:'margin-top: 30px;'}, [
//                     m('input', {onclick: function(e){
//                         u.enabled = e.target.checked;
//                     }, type: 'checkbox', checked: u.enabled}),
//                     ' enable'
//                 ])
//             ]),
//             m('div',{class:'column'},[
//                 m("div", {class:"field"}, [
//                     m("label", {class: "label"}, "Harga"),
//                     m("div", {class:"control"}, [
//                         m("input", {class:"input", type: "text", placeholder:"value",
//                         oninput: function(e){
//                             u.price = e.target.value;
//                         }, value: u.price})
//                     ])
//                 ]),
//             ]),
//             m('div',{class:'column'},[
//                 m("div", {class:"field"}, [
//                     m("label", {class: "label"}, "Value"),
//                     m("div", {class:"control"}, [
//                         m("input", {class:"input", type: "text", placeholder:"value",
//                         oninput: function(e){
//                             u.value = e.target.value;
//                         }, value: u.value})
//                     ])
//                 ]),
//             ]),
//             m('div',{class:'column'},[
//                 m('div', {class: 'field'}, [
//                     m('label', {class:'label'}, 'Satuan'),
//                     m('div', {class: 'control'}, [
//                         m('div', {class:'select'}, [
//                             m('select', {onchange: function(e){
//                                 //console.log(e);
//                                 u.satuan = e.target.value;
//                             }}, [
//                                 ListsOptionSatuan(self, list_satuan, u.satuan)
//                                 /*m('option', {value: 'piece', selected: (u.satuan == 'piece') ? 'true' : ''}, 'Piece'),
//                                 m('option', {value: 'gram', selected: (u.satuan == 'gram') ? 'true' : ''}, 'Gram'),*/
//                             ])
//                         ])
//                     ])
//                 ]),
//             ]),
//             m('div',{class:'column is-1'},[
//                 m('span', {onclick: function(){
                    
//                     Modal.show(function(ok){
//                         if(ok)
//                         {
//                             for(let i=0;i<self.data.variant.length;i++)
//                             {
//                                 if(self.data.variant[i].kode == u.kode)
//                                 {
//                                     self.data.variant.splice(i, 1) ;
//                                 }
//                             }
//                             Modal.close();
//                         }
//                     });
//                 }, class: 'icon', style:'margin-top: 30px;'}, [
//                     m('i', {class:'fas fa-trash', "aria-hidden":"true"})
//                 ])
//             ]),
//         ])
//     })
// }
function ListsVariants(self, cust) {
    let list_satuan = [];
    if(self.master_satuan == 'piece')
    {
        list_satuan.push({name: 'piece', value: 'piece'});
        list_satuan.push({name: 'lusin', value: 'lusin'});
        list_satuan.push({name: 'kodi', value: 'kodi'});
    }
    if(self.master_satuan == 'gram')
    {
        list_satuan.push({name: 'gram', value: 'gram'});
        list_satuan.push({name: 'ons', value: 'ons'});
        list_satuan.push({name: 'Kg', value: 'kilogram'});
    }

    return cust.map(function(u) {

        const new_obj = {...u};
        delete new_obj['enabled'];
        delete new_obj['price'];
        delete new_obj['satuan'];
        delete new_obj['value'];
        delete new_obj['kode'];
        const new_keys = Object.keys(new_obj);

        return m('div', {class:'columns'},[
            m('div',{class:'column is-2'},[
                m('label', {class: "label"}, [
                    new_keys.map((ky,idx)=>{
                        return m('span', (idx == (new_keys.length - 1)) ? `${new_obj[ky]}` : `${new_obj[ky]} / `);
                    })
                ])
            ]),
            m('div',{class:'column'},[
                m("div", {class:"field"}, [
                    m("label", {class: "label"}, "Harga"),
                    m("div", {class:"control"}, [
                        m("input", {class:"input", type: "number", min:0, placeholder:"value",
                        oninput: function(e){
                            u.price = e.target.value;
                        }, value: u.price})
                    ])
                ]),
            ]),
            m('div',{class:'column'},[
                m("div", {class:"field"}, [
                    m("label", {class: "label"}, "Value"),
                    m("div", {class:"control"}, [
                        m("input", {class:"input", type: "text", placeholder:"value",
                        oninput: function(e){
                            u.value = e.target.value;
                        }, value: u.value})
                    ])
                ]),
            ]),
            m('div',{class:'column'},[
                m('div', {class: 'field'}, [
                    m('label', {class:'label'}, 'Satuan'),
                    m('div', {class: 'control'}, [
                        m('div', {class:'select'}, [
                            m('select', {onchange: function(e){
                                //console.log(e);
                                u.satuan = e.target.value;
                            }}, [
                                ListsOptionSatuan(self, list_satuan, u.satuan)
                                /*m('option', {value: 'piece', selected: (u.satuan == 'piece') ? 'true' : ''}, 'Piece'),
                                m('option', {value: 'gram', selected: (u.satuan == 'gram') ? 'true' : ''}, 'Gram'),*/
                            ])
                        ])
                    ])
                ]),
            ])
        ])
    })
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
 
const makeid2 = ()=>{

    return hashids.encode(new Date().getTime()) + makeid(4);
}

 function getContent(self, tipe){
    if(tipe == 'data'){
        return m("form",{
            onsubmit: function(e) {
                e.preventDefault()
                //User.save()
                console.log(self.data);
                self.error.price = !Validator.isInt(self.data.price);
                self.error.value = !Validator.isInt(self.data.value);
                self.error.discount = !Validator.isInt(self.data.discount);
                self.error.name = !Validator.isLength(self.data.name, {min:6, max: 60});
                
                console.log(self.error);
                if(!self.error.price && !self.error.discount && !self.error.name && !self.error.value ){
                    self.data.outlets = self.data_outlets;

                    const req_param = {...self.data};
                    delete req_param['attributes'];
                    //delete req_param['variants'];
                    m.request({
                        method: "POST",
                        url: "/api/product/insert",
                        body: req_param,
                        withCredentials: true
                    })
                    .then(function(data) {
                        if(data.result)
                        {
                            location.href = '#!/product/'+self.ProductPageLimit+'/'+self.ProductPageOffset+'/'+self.ProductPageType;
                        //location.href = '#!/product/10/1/ALL';
                        }
                        else
                        {
                            alert(data.error[0].message);
                        }
                    });
                }
            }
        },[
            m("div", {class: "field"}, [
                m("label", {class:"label"}, "Name"),
                m("div", {class:"control "+((self.error.name) ? ' has-icons-right' : '')}, [
                    m("input", {class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"Name",
                    oninput: function(e){
                        self.data.name = e.target.value;
                    }, value: self.data.name}),
                    ((self.error.name) ?
                              m("span", {class:"icon is-small is-right"}, [
                                  m("i", {class:"fas fa-exclamation-triangle"})
                              ]) : "")
                ]),
                ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
            ]),
            m("div", {class:"field"}, [
                m("label", {class: "label"}, "Price"),
                m("div", {class:"control "+((self.error.price) ? ' has-icons-right' : '')}, [
                    m("input", {class:"input"+((self.error.price) ? ' is-danger' : ''), type: "text", placeholder:"Price",
                    oninput: function(e){
                        self.data.price = e.target.value;
                    }, value: self.data.price}),
                    ((self.error.price) ?
                              m("span", {class:"icon is-small is-right"}, [
                                  m("i", {class:"fas fa-exclamation-triangle"})
                              ]) : "")
                ]),
                ((self.error.price) ? m("p", {class:"help is-danger"}, "This price is invalid") : "")
            ]),
            m("div", {class:"field"}, [
                m("label", {class: "label"}, "Discount"),
                m("div", {class:"control "+((self.error.discount) ? ' has-icons-right' : '')}, [
                    m("input", {class:"input"+((self.error.discount) ? ' is-danger' : ''), type: "text", placeholder:"Discount",
                    oninput: function(e){
                        self.data.discount = e.target.value;
                    }, value: self.data.discount}),
                    ((self.error.discount) ?
                              m("span", {class:"icon is-small is-right"}, [
                                  m("i", {class:"fas fa-exclamation-triangle"})
                              ]) : "")
                ]),
                ((self.error.discount) ? m("p", {class:"help is-danger"}, "This discount is invalid") : "")
            ]),
            m('div', {class:'columns'},[
                m('div',{class:'column'},[
                    m("div", {class:"field"}, [
                        m("label", {class: "label"}, "Value"),
                        m("div", {class:"control "+((self.error.value) ? ' has-icons-right' : '')}, [
                            m("input", {class:"input"+((self.error.value) ? ' is-danger' : ''), type: "text", placeholder:"value",
                            oninput: function(e){
                                self.data.value = e.target.value;
                            }, value: self.data.value}),
                            ((self.error.value) ?
                                      m("span", {class:"icon is-small is-right"}, [
                                          m("i", {class:"fas fa-exclamation-triangle"})
                                      ]) : "")
                        ]),
                        ((self.error.value) ? m("p", {class:"help is-danger"}, "This value is invalid") : "")
                    ]),
                ]),
                m('div',{class:'column'},[
                    m('div', {class: 'field'}, [
                        m('label', {class:'label'}, 'Satuan'),
                        m('div', {class: 'control'}, [
                            m('div', {class:'select'}, [
                                m('select', {onchange: function(e){
                                    //console.log(e);
                                    self.data.satuan = e.target.value;
                                    self.master_satuan = self.data.satuan;
                                }}, [
                                    m('option', {value: 'piece', selected: (self.data.satuan == 'piece') ? 'true' : ''}, 'Piece'),
                                    m('option', {value: 'gram', selected: (self.data.satuan == 'gram') ? 'true' : ''}, 'Gram'),
                                ])
                            ])
                        ])
                    ]),
                ]),
                m('div',{class:'column'},[
                    //
                ]),
            ]),
            m('div', {class:'columns'},[
                m('div',{class:'column is-four-fifths'},[
                    m('div', {class: 'card'},[
                        m('header', {class:'card-header'},[
                            m('p', {class:'card-header-title'}, 'Attribute'),
                            m('a', {class:'card-header-icon', 'aria-label':'more options'},[
                                m('span', {onclick: function(){
                                    self.data.attributes.push({
                                        enabled: false,
                                        id:null,
                                        name:null,
                                        values:[]
                                    });
                                }, class: 'icon'}, [
                                    m('i', {class:'fas fa-plus', "aria-hidden":"true"})
                                ])
                            ])
                        ]),
                        m('div', {class:'card-content'},[
                            m('div', {class: 'content'},[
                                // ##
                                self.data.attributes.map(function(u) {
                                    return m('div', {class:'columns'},[
                                        m('div',{class:'column is-2'},[
                                            m('label', {class: 'checkbox', style:'margin-top: 30px;'}, [
                                                m('input', {onclick: function(e){
                                                    u.enabled = e.target.checked;
                                                }, type: 'checkbox', checked: u.enabled}),
                                                ' enable'
                                            ])
                                        ]),
                                        m('div',{class:'column'},[
                                            m('div', {class: 'field'}, [
                                                m('label', {class:'label'}, 'Attribute Name'),
                                                m('div', {class: 'control'}, [
                                                    m('div', {class:'select'}, [
                                                        m('select', {onchange: function(e){
                                                            //console.log(e);

                                                            const check = self.data.attributes.find((objj)=>{
                                                                return objj.id == e.target.value;
                                                            });
                                                            const check_val = attribute_datas.find((objj)=>{
                                                                return objj._id == e.target.value;
                                                            });

                                                            if(check){

                                                                alert('attribute sudah ada');
                                                                e.target.value = null;
                                                                u.enabled = false;
                                                            }else{
                                                                
                                                                u.id = e.target.value;
                                                                u.enabled = true;
                                                                u.name = check_val.name;
                                                            }
                                                        }}, [
                                                            m('option', {selected: (u.id === null) ? 'true' : '', disabled:'true'}, '- Choose Attribute -'),

                                                            attribute_datas.map((attribute_data)=>{

                                                                return m('option', {value: attribute_data._id, selected: (u.id == attribute_data._id) ? 'true' : ''}, attribute_data.name);
                                                            })
                                                        ])
                                                    ])
                                                ])
                                            ]),
                                        ]),
                                        m('div',{class:'column is-1'},[
                                            m('span', {onclick: function(){
                                                
                                                Modal.show(function(ok){
                                                    if(ok)
                                                    {
                                                        for(let i=0;i<self.data.attributes.length;i++)
                                                        {
                                                            if(self.data.attributes[i].id == u.id)
                                                            {
                                                                self.data.attributes.splice(i, 1) ;
                                                            }
                                                        }
                                                        Modal.close();
                                                    }
                                                });
                                            }, class: 'icon', style:'margin-top: 30px;'}, [
                                                m('i', {class:'fas fa-trash', "aria-hidden":"true"})
                                            ])
                                        ]),
                                    ])
                                })
                                // ## 
                            ])
                        ])
                    ])
                ]),
                m('div',{class:'column'},[
                    //
                ]),
            ]),
            self.data.attributes.map((att,idx)=>{

                if(att.enabled){

                    return m('div', {class:'columns'},[
                        m('div',{class:'column is-four-fifths'},[
                            m('div', {class: 'card'},[
                                m('header', {class:'card-header'},[
                                    m('p', {class:'card-header-title'}, `${att.name} Variant List`),
                                    m('a', {class:'card-header-icon', 'aria-label':'more options'},[
                                        m('span', {onclick: function(){
                                            self.data.attributes[idx].values.push(null);
                                        }, class: 'icon'}, [
                                            m('i', {class:'fas fa-plus', "aria-hidden":"true"})
                                        ])
                                    ])
                                ]),
                                m('div', {class:'card-content'},[
                                    m('div', {class: 'content'},[
                                        self.data.attributes[idx].values.map((val_data,idd)=>{

                                            return m('div', {class:'columns'},[
                                                m('div',{class:'column'},[
                                                    m("div", {class:"field"}, [
                                                        m("label", {class: "label"}, "Value"),
                                                        m("div", {class:"control"}, [
                                                            m("input", {class:"input", type: "text", placeholder:"value",
                                                            oninput: function(e){
                                                                self.data.attributes[idx].values[idd] = e.target.value;
                                                            }, value: val_data})
                                                        ])
                                                    ]),
                                                ]),
                                            ])
                                        })
                                    ])
                                ])
                            ])
                        ]),
                        m('div',{class:'column'},[
                            //
                        ]),
                    ])
                }
            }),
            m('div', {class:'columns'},[
                m('div',{class:'column is-four-fifths'},[
                    m('div', {class: 'card'},[
                        m('header', {class:'card-header'},[
                            m('p', {class:'card-header-title'}, 'Variants'),
                            m("a", {class:"button is-link", onclick: function(){
                                
                                const obj_variant = {
                                    enabled: true,
                                    price: self.price,
                                    satuan: self.master_satuan,
                                    value: '1'
                                };
                                const arr_key = {};
                                eachSeries(self.data.attributes,(att_data,cb)=>{
                                    
                                    if(att_data.enabled){

                                        obj_variant[att_data.name.toLowerCase()] = null;
                                        arr_key[att_data.name.toLowerCase()] = att_data.values;
                                    }

                                    cb();
                                },()=>{

                                    const keys = Object.keys(arr_key);
                                    const values = [];

                                    let cnt = 0;
                                    eachSeries(keys,(key,cb)=>{
                                        values[cnt] = arr_key[key];

                                        cnt++;
                                        cb();
                                    },()=>{
                                        const rez = values.reduce(
                                            (a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), [])
                                        );
                                        
                                        self.data.variants = rez.map((gen)=>{
                                            
                                            const tst = {...obj_variant};
                                            tst.kode = makeid2();
                                            
                                            for(let i=0;i<keys.length;i++){
                                                tst[keys[i]] = Array.isArray(gen) ? gen[i] : gen;
                                            }

                                            return tst;
                                        });
                                    });
                                });
                            }}, "Generate Variant"),
                        ]),
                        m('div', {class:'card-content'},[
                            m('div', {class: 'content'},[
                                // ## TODO
                                ListsVariants(self, self.data.variants)
                            ])
                        ])
                    ])
                ]),
                m('div',{class:'column'},[
                    //
                ]),
            ]),
            m("div", {class:"field"}, [
                m("label", {class: "label"}, "No Urut"),
                m("div", {class:"control has-icons-left"+((self.error.nourut) ? ' has-icons-right' : '')}, [
                    m("input", {class:"input"+((self.error.nourut) ? ' is-danger' : ''), type: "text", placeholder:"No Urut",
                    oninput: function(e){
                        self.data.nourut = e.target.value;
                    }, value: self.data.nourut}),
                    m("span", {class:"icon is-small is-left"}, [
                        m("i", {class:"fas fa-envelope"})
                    ]),
                    ((self.error.nourut) ?
                              m("span", {class:"icon is-small is-right"}, [
                                  m("i", {class:"fas fa-exclamation-triangle"})
                              ]) : "")
                ]),
                ((self.error.nourut) ? m("p", {class:"help is-danger"}, "No urut is invalid") : "")
            ]),
            m("div", {class: "field"}, [
                m("label", {class:"label"}, "Category"),
                m("div", {class:"control"}, [
                    m('div', {class:'select'}, [
                        m('select', {
                            onchange: function(e){
                                //console.log(e);
                                self.data.category = e.target.value;
                            }}, [
                            categoryInputs(self.data.category, categories)
                        ])
                    ])
                ])
            ]),
            m("div", {class:"field"}, [
                m("label", {class:"label"}, "Description"),
                m("div", {class:"control"}, [
                    m("textarea", {class:"textarea", placeholder:"Description",
                    oninput: function(e){
                        self.data.description = e.target.value;
                    }}, self.data.description)
                ]),
                ((self.error.description) ? m("p", {class:"help is-danger"}, "description harus diisi") : "")
            ]),
            m("div", {class:"field"}, [
                m("label", {class:"label"}, "Image File"),
                m("div", {class:"control"}, [
                    m('div', {class: 'file has-name'}, [
                        m('label', {class: 'file-label'}, [
                            m('input', {class:'file-input', 
                            onchange: function(e){
                                
                                var file = this.files[0];
                                var frmdata = new FormData();
                                frmdata.append('photos[]', file, file.name);
                                m.request({
                                    method: "POST",
                                    url: "/api/product/image/upload",
                                    body: frmdata
                                })
                                .then(function(data) {
                                    if(!data.result)
                                    {
                                        alert('upload image gagal');
                                    }
                                    else
                                    {
                                        self.filename = file.name;
                                        self.data.image = data.data.image;
                                        //alert('insert customer sukses ', data.data.image);
                                    }
                                });
                                //console.log(file);
                                //console.log(frmdata);
                            },
                            type: 'file', name: 'image'}),
                            m('span', {class: 'file-cta'}, [
                                m('span', {class: 'file-icon'}, [
                                    m('i', {class: 'fas fa-upload'})
                                ]),
                                m('span', {class:'file-label'}, 'Choose a file…')
                            ]),
                            m('span', {class: 'file-name'}, self.filename)
                        ])
                    ])
                ])
            ]),
            m("div", {class:"field"}, [
                m("div", {class:"control"}, [
                    m("label", {class:"checkbox"}, [
                        m("input", {type:"checkbox",
                        onclick: function(e){
                            self.data.active = e.target.checked;
                        }, checked: (self.data.active) ? 'checked' : ''}),
                        " Active"
                    ])
                ])
            ]),
            m("div", {class:"field"}, [
                m("div", {class:"control"}, [
                    m("label", {class:"checkbox"}, [
                        m("input", {type:"checkbox",
                        onclick: function(e){
                            self.data.stock = e.target.checked;
                        }, checked: (self.data.stock) ? 'checked' : ''}),
                        " Stock"
                    ])
                ])
            ]),
            m("div", {class:"field"}, [
                m("div", {class:"control"}, [
                    m("label", {class:"checkbox"}, [
                        m("input", {type:"checkbox",
                        onclick: function(e){
                            self.data.is_roastery = e.target.checked;
                        }, checked: (self.data.is_roastery) ? 'checked' : ''}),
                        " Roastery"
                    ])
                ])
            ]),
            m("div", {class: classNames("field",'is-hidden')}, [ //classNames("field",{'is-hidden': !self.data.is_roastery})
                m("label", {class: "label"}, "Tipe Roastery"),
                m("div", {class:"control"}, [
                    m("label", {class:"radio"}, [
                        m("input", {type:"radio",
                        onclick: function(e){
                            self.data.tipe_roastery = 'input';
                        }, checked: (self.data.tipe_roastery == 'input') ? 'checked' : ''}),
                        " Input"
                    ]),
                    m("label", {class:"radio"}, [
                        m("input", {type:"radio",
                        onclick: function(e){
                            self.data.tipe_roastery = 'output';
                        }, checked: (self.data.tipe_roastery == 'output') ? 'checked' : ''}),
                        " Output"
                    ])
                ])
            ]),
            m("div", {class: classNames("field",{'is-hidden': !self.data.is_roastery})}, [
                m("label", {class: "label"}, "Source Roastery"),
                m("div", {class:"control has-icons-right"}, [
                    m("input", {class:"input"+((self.error.is_roastery) ? ' is-danger' : ''), type: "text", placeholder:"Source Roastery",
                    oninput: function(e){
                        
                        
                        
                    }, onclick: function(){
                        self.showselect = !self.showselect;
                    }, value: self.namesourceroastery}),
                    m("span", {class:"icon is-small is-right"}, [
                        m("i", {class:"fas fa-arrow-down"})
                    ]),
                    
                ]),
                m('div', {class: classNames({'is-hidden': !self.showselect}, "list", "is-hoverable"), style:"position: absolute; overflow-y: scroll; height:200px; z-index: 99999; "}, [
                    ListsSourceRoastery(self, self.datasourceroastery)
                    /*m('a', {class:"list-item is-active"}, 'Featured'),
                    m('a', {class:"list-item"}, 'Featured'),
                    m('a', {class:"list-item"}, 'Featured'),
                    m('a', {class:"list-item"}, 'Featured'),
                    m('a', {class:"list-item"}, 'Featured'),
                    m('a', {class:"list-item"}, 'Featured'),*/
                ])
            ]),
            m("div", {class:"field is-grouped"}, [
                m("div", {class:"control"}, [
                    m("button", {class:"button is-link"}, "Submit")
                ]),
                m("div", {class:"control"}, [
                    m("a", {class:"button is-text", href: "#!/product/"+self.ProductPageLimit+"/"+self.ProductPageOffset+"/"+self.ProductPageType}, "Cancel")
                ])
            ])
        ])
    }else if(tipe == 'outlets'){
        return m('table', {class: 'table'}, [
            m('thead', [
                m('tr', [
                    m('th', ''),
                    m('th', 'Outlet Name'),
                    m('th', 'Price')
                ])
            ]),
            m('tbody', outletLists(self, self.data_outlets))
        ])
    }else if(tipe == 'stocks'){
        return m('div', [
            m('table', {class: 'table'}, [
                m('thead', [
                    m('tr', [
                        m('th', 'Outlet Name'),
                        m('th', 'Stock'),
                    ])
                ]),
                m('tbody', [
                    outletStockListsAda(self, self.data_outlets)
                ])
            ]),
            m('table', {class: 'table'}, [
                m('thead', [
                    m('tr', [
                        m('th', 'Outlet Name'),
                        m('th', 'Stock'),
                    ])
                ]),
                m('tbody', [
                    outletStockLists(self, self.data_outlets, self.data.satuan)
                ])
            ])
        ])
    }
}

let attribute_datas = [];

var AddProducts = {
    filename: '',
    oninit: function(vnode) {
        let self = this;
        this.tab_active = 'data';
        this.data_outlets = [];

        let glob = Global.getInstance();
        this.ProductPageLimit = glob.getProductPageLimit();
        this.ProductPageOffset = glob.getProductPageOffset();
        this.ProductPageType = glob.getProductPageType();
        this.datasourceroastery = [];
        this.namesourceroastery = '';
        this.master_satuan = "piece";

        this.data = {
            category: "",
            name: "",
            image: "",
            price: "",
            nourut: "0",
            value: "1",
            discount: "",
            description: "",
            id_roastery: "",
            tipe_roastery: "",
            satuan: "piece",
            variant: [],
            variants: [],
            attributes: [],
            active: false,
            stock: false,
            is_roastery: false
        };
        this.error = {
            category: undefined,
            name: undefined,
            image: undefined,
            price: undefined,
            value: undefined,
            nourut: undefined,
            discount: undefined,
            description: undefined,
            active: false,
            stock: false,
            is_roastery: false,
            id_roastery: false,
            tipe_roastery: false
        };

        product_attributes_model.loadAll().then((dtattributes)=>{

            attribute_datas = dtattributes;
        }).catch(()=>{
            
            attribute_datas = [];
        });
        
        m.request({
            method: "POST",
            url: "/api/category/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log('/api/category/getall ', data.data);
                categories = data.data;
                self.data.category = data.data[0]._id;
            }
            else
            {
                alert('insert categories gagal');
            }
        });

        m.request({
            method: "POST",
            url: "/api/pos/products/outlets/new/getall",
            body: {  },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log('/api/products/outlest/new/getall ', data.data);
                self.data_outlets = data.data;
                for(let i=0;i<self.data_outlets.length;i++)
                {
                    if(!self.data_outlets[i].price)
                        self.data_outlets[i].price = 0;
                    self.data_outlets[i].stock_save = [];
                    if(self.data_outlets[i].stocks)
                        self.data_outlets[i].stock_save = self.data_outlets[i].stocks;
                    self.data_outlets[i].stock = {};
                    self.data_outlets[i].add_stock = false;
                }
            }
            else
            {
                alert('/api/products/outlest/new/getall gagal');
            }
        });

        m.request({
            method: "GET",
            url: "/api/product/getall/100/1",
            //data: { limit: 10, page: 1 },
            params: {tipe: 'ALL'}, //, roastery: 'true'
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                //console.log('data product roastery : ', data);
                self.datasourceroastery = data.data;
                for(let i=0;i<self.datasourceroastery.length;i++)
                {
                    self.datasourceroastery[i].active = false;
                }
            }
            else
            {
                alert('get products gagal');
            }
        });
    },
    view: function(vnode) {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "products"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Add Product"),
                      m('div', {class: 'tabs'}, [
                          m('ul',[
                              m('li', {class: (self.tab_active == 'data') ? 'is-active' : ''}, [
                                  m('a', {onclick: function(){
                                    self.tab_active = 'data';
                                  }}, 'Data')
                              ]),
                              m('li', {class: (self.tab_active == 'outlets') ? 'is-active' : ''}, [
                                  m('a', {onclick: function(){
                                    self.tab_active = 'outlets';
                                  }}, 'Outlets')
                              ]),
                              m('li', {class: (self.tab_active == 'stocks') ? 'is-active' : '', style: (self.data.stock) ? '' : 'display: none'}, [
                                m('a', {onclick: function(){
                                    self.tab_active = 'stocks';
                                  }}, 'Stocks')
                            ])
                          ])
                      ]),
                      getContent(self, self.tab_active)

                      
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = AddProducts;