import m from "mithril"
import classNames from "classnames"

var LeftMenu = {
    onClick: function(e){
        var elem = document.querySelector('#leftmenu');
        var allElems = elem.querySelectorAll('.itm');

        for(var i=0;i<allElems.length;i++)
        {
            allElems[i].classList.remove('is-active');
        }

        e.target.classList.add("is-active");
    },
    oninit: function(){
        let url = '/api/auth/get';
        let self = this;
        this.user = {
            role: 'others'
        };
        m.request({
            method: "POST",
            url: url,
            body: {},
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log('dari left menu ', data);
                //self.username = data.data.username;
                self.user = data.data;
                if(!self.user.role)
                {
                    self.user.role = 'others'
                }
                m.redraw();
            }
            else
            {
                //alert(data.error[0].message);
                if(data.error[0].message == "You're not login")
                {
                    m.request({
                        method: "POST",
                        url: "/api/auth/logout",
                        withCredentials: true,
                    })
                    .then(function(data) {
                        location.href = '/';
                    });
                }
            }
        });
    },
    view: function(vnode) {
        let self = this;
        this.dta = m("aside", {id:"leftmenu", class: "menu is-hidden-touch"}, [
            m("p", {class: "menu-label"}, "Report"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "home") ? ' is-active' : ''), href: "#!/home", onclick: this.onClick.bind(this)}, "Sales Summary")
                ])
            ]),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "tax_summary") ? ' is-active' : ''), href: "#!/tax_summary", onclick: this.onClick.bind(this)}, "Tax Summary")
                ])
            ]),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "salesdetail") ? ' is-active' : ''), href: "#!/salesdetail", onclick: this.onClick.bind(this)}, "Sales By Item")
                ])
            ]),
            // m("ul", {class: "menu-list"}, [
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "salesdc") ? ' is-active' : ''), href: "#!/dc/salesdc", onclick: this.onClick.bind(this)}, "Sales Distribusi")
            //     ])
            // ]),
            m("p", {class: "menu-label"}, "Cashier"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "customers") ? ' is-active' : ''), href: "#!/customers", onclick: this.onClick.bind(this)}, "Customers")
                ]),
                m("li",  [
                    m("a", {class:"itm"+((vnode.attrs.title == "outlets") ? ' is-active' : ''), href: "#!/outlets", onclick: this.onClick.bind(this)}, "Outlets")
                ]),
                m("li", {class:classNames({"hidden" : (self.user.role != 'admin')})}, [
                    m("a", {class: classNames("itm"+((vnode.attrs.title == "users") ? ' is-active' : '' ) ), href: "#!/users", onclick: this.onClick.bind(this)}, "Users")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "devices") ? ' is-active' : ''), href: "#!/devices", onclick: this.onClick.bind(this)}, "Devices")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "settings") ? ' is-active' : ''), href: "#!/settings", onclick: this.onClick.bind(this)}, "Settings")
                ])
            ]),
            // m("p", {class: "menu-label"}, "Distribusi"),
            // m("ul", {class: "menu-list"}, [
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "dcsummary") ? ' is-active' : ''), href: "#!/dc/summary", onclick: this.onClick.bind(this)}, "Summary")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "dc") ? ' is-active' : ''), href: "#!/dc", onclick: this.onClick.bind(this)}, "DC")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "purchase_orders") ? ' is-active' : ''), href: "#!/purchase_orders", onclick: this.onClick.bind(this)}, "Purchase Orders")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "po_report") ? ' is-active' : ''), href: "#!/purchase_orders/reports", onclick: this.onClick.bind(this)}, "Report PO")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "dc_products") ? ' is-active' : ''), href: "#!/dc/products", onclick: this.onClick.bind(this)}, "DC Products")
            //     ]),
            //     // m("li", [
            //     //     m("a", {class:"itm"+((vnode.attrs.title == "dcproduct") ? ' is-active' : ''), href: "#!/dc/masterproduct", onclick: this.onClick.bind(this)}, "Master Product")
            //     // ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "payment") ? ' is-active' : ''), href: "#!/payment", onclick: this.onClick.bind(this)}, "Payment")
            //     ])
            // ]),
            // m("p", {class: "menu-label"}, "Roastery"),
            // m("ul", {class: "menu-list"}, [
            //     // m("li", [
            //     //     m("a", {class:"itm"+((vnode.attrs.title == "add_roastery_products") ? ' is-active' : ''), href: "#!/roastery/products/add", onclick: this.onClick.bind(this)}, "Add Products")
            //     // ]),
            //     // m("li", [
            //     //     m("a", {class:"itm"+((vnode.attrs.title == "roastery_products") ? ' is-active' : ''), href: "#!/roastery/products", onclick: this.onClick.bind(this)}, "Products")
            //     // ])
            // ]),
            m("p", {class: "menu-label"}, "Manage Product"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "products") ? ' is-active' : ''), href: "#!/product/10/1/ALL", onclick: this.onClick.bind(this)}, "Product")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "product_attributes") ? ' is-active' : ''), href: "#!/products/attributes", onclick: this.onClick.bind(this)}, "Attributes")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "category") ? ' is-active' : ''), href: "#!/category", onclick: this.onClick.bind(this)}, "Category")
                ])
            ]),
            m("p", {class: "menu-label"}, "Transactions"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "POS Transactions") ? ' is-active' : ''), href: "#!/postransactions/10/1/ALL", onclick: this.onClick.bind(this)}, "POS Transactions"),
                    m("a", {class:"itm"+((vnode.attrs.title == "Export POS Transaction") ? ' is-active' : ''), href: "#!/postransactions/export", onclick: this.onClick.bind(this)}, "Export POS Transactions"),
                    m("a", {class:"itm"+((vnode.attrs.title == "Roastery Transactions") ? ' is-active' : ''), href: "#!/roasterytransactions/10/1/ALL", onclick: this.onClick.bind(this)}, "Roastery Process")
                ])
            ]),
            m("p", {class: "menu-label"}, "Sementara"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "Form Pengajian Soul") ? ' is-active' : ''), href: "#!/pengajian/soul", onclick: this.onClick.bind(this)}, "Form Pengajian Soul")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "Backup Transaction Client") ? ' is-active' : ''), href: "#!/backup/transaction", onclick: this.onClick.bind(this)}, "Backup Transaction Client")
                ])
            ]),
        ])
        return this.dta;
    }
}
module.exports = LeftMenu;
