var m = require("mithril")

var datakosong = {
    kode: '',
    name: '',
    unit: '',
    price: 0
}
var mDcMasterProduct = {
    list: [],
    loadList: function(cb) {
        return m.request({
            method: "POST",
            url: "/api/distributor/masterproduct/getall",
            withCredentials: true,
            body: {
                limit: 10,
                page: 0
            }
        })
        .then(function(result) {
            if(result.result)
            {
                mDcMasterProduct.list = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
            if(cb)
            {
                cb();
            }
                
        })
    },
    current: datakosong,
    load: function(id, cb) {
        if(id == 'new')
        {
            mDcMasterProduct.current = datakosong;
            cb();
        }
        else
        {
            return m.request({
                method: "POST",
                url: "/api/distributor/masterproduct/get", 
                withCredentials: true,
                body: {
                    id: id
                }
            })
            .then(function(result) {
                if(result.result)
                {
                    mDcMasterProduct.current = result.data;
                    //console.log(mDcOutlet.current);
                }
                else{
                    if(result.error[0].message == "You're not login")
                    {
                        m.route.set('/login');
                    }
                }
                cb();
            })
        }
        
    },

    save: function() {
        return m.request({
            method: "POST",
            url: "/api/distributor/masterproduct/insert",
            body: mDcMasterProduct.current,
            withCredentials: true,
        })
        .then(function(result) {
            if(result.result)
            {
                //
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    }
}

module.exports = mDcMasterProduct