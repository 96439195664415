import m from "mithril"
import Table from "../component/table"
import Navbar from "../component/navbar"
import LeftMenu from "../component/leftmenu"
import Footer from "../component/footer"
import '../../node_modules/flatpickr/dist/flatpickr.min.css';
import '../../node_modules/tabulator-tables/dist/css/tabulator.min.css';
import flatpickr from "flatpickr";
import Tabulator from "tabulator-tables";
import Chart from 'chart.js';
import moment from 'moment';

var numberWithCommas = (x) => {
  if(x == undefined)
      return x;
  if (typeof x === 'string' || x instanceof String)
      return x;
  x = Math.round(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function categoryInputs(cust) {
  return cust.map(function(u) {
      return m('option', {value: u._id}, u.name)
  })
}


var SalesDetail = {
    chart: undefined,
    table: undefined,
    generateChart: function(data){
      if(this.chart)
      {
          this.chart.destroy();
      }

      let ctx = document.getElementById('mychart');
      let backgroundColor = [];
      let borderColor = [];
      let labels = [];
      let dataisi = [];
      for(let i=0;i<data.length;i++)
      {
        backgroundColor.push('rgba(255, 99, 132, 0.2)');
        borderColor.push('rgba(255, 99, 132, 1)');
        labels.push(data[i].productName);
        dataisi.push(data[i].value);
      }
      this.chart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: labels,
              datasets: [{
                  label: '# of Sales',
                  data: dataisi,
                  backgroundColor: backgroundColor,
                  borderColor: borderColor,
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              },
              tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        //console.log(tooltipItem);
                        //if(self.DataLokal.byValuePilih == 'value')
                        return 'Rp '+numberWithCommas(tooltipItem.yLabel); //tooltipItem.yLabel;
                        //return self.numberWithCommas(tooltipItem.yLabel);
                    }
                }
            },
            maintainAspectRatio: false,
            scales:{
                xAxes: [{
                    //stacked: true,
                    ticks: {
                        beginAtZero:true,
                        callback: function(value, index, values) {
                            return value ;
                        }
                    }
                }],
                yAxes: [{
                    //stacked: true,
                    ticks: {
                        beginAtZero:true,
                        callback: function(value, index, values) {
                            //if(self.DataLokal.byValuePilih == 'value')
                            return 'Rp '+numberWithCommas(value);
                            //return self.numberWithCommas(value);
                        }
                    }
                }]
            }
          }
      });
    },
    loadData: function(from, to){
      let self = this;
      m.request({
          method: "POST",
          url: "/api/report/sales/by/item",
          body: {
            date_from: from,
            date_to: to,
            outlet: self.tipe
          },
          withCredentials: true
      })
      .then(function(data) {
          if(data.result)
          {
              console.log(data.data);
              self.totalqty = 0;
              self.totalrp = 0;
              for(let i=0;i<data.data.length;i++)
              {
                data.data[i].no = i +1;
                self.totalqty += data.data[i].qty;
                self.totalrp += data.data[i].value;
              }
              self.totaltax = data.totaltax;
              self.jumtrx = data.jumtrx;
              self.totalsemua = self.totalrp+data.totaltax;
              self.generateChart(data.data);
              self.table.setData(data.data);
          }
          else
          {
              alert('get sales summary gagal');
          }
      });
    },
    DateFrom: '',
    DateTo: '',
    oninit: function(){
      let self = this;
      self.tipe = 'ALL';
      self.totalqty = 0;
      self.totalrp = 0;
      self.totaltax = 0;
      self.totalsemua = 0;
      self.jumtrx = 0;

      self.categories = [];
      m.request({
            method: "POST",
            url: "/api/outlets/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.categories = [];
                self.categories.push({
                    _id: 'ALL',
                    name: 'ALL'
                });
                for(let i=0;i<data.data.length;i++)
                {
                    self.categories.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('insert categories gagal');
            }
        });
    },
    oncreate: function(){
      let self = this;
      this.DateFrom =  moment().subtract(1, 'weeks');
      this.DateTo = moment();
      flatpickr('#tanggalan', {
        mode: "range",
        minDate: "2018-01", //"today",
        dateFormat: "d-m-Y",
        //minDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
        defaultDate: [this.DateFrom.format('DD-MM-YYYY'), this.DateTo.format('DD-MM-YYYY')],
        onReady: function(){
          console.log('ok ready tanggalan');
          self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
        },
        onChange: function(selectedDates, dateStr, instance) {
          if(selectedDates.length == 2)
          {
            self.DateFrom =  moment(selectedDates[0]);
            self.DateTo = moment(selectedDates[1]);
          }
          //console.log(selectedDates);
          //console.log(dateStr);
        }
      });

      self.table = new Tabulator("#tabledetail", {
        height:"311px",
        columns:[
        {title:"No", field:"no"},
        {title:"Product", field:"productName", sorter:"string"},
        {title:"Jumlah Transaksi", field:"trx", align:"right", sorter:"number"},
        {title:"Jumlah Qty", field:"qty", align:"right", sorter:"number"},
        {title:"Total Rp", field:"value", align:"right", sorter:"number", formatter:function(cell, formatterParams){
          var value = cell.getValue();
          return numberWithCommas(value);
        }}
        ],
      });
    },
    view: function() {
      let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "salesdetail"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Sales Detail"),
                    m('div', {class:'columns'}, [
                      m('div', {class:'column is-one-thirds'}, [
                        m('input', {id:'tanggalan', type:'text', class:'input', placeholder:'Pilih Tanggal'})
                      ]),
                      m('div', {class:'column'}, [
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m('div', {class:'select'}, [
                                    m('select', {
                                        onchange: function(e){
                                            //console.log(e);
                                            self.tipe = e.target.value;
                                            //loadProduct(self.tipe, self);
                                        }}, [
                                        categoryInputs(self.categories)
                                    ])
                                ])
                            ]),
                            m("div", {class:"control"}, [
                              m('a', {class:'button', onclick: function(){
                                self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                              }}, 'Generate')
                            ])
                        ]),
                        /*m('a', {class:'button', onclick: function(){
                          self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                        }}, 'Generate')*/
                      ])
                    ]),
                    m('div', {class:'columns'}, [
                      m('div', {class:'column'}, [
                        m('canvas', {id:'mychart', width:'400', height:'500'})
                      ])
                    ]),
                    m('div', {id:'tabledetail'}),
                    m('div', {}, [
                      m('div', {}, 'Total Trx   : ' + numberWithCommas(self.jumtrx)),
                      m('div', {}, 'Total Qty   : ' + numberWithCommas(self.totalqty)),
                      m('div', {}, 'Total Value : ' + numberWithCommas(self.totalrp)),
                      m('div', {}, 'Total Tax   : ' + numberWithCommas(self.totaltax)),
                      m('div', {}, 'Total Akhir : ' + numberWithCommas(self.totalsemua))
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = SalesDetail;
