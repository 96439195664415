import m from "mithril"

var Role = {
    oninit: function(vnode){
        //loadDataPotitions(vnode.attrs.id)
    },
    view: function() {
        var self = this;
        return m('div', 'Role');
    }
}

module.exports = Role;
