import m from "mithril";
import classNames from "classnames";

module.exports = {
    onClick: function(e){

        let elem = document.querySelector('#leftmenu');
        let allElems = elem.querySelectorAll('.itm');

        for(let i=0;i<allElems.length;i++){
            allElems[i].classList.remove('is-active');
        }

        e.target.classList.add("is-active");
    },
    oninit: function(){

        let self = this;

        this.user = {
            role: 'others'
        };

        m.request({
            method:"POST",
            url: `/api/auth/get`,
            body:{},
            withCredentials:true
        }).then((res_auth)=>{

            if(res_auth.result){

                self.user = res_auth.data;
                if(!self.user.role){

                    self.user.role = 'others'
                }
                m.redraw();
            }else{

                if(res_auth.error[0].message == "You're not login"){
                    
                    m.request({
                        method: "POST",
                        url: "/api/auth/logout",
                        withCredentials: true,
                    }).then(() => {

                        location.href = '/';
                    }).catch((err)=>{

                        console.log(err);
                        alert(err.message);
                    });
                }
            }
        }).catch((err)=>{

            console.log(err);
            alert(err.message);
        });
    },
    view:function(vnode){

        let self = this;
        const current_active = vnode.attrs.title;
        
        const template = m("aside", {id:"leftmenu", class: "menu is-hidden-touch"}, [
            m("p", {class: "menu-label"}, "Report"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((current_active == "sales") ? ' is-active' : ''), href: "#!/warehouse/report/sales", onclick: this.onClick.bind(this)}, "Sales Summary")
                ])
            ]),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((current_active == "retur") ? ' is-active' : ''), href: "#!/warehouse/report/retur", onclick: this.onClick.bind(this)}, "Retur Summary")
                ])
            ]),
            // m("p", {class: "menu-label"}, "Distribusi"),
            // m("ul", {class: "menu-list"}, [
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "dcsummary") ? ' is-active' : ''), href: "#!/dc/summary", onclick: this.onClick.bind(this)}, "Summary")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "dc") ? ' is-active' : ''), href: "#!/dc", onclick: this.onClick.bind(this)}, "DC")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "purchase_orders") ? ' is-active' : ''), href: "#!/purchase_orders", onclick: this.onClick.bind(this)}, "Purchase Orders")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "po_report") ? ' is-active' : ''), href: "#!/purchase_orders/reports", onclick: this.onClick.bind(this)}, "Report PO")
            //     ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "gen_va") ? ' is-active' : ''), href: "#!/va/pending", onclick: this.onClick.bind(this)}, "Pending VA Generate")
            //     ]),
            //     // m("li", [
            //     //     m("a", {class:"itm"+((vnode.attrs.title == "dcproduct") ? ' is-active' : ''), href: "#!/dc/masterproduct", onclick: this.onClick.bind(this)}, "Master Product")
            //     // ]),
            //     m("li", [
            //         m("a", {class:"itm"+((vnode.attrs.title == "payment") ? ' is-active' : ''), href: "#!/payment", onclick: this.onClick.bind(this)}, "Payment")
            //     ])
            // ]),
            m("p", {class: "menu-label"}, "Manage"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "products") ? ' is-active' : ''), href: "#!/warehouse/products", onclick: this.onClick.bind(this)}, "Products")
                ]),
            ])
        ]);

        return template;
    }
};