import m from "mithril"
import bulma from "bulma"
import Home from "./pages/home"
import TaxSummary from "./pages/tax_summary"
import SalesDetail from "./pages/salesdetail"
import Customers from "./pages/customers/customers"
import Devices from "./pages/devices/devices"
import Outlets from "./pages/outlets/outlets"
import AddOutlet from "./pages/outlets/add"
import EditOutlet from "./pages/outlets/edit"
import Users from "./pages/users/users"
import AddUser from "./pages/users/add"
import EditUser from "./pages/users/edit"
import Login from "./pages/login"
import Register from "./pages/register"
import AddCustomers from "./pages/customers/add"
import EditCustomers from "./pages/customers/edit"
import Category from "./pages/category/category"
import AddCategory from "./pages/category/add"
import EditCategory from "./pages/category/edit"
import Product from "./pages/product/product"
import AddProducts from "./pages/product/add"
import EditProducts from "./pages/product/edit"
import Postransactions from "./pages/postransactions/postransactions"
import ExportPostransactions from './pages/postransactions/exportpostransaction';
import RoasteryTransactions from "./pages/roastery/roasterytransaction"
import RoasteryDetail from "./pages/roastery/roasterydetail"
import Detiltransactions from "./pages/postransactions/detiltransactions"
import Profile from "./pages/profile/profile"
import Settings from "./pages/settings/settings"
import Payment from "./pages/payment/payment"

import PurchaseOrder from "./pages/purchase_orders/index"
import ReportPurchaseOrder from "./pages/purchase_orders/report"
import ProductAttributeList from "./pages/product/attributes/index"
import ProductAttributeAdd from "./pages/product/attributes/add"
import DCProducts from "./pages/dc/products"
import AddDCProducts from "./pages/dc/add_products"
import DCProductsStockHistory from "./pages/dc/products_stocks"
import UpdateDCProductsStockHistory from "./pages/dc/update_stock_products"

import Dc from "./pages/dc/dc"
import DcHome from "./pages/dc/home"
import SalesDC from "./pages/dc/salesdc"
import DcOutlets from "./pages/dc/outlets"
import DcProduct from "./pages/dc/product"
import DcSalesman from "./pages/dc/salesman"
import DcMasterProduct from "./pages/dc/masterproduct"
import DcAddNewMasterProduct from "./pages/dc/addnewmasterproduct"
import DcAddNewSalesman from "./pages/dc/addnewsalesman"
import DcAddNewProduct from "./pages/dc/addnewproduct"
import DcAddNewOutlet from "./pages/dc/addnewoutlet"
import DcBuy from "./pages/dc/buy"
import DcSell from "./pages/dc/sell"
import DcSummary from "./pages/dc/summary"
import DcSummaryDetail from "./pages/dc/summary_detail"
import DcPO from "./pages/dc/po"
import AddDc from "./pages/dc/add"
import Soul from "./pages/sementara/soul"
import Backuptransaction from "./pages/sementara/backuptransaction"

// ## YoyiC
import salesReport from "./pages/yoyic/report/sales_dc"
import salesSummary from "./pages/yoyic/report/sales_summary"
import returSummary from "./pages/yoyic/report/retur_summary"

import warehouseReturReport from "./pages/warehouse/report/retur";
import warehouseSalesReport from "./pages/warehouse/report/sales";
import warehouseProducts from "./pages/warehouse/menu/products";
import warehouseProductAdd from "./pages/warehouse/menu/product_add";
import warehouseProductStock from "./pages/warehouse/menu/product_stock";
import warehouseProductStockUpdate from "./pages/warehouse/menu/product_stock_update"

import vaPending from "./pages/yoyic/va/pending"

import "./css/app.css"

var init = function(){
    m.route(root, "/home", {
        "/home": Home,
        "/tax_summary": TaxSummary,
        "/salesdetail": SalesDetail,
        "/profile": Profile,
        "/postransactions/view/:id": Detiltransactions,
        "/postransactions/export": ExportPostransactions,
        "/postransactions/:limit/:page/:store": Postransactions,
        "/roasterytransactions/:limit/:page/:store": RoasteryTransactions,
        "/roasterytransactions/view/:id": RoasteryDetail,
        "/customers": Customers,
        "/customers/add": AddCustomers,
        "/customers/edit/:id": EditCustomers,
        "/outlets": Outlets,
        "/outlets/add": AddOutlet, 
        "/outlets/edit/:id": EditOutlet,
        "/users": Users,
        "/users/add": AddUser,
        "/users/edit/:id": EditUser,
        "/devices": Devices,
        "/category": Category,
        "/product/edit/:id": EditProducts,
        "/product/:limit/:page/:tipe": Product,
        "/product/add": AddProducts,
        "/products/attributes/add":ProductAttributeAdd,
        "/products/attributes":ProductAttributeList,
        "/category/add": AddCategory,
        "/category/edit/:id": EditCategory, 
        "/settings": Settings, 
        "/pengajian/soul": Soul, 
        "/dc/home/:id/:name": DcHome,
        "/dc/outlets/:id/:name": DcOutlets,
        "/dc/product/:id/:name": DcProduct,
        "/dc/salesman/:id/:name": DcSalesman,
        "/dc/salesman/edit/:pid/:id/:name": DcAddNewSalesman,
        "/dc/product/edit/:pid/:id/:name": DcAddNewProduct,
        "/dc/outlet/edit/:pid/:id/:name": DcAddNewOutlet,
        "/dc/buy/:id/:name": DcBuy,
        "/dc/po/:id/:name/:limit/:page": DcPO,
        "/dc/sell/:id/:name": DcSell,
        "/backup/transaction": Backuptransaction, 
        // ## YoyiC
        "/yoyic/report/summary": salesSummary,
        "/yoyic/report/retur": returSummary,
        "/yoyic/report/sales": salesReport,
        "/dc/products/add":AddDCProducts,
        "/dc/add/:id": AddDc,
        "/dc/masterproduct/edit/:id": DcAddNewMasterProduct,
        "/dc": Dc, 
        "/dc/masterproduct": DcMasterProduct,
        "/dc/products/:id/stocks":DCProductsStockHistory,
        "/dc/products":DCProducts,
        "/dc/salesdc": SalesDC,
        "/dc/summary": DcSummary,
        "/dc/summarydetail/:dcname/:dcid": DcSummaryDetail,
        "/dc/products/:id/stocks/changes":UpdateDCProductsStockHistory,
        "/purchase_orders":PurchaseOrder,
        "/purchase_orders/reports":ReportPurchaseOrder,
        "/payment": Payment,
        "/va/pending": vaPending,
        // ## Warehouse
        "/warehouse/report/retur": warehouseReturReport,
        "/warehouse/report/sales": warehouseSalesReport,
        "/warehouse/products": warehouseProducts,
        "/warehouse/products/add": warehouseProductAdd,
        "/warehouse/products/:id/stocks": warehouseProductStock,
        "/warehouse/products/:id/stocks/changes": warehouseProductStockUpdate,
    });
}
var root = document.body;
var aToken; // = localStorage.getItem("tokenA");
if(aToken)
{
    init();
}
else
{
    m.request({
        method: "POST",
        url: "/api/auth/check",
        data: {},
        withCredentials: true,
    })
    .then(function(data) {
        if(data.result)
        {
            aToken = 'OK';
            init();
        }
        else{
            m.route(root, "/login", {
                "/login": Login,
                "/register": Register
            });
        }
    });

    
}




