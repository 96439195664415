import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"

var DcSell = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "penjualan", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, self.title+ " Penjualan"),
                      m("div", {class:"field is-grouped"}, [
                          m("p", {class:"control"}, [
                              m("a", {class:"button is-link", href: "#!/dc/add/new"}, "Add New")
                          ])
                      ]),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Kode"),
                                m("th", "Name"),
                                m("th", "Kota"),
                                m("th", "Alamat"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            //ListTable(self, mDc.list)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcSell;