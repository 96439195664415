import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"

var mDc = require("../../models/dc")

var AddDc = {
    oninit: function(vnode){
        /*this.data = {
            kode: '',
            name: '',
            city: '',
            address: '',
            latitude: '',
            longitude: ''
        }*/
        this.error = {
            kode: undefined,
            name: undefined,
            city: undefined,
            address: undefined,
            phone: undefined,
            contact: undefined,
            email: undefined,
            latitude: undefined,
            longitude: undefined
        }

        mDc.load(vnode.attrs.id)
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "dc"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Add New Distribution Center"),
                    m("form", {onsubmit: function(e){
                        e.preventDefault();
                        if(!mDc.current._id)
                            mDc.current._id = '';
                        if(!mDc.current.created_at)
                            mDc.current.created_at = '';
                        if(!mDc.current.updated_at)
                            mDc.current.updated_at = '';
                        if(!mDc.current.clientid)
                            mDc.current.clientid = '';
                            
                        mDc.save();
                        m.route.set('/dc');

                        return false;
                    }}, [
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kode"),
                                    m("div", {class:"control has-icons-left"+((self.error.kode) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.kode, class:"input"+((self.error.kode) ? ' is-danger' : ''), type:"text", placeholder:"kode",
                                        oninput: function(e){
                                            mDc.current.kode = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.kode) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.kode) ? m("p", {class:"help is-danger"}, "This kode is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Nama"),
                                    m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.name, class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"name",
                                        oninput: function(e){
                                            mDc.current.name = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.name) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kota"),
                                    m("div", {class:"control has-icons-left"+((self.error.city) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.city, class:"input"+((self.error.city) ? ' is-danger' : ''), type:"text", placeholder:"kota",
                                        oninput: function(e){
                                            mDc.current.city = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.city) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.city) ? m("p", {class:"help is-danger"}, "This city is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Alamat"),
                                    m("div", {class:"control has-icons-left"+((self.error.address) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.address, class:"input"+((self.error.address) ? ' is-danger' : ''), type:"text", placeholder:"alamat",
                                        oninput: function(e){
                                            mDc.current.address = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.address) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.address) ? m("p", {class:"help is-danger"}, "This address is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Phone"),
                                    m("div", {class:"control has-icons-left"+((self.error.phone) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.phone, class:"input"+((self.error.phone) ? ' is-danger' : ''), type:"text", placeholder:"phone",
                                        oninput: function(e){
                                            mDc.current.phone = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.phone) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.phone) ? m("p", {class:"help is-danger"}, "This phone is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Contact"),
                                    m("div", {class:"control has-icons-left"+((self.error.contact) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.contact, class:"input"+((self.error.contact) ? ' is-danger' : ''), type:"text", placeholder:"contact",
                                        oninput: function(e){
                                            mDc.current.contact = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.contact) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.contact) ? m("p", {class:"help is-danger"}, "This contact is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Email"),
                                    m("div", {class:"control has-icons-left"+((self.error.email) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.email, class:"input"+((self.error.email) ? ' is-danger' : ''), type:"email", placeholder:"email",
                                        oninput: function(e){
                                            mDc.current.email = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.email) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Latitude"),
                                    m("div", {class:"control has-icons-left"+((self.error.latitude) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.latitude, class:"input"+((self.error.latitude) ? ' is-danger' : ''), type:"text", placeholder:"latitude",
                                        oninput: function(e){
                                            mDc.current.latitude = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.latitude) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.latitude) ? m("p", {class:"help is-danger"}, "This latitude is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Longitude"),
                                    m("div", {class:"control has-icons-left"+((self.error.longitude) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.longitude, class:"input"+((self.error.longitude) ? ' is-danger' : ''), type:"text", placeholder:"longitude",
                                        oninput: function(e){
                                            mDc.current.longitude = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.longitude) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.longitude) ? m("p", {class:"help is-danger"}, "This longitude is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m('button', {class:'button is-link', type:'submit'}, 'Simpan')
                            ]),
                        ])
                        
                    ]),
                    
                ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = AddDc;