var m = require("mithril")

var datakosong = {
    kode: '',
    name: '',
    city: '',
    address: '',
    phone: '',
    email: '',
    password: ''
}
var mDcSalesman = {
    list: [],
    loadList: function(dcid) {
        return m.request({
            method: "POST",
            url: "/api/distributor/salesman/getall",
            withCredentials: true,
            body: {
                limit: 10,
                page: 0,
                dcid: dcid
            }
        })
        .then(function(result) {
            if(result.result)
            {
                mDcSalesman.list = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
                
        })
    },
    current: datakosong,
    load: function(id, cb) {
        if(id == 'new')
        {
            mDcSalesman.current = datakosong;
            cb();
        }
        else
        {
            return m.request({
                method: "POST",
                url: "/api/distributor/salesman/get", 
                withCredentials: true,
                body: {
                    id: id
                }
            })
            .then(function(result) {
                if(result.result)
                {
                    mDcSalesman.current = result.data;
                    //console.log(mDcOutlet.current);
                }
                else{
                    if(result.error[0].message == "You're not login")
                    {
                        m.route.set('/login');
                    }
                }
                cb();
            })
        }
        
    },

    save: function() {
        return m.request({
            method: "POST",
            url: "/api/distributor/salesman/insert",
            body: mDcSalesman.current,
            withCredentials: true,
        })
        .then(function(result) {
            if(result.result)
            {
                //
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    }
}

module.exports = mDcSalesman