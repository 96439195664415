import m from "mithril"
import "../css/login.css"
import Validator from "validator"

// version 0.0.1.1

var Register = {
    oninit: function(vnode) {
        this.data = {
            email: "",
            name: "",
            password: "",
            confirm: "",
            term: false
        };
        this.error = {
            email: undefined,
            name: undefined,
            password: undefined,
            confirm: undefined,
            term: false
        };
    },
    view: function(vnode) {
        self = this;
        return m("section", {class: "hero is-success is-fullheight", style:"margin-top: -60px;"}, [
            m("div", {class: "hero-body"}, [
                m("div", {class:"container has-text-centered"}, [
                    m("div", {class:"column is-4 is-offset-4"}, [
                        m("h3", {class:"title has-text-grey"}, "Sign Up"),
                        m("p", {class:"subtitle has-text-grey"}, "Please Register first"),
                        m("div", {class:"box"}, [
                            m('figure', {class:"avatar"}, [
                                m("img", {src: "https://placehold.it/128x128"})
                            ]),
                            m("form",{
                                onsubmit: function(e){
                                    e.preventDefault();
                                    console.log(self.data);
                                    self.error.email = !Validator.isEmail(self.data.email);;
                                    self.error.name = !Validator.isLength(self.data.name, {min:4, max: 100});
                                    self.error.password = !Validator.isLength(self.data.password, {min:6, max: 20});
                                    self.error.confirm = !Validator.isLength(self.data.confirm, {min:6, max: 20});
                                    self.error.term = !self.data.term;
                                    console.log(self.error);
                                    if(!self.error.email &&
                                        !self.error.name &&
                                        !self.error.password &&
                                        !self.error.confirm &&
                                        !self.error.term)
                                        {
                                            m.request({
                                                method: "POST",
                                                url: "/api/auth/register",
                                                body: self.data
                                            })
                                            .then(function(data) {
                                                if(data.result)
                                                {
                                                    //m.route("/login");
                                                    m.route.set('/login', {})
                                                }
                                                else
                                                {
                                                    alert('register gagal');
                                                }
                                            });
                                        }
                                    

                                }},[
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.email) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.email) ? ' is-danger' : ''), type: "email", placeholder:"Your Email", autofocus:"",
                                        oninput: function(e){
                                            self.data.email = e.target.value;
                                        }}),
                                        ((self.error.email) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.name) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.name) ? ' is-danger' : ''), type: "text", placeholder:"Your Name",
                                        oninput: function(e){
                                            self.data.name = e.target.value;
                                        }}),
                                        ((self.error.name) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.password) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.password) ? ' is-danger' : ''), type:"password", placeholder:"Your Password",
                                        oninput: function(e){
                                            self.data.password = e.target.value;
                                        }}),
                                        ((self.error.password) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.password) ? m("p", {class:"help is-danger"}, "This password is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.confirm) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.confirm) ? ' is-danger' : ''), type:"password", placeholder:"Confirm Password",
                                        oninput: function(e){
                                            self.data.confirm = e.target.value;
                                        }}),
                                        ((self.error.confirm) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.confirm) ? m("p", {class:"help is-danger"}, "This password is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("label", {class:"checkbox"}, [
                                        m("input", {type:"checkbox",
                                        onclick: function(e){
                                            self.data.term = e.target.checked;
                                        }}),
                                        " Term Accepts"
                                    ]),
                                    ((self.error.term) ? m("p", {class:"help is-danger"}, "This password is invalid") : "")
                                ]),
                                m("button", {class:"button is-block is-info is-large is-fullwidth", type: "submit"}, "Login")
                            ])
                        ]),
                        m("p", {class: "has-text-grey"}, [
                            m("a", {href: "#!/login"}, "Have Account ?"),
                            " ",
                            m("a", {href: "/"}, "Need Help?")
                        ])
                    ])
                ])
            ])
        ]);
      }
}

module.exports = Register;
