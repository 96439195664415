import m from "mithril"
import Table from "../component/table"
import Navbar from "../component/navbar"
import LeftMenu from "../component/leftmenu"
import Footer from "../component/footer"
import '../../node_modules/flatpickr/dist/flatpickr.min.css';
import '../../node_modules/tabulator-tables/dist/css/tabulator.min.css';
import flatpickr from "flatpickr";
import Tabulator from "tabulator-tables";
import Chart from 'chart.js';
import moment from 'moment';

var numberWithCommas = (x) => {
  if(x == undefined)
      return x;
  if (typeof x === 'string' || x instanceof String)
      return x;
  x = Math.round(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function categoryInputs(cust) {
  return cust.map(function(u) {
      return m('option', {value: u._id}, u.name)
  })
}

var TaxSummary = {
    chart: undefined,
    table: undefined,
    generateChart: function(data){
      if(this.chart)
      {
          this.chart.destroy();
      }

      let ctx = document.getElementById('mychart');
      //let backgroundColor = [];
      //let borderColor = [];
      let labels = [];
      //let dataisi = [];

      let dataok = [];
      for(let i=0;i<data.length;i++)
      {
        let adatoko = false;
        for(let x=0;x<dataok.length;x++)
        {
          if (data[i].storeid == dataok[x].storeid)
          {
            adatoko = true;
            if (data[i].date == dataok[x].date)
            {
              //
            }
            else
            {
              let datadetail = dataok[x].datadetail;
              let dataokd = dataok[x].data;
              datadetail.push({
                total: data[i].total,
                trx: data[i].trx,
                value: data[i].total,
                date: data[i].date
              });
              dataokd.push(data[i].total);
            }
          }
        }

        /*
rgba(244, 67, 54,1.0)
rgba(33, 150, 243,1.0)
rgba(139, 195, 74,1.0)
rgba(255, 152, 0,1.0)
        */
        if(!adatoko)
        {
          let datadetail = [];
          let dataokd = [];
          datadetail.push({
            total: data[i].total,
            trx: data[i].trx,
            value: data[i].total,
            date: data[i].date
          });
          dataokd.push(data[i].total);
          let color = '';

          console.log('AYE',i,data[i].store_name);

          if(i == 0)
            color = 'rgba(208, 26, 38,1.0)';
          else if(i == 1)
            color = 'rgba(242, 146, 152,1.0)';
          else if(i == 2)
            color = 'rgba(73, 9, 13,1.0)';
          else if(i == 3)
            color = 'rgba(19, 111, 99,1.0)';
          else if(i == 4)
            color = 'rgba(1, 151, 246,1.0)';
          else if(i == 5)
            color = 'rgba(204, 150, 0,1.0)';
          else if(i == 6)
            color = 'rgba(255, 189, 0,1.0)';
          else if(i == 7)
            color = 'rgba(128, 15, 23,1.0)';

          dataok.push({
            storeid: data[i].storeid,
            store_name: data[i].store_name,
            label: data[i].store_name,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            datadetail: datadetail,
            data: dataokd
          });
        }

      }
      //console.log(dataok);

      for(let i=0;i<data.length;i++)
      {
        /*backgroundColor.push('rgba(255, 99, 132, 0.2)');
        borderColor.push('rgba(255, 99, 132, 1)');
        labels.push(data[i].date);
        dataisi.push(data[i].value);*/
        let ada = false;
        for(let x=0;x<labels.length;x++)
        {
          if (data[i].date == labels[x])
          {
            ada = true;
          }
        }
        if(!ada)
        {
          labels.push(data[i].date);
        }
      }
      this.chart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: labels,
              datasets: dataok
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              },
              tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        //console.log(tooltipItem);
                        //if(self.DataLokal.byValuePilih == 'value')
                        return 'Rp '+numberWithCommas(tooltipItem.yLabel); //tooltipItem.yLabel;
                        //return self.numberWithCommas(tooltipItem.yLabel);
                    }
                }
            },
            maintainAspectRatio: false,
            scales:{
                xAxes: [{
                    //stacked: true,
                    ticks: {
                        beginAtZero:true,
                        callback: function(value, index, values) {
                            return value ;
                        }
                    }
                }],
                yAxes: [{
                    //stacked: true,
                    ticks: {
                        beginAtZero:true,
                        callback: function(value, index, values) {
                            //if(self.DataLokal.byValuePilih == 'value')
                            return 'Rp '+numberWithCommas(value);
                            //return self.numberWithCommas(value);
                        }
                    }
                }]
            }
          }
      });
    },
    loadData: function(from, to){
      let self = this;
      m.request({
          method: "POST",
          url: "/api/report/sales/tax_summary",
          body: {
            date_from: from,
            date_to: to,
            outlet: self.tipe
          },
          withCredentials: true
      })
      .then(function(data) {
          if(data.result)
          {
              console.log(data.data);
              for(let i=0;i<data.data.length;i++)
              {
                data.data[i].no = i +1;
              }
              self.generateChart(data.data);
              self.table.setData(data.data);
          }
          else
          {
              alert('get sales summary gagal');
          }
      });
    },
    DateFrom: '',
    DateTo: '',
    oninit: function(){
      let self = this;
      self.tipe = 'ALL';

      self.categories = [];
      m.request({
            method: "POST",
            url: "/api/outlets/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.categories = [];
                self.categories.push({
                    _id: 'ALL',
                    name: 'ALL'
                });
                for(let i=0;i<data.data.length;i++)
                {
                    self.categories.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('insert categories gagal');
            }
        });
    },
    oncreate: function(){
      let self = this;
      

      this.DateFrom =  moment().subtract(1, 'weeks');
      this.DateTo = moment();
      flatpickr('#tanggalan', {
        mode: "range",
        minDate: "2018-01", //"today",
        dateFormat: "d-m-Y",
        //minDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
        defaultDate: [this.DateFrom.format('DD-MM-YYYY'), this.DateTo.format('DD-MM-YYYY')],
        onReady: function(){
          console.log('ok ready tanggalan');
          self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
        },
        onChange: function(selectedDates, dateStr, instance) {
          if(selectedDates.length == 2)
          {
            self.DateFrom =  moment(selectedDates[0]);
            self.DateTo = moment(selectedDates[1]);
          }
          //console.log(selectedDates);
          //console.log(dateStr);
        }
      });

      self.table = new Tabulator("#tablesummary", {
        height:"311px",
        columns:[
        {title:"No", field:"no"},
        {title:"Tanggal", field:"date", sorter:"date"},
        {title:"Outlet", field:"store_name"},
        {title:"Jumlah Transaksi", field:"trx", align:"right"},
        {title:"Pajak Lapor", field:"lapor", align:"right",formatter:function(cell, formatterParams){
            var value = cell.getValue();
            return numberWithCommas(value);
        }},
        {title:"Pajak Non-Lapor", field:"nonlapor", align:"right",formatter:function(cell, formatterParams){
            var value = cell.getValue();
            return numberWithCommas(value);
        }},
        {title:"Total Penerimaan Pajak", field:"total", align:"right",formatter:function(cell, formatterParams){
            var value = cell.getValue();
            return numberWithCommas(value);
        }},
        ],
    });
      
    },
    view: function() {
      let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "tax_summary"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Tax Summary"),
                    m('div', {class:'columns'}, [
                      m('div', {class:'column is-one-thirds'}, [
                        m('input', {id:'tanggalan', type:'text', class:'input', placeholder:'Pilih Tanggal'})
                      ]),
                      m('div', {class:'column'}, [
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m('div', {class:'select'}, [
                                    m('select', {
                                        onchange: function(e){
                                            //console.log(e);
                                            self.tipe = e.target.value;
                                            //loadProduct(self.tipe, self);
                                        }}, [
                                        categoryInputs(self.categories)
                                    ])
                                ])
                            ]),
                            m("div", {class:"control"}, [
                              m('a', {class:'button', onclick: function(){
                                self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                              }}, 'Generate')
                            ])
                        ]),
                        /*m('a', {class:'button', onclick: function(){
                          self.loadData(self.DateFrom.format('DD-MM-YYYY'), self.DateTo.format('DD-MM-YYYY'));
                        }}, 'Generate')*/
                      ])
                    ]),
                    m('div', {class:'columns'}, [
                      m('div', {class:'column'}, [
                        m('canvas', {id:'mychart', width:'400', height:'300'})
                      ])
                    ]),
                    m('div', {id:'tablesummary'})
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = TaxSummary;