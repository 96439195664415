import m from "mithril"
import "../css/login.css"
import Validator from "validator"

// version 0.0.1.1

var Login = {
    oninit: function(vnode) {
        this.data = {
            email: "",
            password: "",
            remember: false
        };
        this.error = {
            email: undefined,
            password: undefined,
            remember: false
        };
    },
    view: function(vnode) {
        self = this;
        return m("section", {class: "hero is-success is-fullheight", style:"margin-top: -60px;"}, [
            m("div", {class: "hero-body"}, [
                m("div", {class:"container has-text-centered"}, [
                    m("div", {class:"column is-4 is-offset-4"}, [
                        m("h3", {class:"title has-text-grey"}, "Login"),
                        m("p", {class:"subtitle has-text-grey"}, "Please login to proceed"),
                        m("div", {class:"box"}, [
                            m('figure', {class:"avatar"}, [
                                m("img", {src: "https://placehold.it/128x128"})
                            ]),
                            m("form",{
                                    onsubmit: function(e) {
                                    e.preventDefault()
                                    //User.save()
                                    //console.log(self.data);
                                    self.error.email = !Validator.isEmail(self.data.email);;
                                    self.error.password = !Validator.isLength(self.data.password, {min:6, max: 20});
                                    self.error.remember = !self.data.remember;
                                    //console.log(self.error);
                                    if(!self.error.email &&
                                        !self.error.password)
                                        {
                                            console.log(self.data);
                                            m.request({
                                                method: "POST",
                                                url: "/api/auth/login",
                                                //data: self.data,
                                                body: self.data
                                            })
                                            .then(function(data) {
                                                if(data.result)
                                                {
                                                    location.href = '/';
                                                }
                                                else
                                                {
                                                    alert('login gagal');
                                                }
                                            });
                                        }
                                }},[
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.email) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.email) ? ' is-danger' : ''), type: "email", placeholder:"Your Email", autofocus:"",
                                        oninput: function(e){
                                            self.data.email = e.target.value;
                                        }}),
                                        ((self.error.email) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("div", {class:"control"+((self.error.password) ? ' has-icons-right' : '')}, [
                                        m("input", {class: "input"+((self.error.password) ? ' is-danger' : ''), type:"password", placeholder:"Your Password",
                                        oninput: function(e){
                                            self.data.password = e.target.value;
                                        }}),
                                        ((self.error.password) ?
                                        m("span", {class:"icon is-small is-right"}, [
                                            m("i", {class:"fas fa-exclamation-triangle"})
                                        ]) : "")
                                    ]),
                                    ((self.error.password) ? m("p", {class:"help is-danger"}, "This password is invalid") : "")
                                ]),
                                m("div", {class:"field"}, [
                                    m("label", {class:"checkbox"}, [
                                        m("input", {type:"checkbox",
                                        onclick: function(e){
                                            //console.log(e.target.checked);
                                            self.data.remember = e.target.checked;
                                        }}),
                                        " Remember me"
                                    ])
                                ]),
                                m("button", {class:"button is-block is-info is-large is-fullwidth", type: "submit"}, "Login")
                            ])
                        ]),
                        m("p", {class: "has-text-grey"}, [
                            m("a", {href: "#!/register"}, "Signup"),
                            " ",
                            m("a", {href: "/"}, "Forgot Password"),
                            " ",
                            m("a", {href: "/"}, "Need Help?")
                        ])
                    ])
                ])
            ])
        ]);
      }
}

module.exports = Login;
