import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Notification from "../../component/notification"
import moment from "moment"
import "moment/locale/id"

function rowsInputs(self, cust) {
    return cust.map(function(u) {
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<self.datarows.length;i++)
                {
                    self.datarows[i].selected = false;
                }
                for(var i=0;i<self.datarows.length;i++)
                {
                    if(self.datarows[i]._id == this.getAttribute("strid"))
                    self.datarows[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [u.no]),
            m("td", [ moment(u.created_at).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a')]),
            m("td", [
                m('a', {href:'/upload/'+u.file}, 'Download')
            ])
        ])
    })
}

var Backuptransaction = {
    oninit: function(vnode) {
        this.datarows = [];
        let self = this;

        m.request({
            method: "POST",
            url: "/api/sementara/backup/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log('/api/sementara/backup/getall ', data.data);
                self.datarows = [];
                
                for(let i=0;i<data.data.length;i++)
                {
                    data.data[i].no = i+1;
                    self.datarows.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('/api/sementara/backup/getall gagal');
            }
        });
    },
    view: function() {
        let self = this;
        return m("div", [
            m("section", {class:"section"}, [
                m(Navbar)
            ]),
            m("section", {class:"section", style:"margin-top: -120px; "}, [
                m("div", {class:"columns"}, [
                    m("div", {class:"column is-one-fifth"}, [
                        m(LeftMenu, {title: "Backup Transaction Client"})
                    ]),
                    m("div", {class:"column"}, [
                        m("h1", {class:"title"}, "Backup Transaction Client"),
                        m("button", {class:"button", style:"margin-bottom: 20px; ", onclick: function(e){
                            
                            m.request({
                                method: "POST",
                                url: "/api/sementara/backup/request",
                                body: { limit: 10, page: 1 },
                                withCredentials: true,
                            })
                            .then(function(data) {
                                if(data.result)
                                {
                                    alert('Request berhasil');
                                }
                                else
                                {
                                    alert('/api/sementara/backup/getall gagal');
                                }
                            });
                        }}, "Request data client"),
                        m("table", { class: "table is-fullwidth is-narrow is-hoverable"}, [
                          m("thead", [
                              m("tr", [
                                  m("th", "No"),
                                  m("th", "Tanggal"),
                                  m("th", "File")
                              ])
                          ]),
                          m("tbody", [
                            rowsInputs(self, self.datarows)
                          ])
                        ]),
                    ])
                  ])
              ])
          ]);
    }
}

module.exports = Backuptransaction;