import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Validator from "validator"

var AddOutlet = {
    filename: '',
    oninit: function(vnode) {
        this.data = {
            name: "",
            kode: "",
            address: "",
            phone: "",
            active: false,
            isroastery: false
        };
        this.error = {
            name: undefined,
            kode: undefined,
            phone: undefined,
            address: undefined,
            active: false,
            isroastery: false
        };
    },
    view: function(vnode) {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "outlets"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Add Outlet"),
                      m("form",{
                        onsubmit: function(e) {
                        e.preventDefault()
                        //User.save()
                        console.log(self.data);
                        self.error.kode = !Validator.isLength(self.data.kode, {min:2, max: 40});
                        self.error.name = !Validator.isLength(self.data.name, {min:6, max: 60});
                        
                        console.log(self.error);
                        if(!self.error.kode &&
                            !self.error.name)
                            {
                                m.request({
                                    method: "POST",
                                    url: "/api/outlets/insert",
                                    body: self.data
                                })
                                .then(function(data) {
                                    if(data.result)
                                    {
                                        location.href = '#!/outlets';
                                    }
                                    else
                                    {
                                        alert('insert outlet gagal');
                                    }
                                });
                            }
                    }},[

                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Kode"),
                            m("div", {class:"control has-icons-left"+((self.error.kode) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.kode) ? ' is-danger' : ''), type:"text", placeholder:"kode",
                                oninput: function(e){
                                    self.data.kode = e.target.value;
                                }}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-user"})
                                ]),
                                ((self.error.kode) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.kode) ? m("p", {class:"help is-danger"}, "This kode is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Name"),
                            m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"Name",
                                oninput: function(e){
                                    self.data.name = e.target.value;
                                }}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-user"})
                                ]),
                                ((self.error.name) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Phone"),
                            m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.phone) ? ' is-danger' : ''), type:"text", placeholder:"Phone",
                                oninput: function(e){
                                    self.data.phone = e.target.value;
                                }}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-user"})
                                ]),
                                ((self.error.phone) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.phone) ? m("p", {class:"help is-danger"}, "This phone is invalid") : "")
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class:"label"}, "Address"),
                            m("div", {class:"control"}, [
                                m("textarea", {class:"textarea", placeholder:"Address",
                                oninput: function(e){
                                    self.data.address = e.target.value;
                                }})
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.active = e.target.checked;
                                    }}),
                                    " Active"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.isroastery = e.target.checked;
                                    }, checked: self.data.isroastery}),
                                    " Roastery"
                                ])
                            ])
                        ]),
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m("button", {class:"button is-link"}, "Submit")
                            ]),
                            m("div", {class:"control"}, [
                                m("a", {class:"button is-text", href: "#!/outlets"}, "Cancel")
                            ])
                        ])

                    ])

                      
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = AddOutlet;