import mithril from "mithril"
import Navbar from "../../../component/navbar"
import LeftMenu from "../../../component/sidebar_warehouse"
import Footer from "../../../component/footer"
import moment from 'moment';

import dc_products_model from "../../../models/dc_products"

let product_id = null;
let page = 1;
let limit = 10;
let filter_type = undefined;
let datas = null;
let paging = [];
let product_data = null;

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{
                
                let type_label = null;
                switch(data.type){
                    case 'in':
                        type_label = 'In';
                        break;
                    case 'out':
                        type_label = 'Out';
                        break;
                }
                
                let created_at = new Date(data.created_at);
                created_at = moment(created_at);

                return mithril("tr", {}, [
                    mithril('td', {}, [
                        mithril("p",created_at.format("DD-MM-YYYY hh:mm")),
                        // mithril("p","staff_name")
                    ]),
                    mithril('td', `${data.changes} ${data.unit_name}`),
                    mithril('td', `${data.before} ${data.unit_name}`),
                    mithril('td', `${data.after} ${data.unit_name}`),
                    mithril('td', type_label),
                    mithril('td', data.comment)
                ])
            })
        }else{

            return mithril("tr", {}, [
                mithril('td', {align:"center",colspan:6},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return mithril("tr", {}, [
            mithril('td', {align:"center",colspan:6},"Loading New Data ...")
        ]);
    }
}

const _redraw_data = (cb)=>{
    
    dc_products_model.loadProductStockHistory(product_id,page,limit,filter_type).then(([stock_datas,paging_data])=>{
    
        datas = stock_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return mithril("li", [
                mithril("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    mithril.redraw();
                    _redraw_data(()=>{
                        
                        mithril.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return mithril("li", [
                mithril("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

module.exports = {
    oninit:(vnode)=>{

        product_id = vnode.attrs.id;
        
        window.scrollTo(0,0);
        _redraw_data(()=>{

            dc_products_model.loadProductData(product_id).then((dtproduct)=>{

                product_data = dtproduct;
                mithril.redraw();
            }).catch(()=>{});
        });
    },
    view:()=>{
        
        return mithril("div", [
            mithril("section", {class:"section"}, [
                mithril(Navbar)
            ]),
            mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                mithril("div", {class:"columns"}, [
                    mithril("div", {class:"column is-one-fifth"}, [
                        mithril(LeftMenu, {title: "products"})
                    ]),
                    mithril("div", {class:"column"}, [
                        mithril("h1", {class:"title"}, (product_data) ? `DC Master Product History - ${product_data.name}` : 'DC Master Product History'),
                        mithril("button", {"class":"button is-info is-outlined is-pulled-right", onclick: function(){
                            mithril.route.set('/warehouse/products');
                        }},[
                            mithril("span", {"class":"icon is-small"}, 
                                mithril("i", {"class":"fas fa-angle-left"})
                            ),
                            mithril("span", "Back to List")
                        ]),
                        mithril("button", {"class":"button is-success is-outlined is-pulled-right", onclick: function(){
                            mithril.route.set(`/warehouse/products/${product_id}/stocks/changes`);
                        }},[
                            mithril("span", {"class":"icon is-small"}, 
                                mithril("i", {"class":"fas fa-plus-circle"})
                            ),
                            mithril("span", "Add Stock Changes")
                        ]),
                        mithril('div', {class: 'field is-pulled-left'}, [
                            mithril('div', {class: 'control'}, [
                                mithril('div', {class:'select'}, [
                                    mithril('select', {onchange: function(e){
                                        
                                        page = 1;
                                        datas = null;
                                        mithril.redraw();
                                        filter_type = (e.target.value != '0') ? e.target.value : undefined;
                                        _redraw_data(()=>{
                                            
                                            mithril.redraw();
                                        });
                                    }}, [
                                        mithril('option', {value: '0', selected: (filter_type === undefined) ? 'true' : ''}, 'All Type'),
                                        mithril('option', {value: 'in', selected: (filter_type == 'in') ? 'true' : ''}, 'In'),
                                        mithril('option', {value: 'out', selected: (filter_type == 'out') ? 'true' : ''}, 'Out')
                                    ])
                                ])
                            ])
                        ]),
                        mithril("div",{class:"is-clearfix"}),
                        mithril("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                            mithril("thead", [
                                mithril("tr", [
                                    mithril("th", "Date"),
                                    mithril("th", "Changes"),
                                    mithril("th", "Before"),
                                    mithril("th", "After"),
                                    mithril("th", "Type"),
                                    mithril("th", "Comment")
                                ])
                            ]),
                            mithril("tbody", [
                                handle_data()
                            ])
                        ]),
                        mithril('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                            mithril('a', {class:'pagination-previous', onclick: function(){
                                if(page > 1){

                                    page--;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Previous'),
                            mithril('a', {class:'pagination-next', onclick: function(){
                                if(page < paging.total_page){

                                    page++;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Next page'),
                            mithril('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                        ])
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
}