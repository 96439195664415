import mithril from "mithril"
import Navbar from "../../../component/navbar"
import LeftMenu from "../../../component/sidebar_yoyic"
import Footer from "../../../component/footer"
import qs from 'querystring';

let page = 1;
let limit = 10;
let datas = null;
let paging = [];

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{
                
                const created_time = new Date(data.created_at);

                return mithril("tr", {}, [
                    mithril('td', created_time.toLocaleString()),
                    mithril('td', data.kode),
                    mithril('td', data.name),
                    mithril('td', data.city),
                    mithril('td', data.va),
                    mithril('td', data.email),
                    mithril("td", [
                        mithril("button", {"class":"button is-small is-success",
                            onclick:()=>{

                                const r = confirm(`Apakah VA ${data.va} telah berhasil di generate?`);
                                if (r == true) {
                                    
                                    mithril.request({
                                        method: "PUT",
                                        url: `/api/distribution_centers/outlets/va/pending`,
                                        body:{
                                            outlet_id:data._id
                                        },
                                        withCredentials: true
                                    }).then((res_va)=>{

                                        if(res_va.code == 200){

                                            alert(`VA ${data.va} berhasil di generate`);
                                            _redraw_data(()=>{
                                                
                                                mithril.redraw();
                                            });
                                        }else{

                                            alert(`API Response ${res_va.code}`);
                                        }
                                    }).catch((err)=>{

                                        alert(`Error Response ${err.message}`);
                                    });
                                }
                                // product_attributes_model.inactivateAttribute(data._id).then(()=>{

                                //     alert('Attribute Inactivated');
                                //     _redraw_data(()=>{

                                //         mithril.redraw();
                                //     });
                                // }).catch((err)=>{

                                //     alert(err.message);
                                // });
                            }
                        }, [
                          mithril("span", {"class":"icon has-text-black"}, 
                            mithril("i", {"class":"fa fa-check"})
                          ),
                          mithril("span",{class:"has-text-black"}, 
                            "Set Generated"
                          )
                        ])
                    ])
                ])
            })
        }else{

            return mithril("tr", {}, [
                mithril('td', {align:"center",colspan:8},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return mithril("tr", {}, [
            mithril('td', {align:"center",colspan:8},"Loading New Data ...")
        ]);
    }
}

const loadData = (page,limit)=>{

    return new Promise((resolve, reject) => {
        
        let query_param = {
            limit:limit,
            page:page
        };

        mithril.request({
            method: "GET",
            url: `/api/distribution_centers/outlets/va/pending?${qs.encode(query_param)}`,
            withCredentials: true
        }).then((res_va)=>{

            if(res_va.code == 200){

                resolve([res_va.data,res_va.pagination]);
            }else if(res_va.code == 404){

                resolve([[],[]]);
            }else{

                reject(new Error(`API Response ${res_va.code}`));
            }
        }).catch((err)=>{

            reject(err);
        });
    });
}

const _redraw_data = (cb)=>{
    
    loadData(page,limit).then(([va_datas,paging_data])=>{
    
        datas = va_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

const _create_query_param = ()=>{

    const query_param = {};

    return qs.encode(query_param);
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return mithril("li", [
                mithril("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    mithril.redraw();
                    _redraw_data(()=>{
                        
                        mithril.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return mithril("li", [
                mithril("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

module.exports = {
    oninit:()=>{
        
        window.scrollTo(0,0);
        loadData(page,limit).then(([va_datas,paging_data])=>{
            
            datas = va_datas;
            paging = paging_data;
        }).catch(()=>{});
    },
    view:()=>{
        return mithril("div", [
                mithril("section", {class:"section"}, [
                    mithril(Navbar)
                ]),
                mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                    mithril("div", {class:"columns"}, [
                        mithril("div", {class:"column is-one-fifth"}, [
                            mithril(LeftMenu, {title: "gen_va"})
                        ]),
                        mithril("div", {class:"column"}, [
                            mithril("h1", {class:"title"}, "Pending VA"),
                            mithril("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                            mithril("thead", [
                                mithril("tr", [
                                    mithril("th", "Tanggal"),
                                    mithril("th", "Kode"),
                                    mithril("th", "Outlet"),
                                    mithril("th", "City"),
                                    mithril("th", "VA"),
                                    mithril("th", "Email"),
                                    mithril("th", "")
                                ])
                            ]),
                            mithril("tbody", [
                                handle_data()
                            ])
                        ]),
                        mithril('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                            mithril('a', {class:'pagination-previous', onclick: function(){
                                if(page > 1){

                                    page--;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                            }}, 'Previous'),
                            mithril('a', {class:'pagination-next', onclick: function(){
                                if(page < paging.total_page){

                                    page++;
                                    datas = null;
                                    mithril.redraw();
                                    _redraw_data(()=>{
                                        
                                        mithril.redraw();
                                    });
                                }
                                
                            }}, 'Next page'),
                            mithril('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                        ]),
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
};