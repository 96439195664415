import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"

var mDc = require("../../models/dc")

function ListTable(self, cust) {
    return cust.map(function(u) {
        return m("tr", {}, [
            m('td', ''),
            m('td', u.kode),
            m('td', u.name),
            m('td', u.city),
            m('td', u.address),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    m.route.set('/dc/add/:id', {id: u._id})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ]),
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    m.route.set('/dc/home/:id/:name', {id: u._id, name: u.name})
                }}, [
                    m("i", {class:"fas fa-eye"})
                ])
            ])
        ])
    })
}

var Dc = {
    oninit: function(vnode){
        mDc.loadList()
    },
    view: function() {
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "dc"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Distribution Center"),
                      m("div", {class:"field is-grouped"}, [
                          m("p", {class:"control"}, [
                              m("a", {class:"button is-link", href: "#!/dc/add/new"}, "Add New")
                          ])
                      ]),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Kode"),
                                m("th", "Name"),
                                m("th", "Kota"),
                                m("th", "Alamat"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            ListTable(self, mDc.list)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Dc;