import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"
import classNames from "classnames";
import Validator from "validator"
var mDc = require("../../models/outlet")

var DcAddNewOutlet = {
    oninit: function(vnode){
        /*this.data = {
            kode: '',
            name: '',
            city: '',
            address: '',
            latitude: '',
            longitude: ''
        }*/
        let self = this;
        this.error = {
            kode: undefined,
            name: undefined,
            city: undefined,
            address: undefined,
            phone: undefined,
            contact: undefined,
            latitude: undefined,
            longitude: undefined
        }

        this.tmpva = '';
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        this.pid = vnode.attrs.pid;
        console.log('vnode.attrs.pid ', vnode.attrs.pid);
        mDc.load(vnode.attrs.pid, function(){
            self.tmpva = mDc.current.va;
        })
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      //m(LeftMenu, {title: "dc"})
                      m(LeftMenuDc, {title: "outlets", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Add New Outlet"),
                    m("form", {onsubmit: function(e){
                        e.preventDefault();
                        if(!mDc.current._id)
                            mDc.current._id = '';
                        if(!mDc.current.created_at)
                            mDc.current.created_at = '';
                        if(!mDc.current.updated_at)
                            mDc.current.updated_at = '';
                        if(!mDc.current.clientid)
                            mDc.current.clientid = '';
                        if(!mDc.current.va)
                            mDc.current.va = '';
                        if(!mDc.current.email)
                            mDc.current.email = '';
                        mDc.current.dcid = self.id;
                            
                        mDc.save(); //"#!/dc/outlets/"+self.id+"/"+self.name
                        m.route.set('/dc/outlets/'+self.id+'/'+self.name);

                        return false;
                    }}, [
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kode"),
                                    m("div", {class:"control has-icons-left"+((self.error.kode) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.kode, class:"is-uppercase input"+((self.error.kode) ? ' is-danger' : ''), type:"text", placeholder:"kode",
                                        readonly: (self.pid == 'new') ? 'readonly' : '',
                                        oninput: function(e){
                                            mDc.current.kode = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.kode) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.kode) ? m("p", {class:"help is-danger"}, "This kode is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Nama"),
                                    m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.name, class:"is-uppercase input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"name",
                                        oninput: function(e){
                                            mDc.current.name = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.name) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kota"),
                                    m("div", {class:"control has-icons-left"+((self.error.city) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.city, class:"is-uppercase input"+((self.error.city) ? ' is-danger' : ''), type:"text", placeholder:"kota",
                                        oninput: function(e){
                                            mDc.current.city = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.city) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.city) ? m("p", {class:"help is-danger"}, "This city is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Alamat"),
                                    m("div", {class:"control has-icons-left"+((self.error.address) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.address, class:"is-uppercase input"+((self.error.address) ? ' is-danger' : ''), type:"text", placeholder:"alamat",
                                        oninput: function(e){
                                            mDc.current.address = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.address) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.address) ? m("p", {class:"help is-danger"}, "This address is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Phone"),
                                    m("div", {class:"control has-icons-left"+((self.error.phone) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.phone, class:"is-uppercase input"+((self.error.phone) ? ' is-danger' : ''), type:"text", placeholder:"phone",
                                        oninput: function(e){
                                            mDc.current.phone = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.phone) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.phone) ? m("p", {class:"help is-danger"}, "This phone is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Contact"),
                                    m("div", {class:"control has-icons-left"+((self.error.contact) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.contact, class:"is-uppercase input"+((self.error.contact) ? ' is-danger' : ''), type:"text", placeholder:"contact",
                                        oninput: function(e){
                                            mDc.current.contact = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.contact) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.contact) ? m("p", {class:"help is-danger"}, "This contact is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Latitude"),
                                    m("div", {class:"control has-icons-left"+((self.error.latitude) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.latitude, class:"is-uppercase input"+((self.error.latitude) ? ' is-danger' : ''), type:"text", placeholder:"latitude",
                                        oninput: function(e){
                                            mDc.current.latitude = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.latitude) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.latitude) ? m("p", {class:"help is-danger"}, "This latitude is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Longitude"),
                                    m("div", {class:"control has-icons-left"+((self.error.longitude) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.longitude, class:"is-uppercase input"+((self.error.longitude) ? ' is-danger' : ''), type:"text", placeholder:"longitude",
                                        oninput: function(e){
                                            mDc.current.longitude = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.longitude) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.longitude) ? m("p", {class:"help is-danger"}, "This longitude is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class: "columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class:"columns"}, [
                                    m("div", {class:"column"}, [
                                        m("div", {class: "field"}, [
                                            m("label", {class:"label"}, "Virtual Account"),
                                            m("div", {class:"control has-icons-left"+((self.error.va) ? ' has-icons-right' : '')}, [
                                                m("input", {disabled: (mDc.current.va.length > 0) ? 'disabled' : '', value: self.tmpva, class:"is-uppercase input"+((self.error.va) ? ' is-danger' : ''), type:"text", placeholder:"virtual account",
                                                oninput: function(e){
                                                    self.tmpva = e.target.value;
                                                }}),
                                                m("span", {class: "icon is-small is-left"}, [
                                                    m("i", {class:"fas fa-user"})
                                                ]),
                                                ((self.error.va) ?
                                                          m("span", {class:"icon is-small is-right"}, [
                                                              m("i", {class:"fas fa-exclamation-triangle"})
                                                          ]) : "")
                                            ]),
                                            ((self.error.va) ? m("p", {class:"help is-danger"}, "This virtual account is invalid") : ""),
                                        ])
                                    ]),
                                    m("div", {class:"column is-one-fifth"}, [
                                        m('button', {disabled: (mDc.current.va.length > 0) ? 'disabled' : '', onclick: function(){
                                            self.error.va = !Validator.isLength(self.tmpva, {min:11, max: 40});
                                            if(self.tmpva.substring(0, 4) != '7841')
                                            {
                                                self.error.va = true;
                                            }
                                            if(!self.error.va )
                                            {
                                                mDc.current.va = self.tmpva;
                                                m.request({
                                                    method: "POST",
                                                    url: "/api/distributor/outlets/va/update",
                                                    body: {
                                                        va: self.tmpva,
                                                        id: self.pid
                                                    }
                                                })
                                                .then(function(data) {
                                                    if(data.result)
                                                    {
                                                        //location.href = '#!/outlets';
                                                    }
                                                    else
                                                    {
                                                        alert('insert outlet gagal');
                                                    }
                                                });
                                            }
                                                    
                                        },style:'margin-top: 30px; ', class:classNames("button is-link", {'is-hidden': (self.pid == 'new') ? true : false}), type:'button'}, 'Add VA'),
                                        m('button', {disabled: (mDc.current.va.length > 0) ? 'disabled' : '', onclick: function(e){
                                            mDc.current.va = '';
                                            m.request({
                                                method: "GET",
                                                url: "/api/distributor/outlets_v1/generate/va",
                                                params: {
                                                    dcid:self.id
                                                }
                                            })
                                            .then(function(data) {
                                                if(data.result){
                                                    self.tmpva = data.data.va;
                                                    mDc.current.va = data.data.va;
                                                    mDc.current.kode = data.data.outlet_code;
                                                    mDc.current.email = data.data.email;
                                                }else{
                                                    alert('generate va gagal');
                                                }
                                            });
                                                    
                                        },style:'margin-top: 30px; ', class:classNames("button is-link", {'is-hidden': (self.pid != 'new') ? true : false}), type:'button'}, 'Generate')
                                    ])
                                ])
                                
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "email"),
                                    m("div", {class:"control has-icons-left"+((self.error.email) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.email, class:"input"+((self.error.email) ? ' is-danger' : ''), type:"text", placeholder:"email",
                                        oninput: function(e){
                                            mDc.current.email = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-envelope"})
                                        ]),
                                        ((self.error.email) ?
                                                  m("span", {class:"icon is-small is-right"}, [
                                                      m("i", {class:"fas fa-exclamation-triangle"})
                                                  ]) : "")
                                    ]),
                                    ((self.error.email) ? m("p", {class:"help is-danger"}, "This email is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m('button', {class:'button is-link', type:'submit'}, 'Simpan')
                            ]),
                        ])
                        
                    ]),
                    
                ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcAddNewOutlet;