import mithril from 'mithril';
import qs from 'querystring';

module.exports = {
    loadProductDatas:(page,limit,status)=>{

        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page
            };

            if(status !== undefined && status != 0){

                query_param.status = status;
            }

            mithril.request({
                method: "GET",
                url: `/api/dc_products?${qs.encode(query_param)}`,
                withCredentials: true
            }).then((res_products)=>{

                if(res_products.code == 200){

                    resolve([res_products.data,res_products.pagination]);
                }else if(res_products.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_products.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadProductData:(product_id)=>{

        return new Promise((resolve, reject) => {

            mithril.request({
                method: "GET",
                url: `/api/dc_products/${product_id}`,
                withCredentials: true
            }).then((res_products)=>{

                if(res_products.code == 200){

                    resolve(res_products.data);
                }else{

                    reject(new Error(`API Response ${res_products.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    addProduct:(product_data)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "POST",
                url: `/api/dc_products`,
                withCredentials: true,
                body:product_data
            }).then((res_products)=>{

                if(res_products.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_products.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    updateProduct:(product_id,update_data)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "PUT",
                url: `/api/dc_products/${product_id}`,
                withCredentials: true,
                body:update_data
            }).then((res_products)=>{

                if(res_products.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_products.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadProductStockHistory:(product_id,page,limit,type)=>{
        
        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page
            };

            if(type !== undefined){

                query_param.type = type;
            }

            mithril.request({
                method: "GET",
                url: `/api/dc_products/${product_id}/stocks?${qs.encode(query_param)}`,
                withCredentials: true
            }).then((res_stocks)=>{

                if(res_stocks.code == 200){

                    resolve([res_stocks.data,res_stocks.pagination]);
                }else if(res_stocks.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_stocks.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    updateProductStockHistory:(product_id,update_data)=>{

        return new Promise((resolve, reject) => {
            
            mithril.request({
                method: "POST",
                url: `/api/dc_products/${product_id}/stocks`,
                withCredentials: true,
                body:update_data
            }).then((res_stocks)=>{

                if(res_stocks.code == 200){

                    resolve();
                }else{

                    reject(new Error(`API Response ${res_stocks.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    }
};