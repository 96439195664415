import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"
import Global from "../../global"

var mDc = require("../../models/dc")

let glob = Global.getInstance();

const _displaySummaryTransaction = ()=>{
    
    if(mDc.current.sales_count !== undefined){

        return mDc.current.sales_count.map((sales_data)=>{

            return [m('tr', [
                m('th', `Total ${sales_data.type} (IDR)`),
                m('th', ':'),
                m('td', glob.number_format(sales_data.total_harga,0))
            ]),
            m('tr', [
                m('th', `Total ${sales_data.type} (Unit)`),
                m('th', ':'),
                m('td', glob.number_format(sales_data.total_unit,0))
            ])];
        })
    }else{

        return [m('tr', [
            m('th', `Total`),
            m('th', ':'),
            m('td', '-')
        ])];
    }
}

var DcHome = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id
        this.name = vnode.attrs.name

        mDc.load(vnode.attrs.id)
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "home", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, self.title),
                      m("hr"),
                      m("div", {class:"columns"}, [
                        m("div", {class:"column"}, [
                            m('table', {class: 'table'}, [
                                m('tr', [
                                    m('th', 'Kode'),
                                    m('th', ':'),
                                    m('td', mDc.current.kode)
                                ]),
                                m('tr', [
                                    m('th', 'Name'),
                                    m('th', ':'),
                                    m('td', mDc.current.name)
                                ]),
                                m('tr', [
                                    m('th', 'Address'),
                                    m('th', ':'),
                                    m('td', mDc.current.address)
                                ]),
                                m('tr', [
                                    m('th', 'Kota'),
                                    m('th', ':'),
                                    m('td', mDc.current.city)
                                ]),
                                m('tr', [
                                    m('th', 'Phone'),
                                    m('th', ':'),
                                    m('td', mDc.current.phone)
                                ]),
                                m('tr', [
                                    m('th', 'Contact'),
                                    m('th', ':'),
                                    m('td', mDc.current.contact)
                                ])
                            ])
                        ]),
                        m("div", {class:"column"}, [
                            m('table', {class: 'table'}, [
                                _displaySummaryTransaction()
                            ]),
                            m('table', {class: 'table'}, [
                                m('tr', [
                                    m('th', 'Total Outlets'),
                                    m('th', ':'),
                                    m('td', glob.number_format(mDc.current.outlet_count,0))
                                ]),
                                m('tr', [
                                    m('th', 'Total Salesman'),
                                    m('th', ':'),
                                    m('td', glob.number_format(mDc.current.salesman_count,0))
                                ])
                            ])
                        ])
                      ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcHome;