import m from "mithril"

var LeftMenuDc = {
    oninit: function(vnode){
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
    },
    onClick: function(e){
        var elem = document.querySelector('#leftmenu');
        var allElems = elem.querySelectorAll('.itm');

        for(var i=0;i<allElems.length;i++)
        {
            allElems[i].classList.remove('is-active');
        }

        e.target.classList.add("is-active");
    },
    view: function(vnode) {
        let self = this;
        this.dta = m("aside", {id:"leftmenu", class: "menu is-hidden-touch"}, [
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm", onclick: function(e){
                        m.route.set('/dc');
                    }}, "< Back")
                ])
            ]),
            m("p", {class: "menu-label"}, "Menu"),
            m("ul", {class: "menu-list"}, [
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "home") ? ' is-active' : ''), href: encodeURI("#!/dc/home/"+self.id+"/"+self.name), onclick: this.onClick.bind(this)}, "Home")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "product") ? ' is-active' : ''), href: encodeURI("#!/dc/product/"+self.id+"/"+self.name), onclick: this.onClick.bind(this)}, "Product")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "outlets") ? ' is-active' : ''), href: encodeURI("#!/dc/outlets/"+self.id+"/"+self.name), onclick: this.onClick.bind(this)}, "Outlets")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "salesman") ? ' is-active' : ''), href: encodeURI("#!/dc/salesman/"+self.id+"/"+self.name), onclick: this.onClick.bind(this)}, "Salesman")
                ])
            ]),
            m("p", {class: "menu-label"}, "Administrasi"),
            m("ul", {class: "menu-list"}, [
                /*m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "pembelian") ? ' is-active' : ''), href: "#!/dc/buy/"+self.id+"/"+self.name, onclick: this.onClick.bind(this)}, "Pembelian")
                ]),
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "penjualan") ? ' is-active' : ''), href: "#!/dc/sell/"+self.id+"/"+self.name, onclick: this.onClick.bind(this)}, "Penjualan")
                ]),*/
                m("li", [
                    m("a", {class:"itm"+((vnode.attrs.title == "po") ? ' is-active' : ''), href: "#!/dc/po/"+self.id+"/"+self.name+"/10/1", onclick: this.onClick.bind(this)}, "Purchase Order")
                ])
            ])
        ])
        return this.dta;
    }
}
module.exports = LeftMenuDc;
