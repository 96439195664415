import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"

var mDc = require("../../models/dcproduct")

var DcAddNewProduct = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.productid = vnode.attrs.pid;
        this.name = vnode.attrs.name;

        this.error = {
            name: false,
            price: false,
            unit: false,
            kode: false
        }
        mDc.load(vnode.attrs.pid, vnode.attrs.id, function(){
            //self.tmpva = mDc.current.va;
        })
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "product", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                        m("h1", {class:"title"}, " Add Product"),
                        m("form", {onsubmit: function(e){
                        e.preventDefault();
                        if(!mDc.current._id)
                            mDc.current._id = '';
                        
                        if(!mDc.current.clientid)
                            mDc.current.clientid = '';
                        mDc.current.dcid = self.id;
                        
                        mDc.save(); //"#!/dc/outlets/"+self.id+"/"+self.name
                        m.route.set('/dc/product/'+self.id+'/'+self.name);

                        return false;
                    }}, [
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Kode"),
                                    m("div", {class:"control has-icons-left"+((self.error.kode) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.kode, class:"input"+((self.error.kode) ? ' is-danger' : ''), type:"text", placeholder:"kode",
                                        oninput: function(e){
                                            mDc.current.kode = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.kode) ?
                                                    m("span", {class:"icon is-small is-right"}, [
                                                        m("i", {class:"fas fa-exclamation-triangle"})
                                                    ]) : "")
                                    ]),
                                    ((self.error.kode) ? m("p", {class:"help is-danger"}, "This kode is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Nama"),
                                    m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.name, class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"name",
                                        oninput: function(e){
                                            mDc.current.name = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.name) ?
                                                    m("span", {class:"icon is-small is-right"}, [
                                                        m("i", {class:"fas fa-exclamation-triangle"})
                                                    ]) : "")
                                    ]),
                                    ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Unit"),
                                    m("div", {class:"control has-icons-left"+((self.error.unit) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.unit, class:"input"+((self.error.unit) ? ' is-danger' : ''), type:"text", placeholder:"unit",
                                        oninput: function(e){
                                            mDc.current.unit = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.unit) ?
                                                    m("span", {class:"icon is-small is-right"}, [
                                                        m("i", {class:"fas fa-exclamation-triangle"})
                                                    ]) : "")
                                    ]),
                                    ((self.error.unit) ? m("p", {class:"help is-danger"}, "This unit is invalid") : "")
                                ]),
                            ]),
                            m("div", {class:"column"}, [
                                m("div", {class: "field"}, [
                                    m("label", {class:"label"}, "Price"),
                                    m("div", {class:"control has-icons-left"+((self.error.price) ? ' has-icons-right' : '')}, [
                                        m("input", {value: mDc.current.price, class:"input"+((self.error.price) ? ' is-danger' : ''), type:"text", placeholder:"price",
                                        oninput: function(e){
                                            mDc.current.price = e.target.value;
                                        }}),
                                        m("span", {class: "icon is-small is-left"}, [
                                            m("i", {class:"fas fa-user"})
                                        ]),
                                        ((self.error.price) ?
                                                    m("span", {class:"icon is-small is-right"}, [
                                                        m("i", {class:"fas fa-exclamation-triangle"})
                                                    ]) : "")
                                    ]),
                                    ((self.error.price) ? m("p", {class:"help is-danger"}, "This price is invalid") : "")
                                ]),
                            ])
                        ]),
                        m("div", {class:"columns"}, [
                            m("div", {class:"column"}, [
                                m('button', {class:'button is-link', type:'submit'}, 'Simpan')
                            ]),
                        ])
                        
                        ]),
                      
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcAddNewProduct;