import m from "mithril"

var Table = {
    view: function(vnode) {
        return m("table", {class: "table"}, [
            m("thead", [
                m("tr", [
                    m("th", "satu"),
                    m("th", "dua"),
                    m("th", "tiga")
                ])
            ]),
            m("tfoot"),
            m("tbody", [
                m("tr", [
                    m("td", "satu"),
                    m("td", "dua"),
                    m("td", "tiga")
                ])
            ])
        ])
    }
}

module.exports = Table;
