import mithril from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/sidebar_warehouse"
import Footer from "../../component/footer"
import Joi from "@hapi/joi"

import dc_products_model from "../../models/dc_products"

let inserted_product_data = {
    name:null,
    kode:null,
    unit:null,
    price:null,
    stock:null,
    status:null
};
let data = [];

module.exports = {
    oninit:()=>{

        window.scrollTo(0,0);
    },
    view:()=>{
        return mithril("div", [
            mithril("section", {class:"section"}, [
                mithril(Navbar)
            ]),
            mithril("section", {class:"section", style:"margin-top: -120px; "}, [
                mithril("div", {class:"columns"}, [
                    mithril("div", {class:"column is-one-fifth"}, [
                        mithril(LeftMenu, {title: "add_dc_products"})
                    ]),
                    mithril("div", {class:"column"}, [
                        mithril("h1", {class:"title"}, "Add DC Master Product"),
                        mithril("button", {"class":"button is-info is-outlined is-pulled-right", onclick: function(){
                            mithril.route.set('/dc/products');
                        }},[
                            mithril("span", {"class":"icon is-small"}, 
                                mithril("i", {"class":"fas fa-angle-left"})
                            ),
                            mithril("span", "Back to List")
                        ]),
                        mithril("div",{class:"is-clearfix"}),
                        mithril("form",[
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Kode Product"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"text", 
                                        placeholder:"Kode Product",
                                        oninput: function(e){
                                            inserted_product_data.kode = e.target.value;
                                        }, 
                                        value: inserted_product_data.kode
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Name"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"text", 
                                        placeholder:"Name",
                                        oninput: function(e){
                                            inserted_product_data.name = e.target.value;
                                        }, 
                                        value: inserted_product_data.name
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Unit Name"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"text", 
                                        placeholder:"Unit Name",
                                        oninput: function(e){
                                            inserted_product_data.unit = e.target.value;
                                        }, 
                                        value: inserted_product_data.unit
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Price"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"number",
                                        min:0, 
                                        placeholder:"Price",
                                        oninput: function(e){
                                            inserted_product_data.price = e.target.value;
                                        }, 
                                        value: inserted_product_data.price
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Stock"),
                                mithril("div", {class:"control"}, [
                                    mithril("input", {
                                        class:"input", 
                                        type:"number",
                                        min:0, 
                                        placeholder:"Stock",
                                        oninput: function(e){
                                            inserted_product_data.stock = e.target.value;
                                        }, 
                                        value: inserted_product_data.stock
                                    })
                                ])
                            ]),
                            mithril("div", {class: "field"}, [
                                mithril("label", {class:"label"}, "Status"),
                                mithril("div", {class:"control"}, [
                                    mithril('div', {class:'select'}, [
                                        mithril('select', {onchange: function(e){
                                        
                                            inserted_product_data.status = e.target.value;
                                        }}, [
                                            mithril('option', {selected: 'true', disabled: 'disabled'}, '- Choose Status -'),
                                            mithril('option', {value: 'active',selected: (inserted_product_data.status == 'active') ? 'true' : ''}, 'Active'),
                                            mithril('option', {value: 'inactive',selected: (inserted_product_data.status == 'inactive') ? 'true' : ''}, 'Inactive'),
                                        ])
                                    ]),
                                ])
                            ]),
                            //
                            mithril("div", {class:"field"}, [
                                mithril("div", {class:"control"}, [
                                    mithril("button", {type:"button",class:"button is-link is-primary is-pulled-right",
                                        onclick:function(){

                                            const rule = {
                                                kode: Joi.string().required(),
                                                name: Joi.string().required(),
                                                unit: Joi.string().required(),
                                                price: Joi.number().greater(0).required(),
                                                stock: Joi.number().greater(0).required(),
                                                status: Joi.string().valid('active','inactive')
                                            };
                                            
                                            const validation = Joi.object(rule).validate(inserted_product_data);
                                            
                                            if (validation.error === undefined) {

                                                dc_products_model.addProduct(inserted_product_data).then(()=>{

                                                    alert('Product Created Successfully');
                                                    mithril.route.set("/dc/products");
                                                }).catch((err)=>{

                                                    alert(err.message);
                                                });
                                            }else{
                                                
                                                alert('Invalid Product Data, Check Again Your Input');
                                            }
                                        }
                                    }, "Create Product")
                                ])
                            ])
                            //
                        ])
                    ])
                ])
            ]),
            mithril("section", {class:"section"}, [
                mithril(Footer)
            ])
        ]);
    }
}