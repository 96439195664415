import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"

var customers = [];

function customerInputs(cust) {
    return cust.map(function(u) {
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<customers.length;i++)
                {
                    customers[i].selected = false;
                }
                for(var i=0;i<customers.length;i++)
                {
                    if(customers[i]._id == this.getAttribute("strid"))
                        customers[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [
                m("label", {class:"checkbox"}, [
                    m("input", {type: "checkbox"})
                ])
            ]),
            m("td", [u.no]),
            m("td", [u.name]),
            m("td", [u.email]),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    console.log(this.getAttribute("strid"));
                    m.route.set('/customers/edit/:id', {id: this.getAttribute("strid")})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

var Customers = {
    oninit: function(vnode) {
        m.request({
            method: "POST",
            url: "/api/customers/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                customers = data.data;
                for(var i=0;i<customers.length;i++)
                {
                    customers[i].selected = false;
                }
            }
            else
            {
                alert('insert customer gagal');
            }
        });
    },
    view: function() {
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "customers"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Customers"),
                      m("div", {class:"field is-grouped"}, [
                          m("p", {class:"control"}, [
                              m("a", {class:"button is-link", href: "#!/customers/add"}, "Add New")
                          ])
                      ]),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "No"),
                                m("th", "Name"),
                                m("th", "email"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            customerInputs(customers)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Customers;