var m = require("mithril")


var datakosong = {
    kode: '',
    name: '',
    city: '',
    address: '',
    phone: '',
    contact: '',
    latitude: '',
    longitude: '',
    va: '',
    email: ''
}
var mDcOutlet = {
    list: [],
    loadDatas:(page,limit,dcid,search=undefined)=>{

        return new Promise((resolve, reject) => {
            
            let query_param = {
                limit:limit,
                page:page,
                dcid:dcid
            };

            if(search !== undefined){

                if(search.length > 2){

                    query_param.search = search;
                }
            }

            m.request({
                method: "GET",
                url: "/api/distributor/outlets_v1",
                withCredentials: true,
                params:query_param
            }).then((res_products)=>{

                if(res_products.code == 200){

                    resolve([res_products.data,res_products.pagination]);
                }else if(res_products.code == 404){

                    resolve([[],[]]);
                }else{

                    reject(new Error(`API Response ${res_products.code}`));
                }
            }).catch((err)=>{

                reject(err);
            });
        });
    },
    loadList: function(dcid) {
        return m.request({
            method: "GET",
            // url: "/api/distributor/outlets/getall",
            url: "/api/distributor/outlets_v1",
            withCredentials: true,
            params: {
                limit: 10,
                page: 1,
                dcid: dcid
            }
        })
        .then(function(result) {
            if(result.result)
            {
                mDcOutlet.list = result.data
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
                
        })
    },
    current: JSON.parse(JSON.stringify(datakosong)),
    load: function(id, cb) {
        if(id == 'new')
        {
            mDcOutlet.current = JSON.parse(JSON.stringify(datakosong));
            cb();
        }
        else
        {
            return m.request({
                method: "POST",
                url: "/api/distributor/outlets/get", 
                withCredentials: true,
                body: {
                    id: id
                }
            })
            .then(function(result) {
                if(result.result)
                {
                    mDcOutlet.current = result.data;
                    //console.log(mDcOutlet.current);
                }
                else{
                    if(result.error[0].message == "You're not login")
                    {
                        m.route.set('/login');
                    }
                }
                cb();
            })
        }
        
    },

    save: function() {
        return m.request({
            method: "POST",
            url: "/api/distributor/outlets/insert",
            body: mDcOutlet.current,
            withCredentials: true,
        })
        .then(function(result) {
            if(result.result)
            {
                //
            }
            else{
                if(result.error[0].message == "You're not login")
                {
                    m.route.set('/login');
                }
            }
        })
    }
}

module.exports = mDcOutlet