import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Notification from "../../component/notification"

function categoryInputs(selected, cust) {
    return cust.map(function(u) {
        //return m('option', {value: u._id}, u.name)
        return m('option', {value: u._id, selected: (selected == u._id) ? 'true' : ''}, u.name)
    })
}

function loadSetting(self){
    m.request({
        method: "POST",
        url: "/api/pos/v1/setting/get",
        body: { store_id: self.data.store_id },
        withCredentials: true,
    })
    .then(function(data) {
        if(data.result)
        {
            if(data.data.length>0)
            {
                if(data.data[0].store_id)
                    self.data.store_id = data.data[0].store_id;
                self.data.header1 = data.data[0].header1;
                self.data.header2 = data.data[0].header2;
                self.data.header3 = data.data[0].header3;
                self.data.footer1 = data.data[0].footer1;
                self.data.footer2 = data.data[0].footer2;
                self.data.footer3 = data.data[0].footer3;

                self.data.creditcard = data.data[0].creditcard;
                self.data.debit = data.data[0].debit;
                self.data.check = data.data[0].check;
                self.data.voucher = data.data[0].voucher;
                self.data.online = data.data[0].online;
                self.data.receipt = data.data[0].receipt;
                self.data.order = data.data[0].order;
            }
            else
            {
                self.data.header1 = '';
                self.data.header2 = '';
                self.data.header3 = '';
                self.data.footer1 = '';
                self.data.footer2 = '';
                self.data.footer3 = '';

                self.data.creditcard = false;
                self.data.debit = false;
                self.data.check = false;
                self.data.voucher = false;
                self.data.online = false;
                self.data.receipt = false;
                self.data.order = false;
            }
            
        }
        else
        {
            alert('get setting gagal');
        }
    });
}

var Settings = {
    oninit: function(vnode) {
        let self = this;

        this.data = {
            store_id: "",
            header1: "",
            header2: "",
            header3: "",
            footer1: "",
            footer2: "",
            footer3: "",
            creditcard: false,
            debit: false,
            check: false,
            voucher: false,
            online: false,
            receipt: false,
            order: false,
        };
        this.error = {
            store_id: undefined,
            header1: undefined,
            header2: undefined,
            header3: undefined,
            footer1: undefined,
            footer2: undefined,
            footer3: undefined,
            creditcard: false,
            debit: false,
            check: false,
            voucher: false,
            online: false,
            receipt: false,
            order: false,
        };

        self.categories = [];
        m.request({
                method: "POST",
                url: "/api/outlets/getall",
                body: { limit: 10, page: 1 },
                withCredentials: true,
            })
            .then(function(data) {
                if(data.result)
                {
                    console.log(data.data);
                    self.categories = [];
                    self.data.store_id = data.data[0]._id;
                    for(let i=0;i<data.data.length;i++)
                    {
                        self.categories.push(data.data[i]);
                    }
                    //self.categories = data.data;
                    
                    loadSetting(self);
                }
                else
                {
                    alert('/api/outlets/getall gagal');
                }
            });
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "settings"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Settings"),
                      m("h1", {class:"subtitle"}, "Receipt"),
                      m('form', {
                        onsubmit: function(e) {
                        e.preventDefault()
                        //User.save()
                        console.log(self.data);
                        m.request({
                            method: "POST",
                            url: "/api/setting/update",
                            body: self.data,
                            withCredentials: true
                        })
                        .then(function(data) {
                            if(data.result)
                            {
                                //location.reload();
                                Notification.setBody('Success to save setting');
                                Notification.setType('is-success');
                                Notification.show(function(){
                                    Notification.close();
                                });
                            }
                            else
                            {
                                alert('update setting gagal');
                            }
                        });
                    }}, [
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Header 1"),
                            m("div", {class:"control"}, [
                                m('div', {class:'select'}, [
                                    m('select', {
                                        onchange: function(e){
                                            //console.log(e);
                                            self.data.store_id = e.target.value;
                                            //loadProduct(self.tipe, self);
                                            loadSetting(self);
                                        }}, [
                                        categoryInputs(self.data.store_id, self.categories)
                                    ])
                                ])
                            ]),
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Header 1"),
                            m("div", {class:"control "+((self.error.header1) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.header1) ? ' is-danger' : ''), type:"text", placeholder:"Header 1",
                                oninput: function(e){
                                    self.data.header1 = e.target.value;
                                }, value: self.data.header1 }),
                                ((self.error.header1) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.header1) ? m("p", {class:"help is-danger"}, "Header 1 is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Header 2"),
                            m("div", {class:"control "+((self.error.header2) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.header2) ? ' is-danger' : ''), type:"text", placeholder:"Header 2",
                                oninput: function(e){
                                    self.data.header2 = e.target.value;
                                }, value: self.data.header2}),
                                ((self.error.header2) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.header2) ? m("p", {class:"help is-danger"}, "Header 2 is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Header 3"),
                            m("div", {class:"control "+((self.error.header3) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.header3) ? ' is-danger' : ''), type:"text", placeholder:"Header 3",
                                oninput: function(e){
                                    self.data.header3 = e.target.value;
                                }, value: self.data.header3}),
                                ((self.error.header3) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.header3) ? m("p", {class:"help is-danger"}, "Header 2 is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Footer 1"),
                            m("div", {class:"control "+((self.error.footer1) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.footer1) ? ' is-danger' : ''), type:"text", placeholder:"Footer 1",
                                oninput: function(e){
                                    self.data.footer1 = e.target.value;
                                }, value: self.data.footer1}),
                                ((self.error.footer1) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.footer1) ? m("p", {class:"help is-danger"}, "Footer 1 is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Footer 2"),
                            m("div", {class:"control "+((self.error.footer2) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.footer2) ? ' is-danger' : ''), type:"text", placeholder:"Footer 2",
                                oninput: function(e){
                                    self.data.footer2 = e.target.value;
                                }, value: self.data.footer2}),
                                ((self.error.footer2) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.footer2) ? m("p", {class:"help is-danger"}, "Footer 2 is invalid") : "")
                        ]),
                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Footer 3"),
                            m("div", {class:"control "+((self.error.footer3) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.footer3) ? ' is-danger' : ''), type:"text", placeholder:"Footer 3",
                                oninput: function(e){
                                    self.data.footer3 = e.target.value;
                                }, value: self.data.footer3}),
                                ((self.error.footer3) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.footer3) ? m("p", {class:"help is-danger"}, "Footer 3 is invalid") : "")
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.creditcard = e.target.checked;
                                    }, checked: (self.data.creditcard) ? 'checked' : ''}),
                                    " Credit Card"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.debit = e.target.checked;
                                    }, checked: (self.data.debit) ? 'checked' : ''}),
                                    " Debit Card"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.check = e.target.checked;
                                    }, checked: (self.data.check) ? 'checked' : ''}),
                                    " Cek"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.voucher = e.target.checked;
                                    }, checked: (self.data.voucher) ? 'checked' : ''}),
                                    " Voucher"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.online = e.target.checked;
                                    }, checked: (self.data.online) ? 'checked' : ''}),
                                    " Online"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.receipt = e.target.checked;
                                    }, checked: (self.data.receipt) ? 'checked' : ''}),
                                    " Cetak Receipt"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.order = e.target.checked;
                                    }, checked: (self.data.order) ? 'checked' : ''}),
                                    " Cetak Order"
                                ])
                            ])
                        ]),
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m("button", {class:"button is-link"}, "Submit")
                            ]),
                        ])
                      ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Settings;