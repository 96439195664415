import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"

var mDcSalesman = require("../../models/salesman")

function ListTable(self, cust) {
    return cust.map(function(u) {
        return m("tr", {}, [
            m('td', ''),
            m('td', u.kode),
            m('td', u.name),
            m('td', u.city),
            m('td', u.address),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    m.route.set('/dc/salesman/edit/:pid/:id/:name', {pid: u._id, id: u.dcid, name: self.name})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

var DcSalesman = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        mDcSalesman.loadList(this.id);
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "salesman", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, self.title+ " Salesman"),
                      m("div", {class:"field is-grouped"}, [
                          m("p", {class:"control"}, [
                              m("a", {class:"button is-link", href: "#!/dc/salesman/edit/new/"+self.id+"/"+self.name}, "Add New")
                          ])
                      ]),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Kode"),
                                m("th", "Name"),
                                m("th", "Kota"),
                                m("th", "Alamat"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            ListTable(self, mDcSalesman.list)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcSalesman;