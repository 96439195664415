import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import moment from "moment"
import "moment/locale/id"
import Global from "../../global"

function trxdetiProducts(cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        return m("li", {class: 'list-item', style: 'white-space: nowrap; '}, [ //formatNumber(u.price)
            u.productSourceName + ' [ ' + u.SourceQty + u.satuanSource + ' ] => ' + u.productTargetName + ' [ ' + u.TargetQty + u.satuanTarget + ' ]'
        ])
    })
}

function trxdetilInputs(self, cust) {
    let glob = Global.getInstance();
    return cust.map(function(u) {
        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<trxdetils.length;i++)
                {
                    trxdetils[i].selected = false;
                }
                for(var i=0;i<trxdetils.length;i++)
                {
                    if(trxdetils[i]._id == this.getAttribute("strid"))
                        trxdetils[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [
                m("label", {class:"checkbox"}, [
                    m("input", {type: "checkbox"})
                ])
            ]),
            m("td", [u.no]),
            m("td", [u.rkode]),
            m("td", [moment(u.enddate).format('dddd, Do MMMM YYYY, h:mm:ss a')]),
            m("td", [
                m('ul', {class:"list"}, trxdetiProducts(u.trxdetail))
            ])
        ])
    })
}

var RoasteryDetail = {
    oninit: function(vnode) {
        let self = this;
        this.trxdetils = [];
        this.sessionid = vnode.attrs.id;
        m.request({
            method: "POST",
            url: "/api/postrx/roastery/getall/trx",
            body: { sessionid: this.sessionid },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.trxdetils = data.data;
                for(var i=0;i<trxdetils.length;i++)
                {
                    self.trxdetils[i].selected = false;
                  //categories[i].start = moment(categories[i].start).format('DD-MM-YYYY HH:mm:ss');
                }
            }
            else
            {
                alert('insert categories gagal');
            }
        });
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "Roastery Transactions"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Roastery Detail Process"),
                    m('div', {style:'overflow-x: auto;'}, [
                        m("table", {class: "table is-fullwidth is-narrow is-hoverable", style:'font-size: 13px; '}, [
                            m("thead", [
                                m("tr", [
                                    m("th", ""),
                                    m("th", "No"),
                                    m("th", "Kode"),
                                    m("th", "Tanggal"),
                                    m("th", "Products"),
                                    m("th", "")
                                ])
                            ]),
                            m("tbody", [
                                trxdetilInputs(self, self.trxdetils)
                            ])
                          ]),
                    ])
                  ])
                ])
            ])
        ])
    }
}
module.exports = RoasteryDetail;