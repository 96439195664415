import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"
import Global from "../../global"

var mDcOutlet = require("../../models/outlet")

let glob = Global.getInstance();

let page = 1;
let limit = 10;
let dcid,search;
let datas = null;
let paging = [];
let dc_data;

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{
                
                let jumpo = data.listpo.length;
                let totpo = 0;
                for(let i=0;i<data.listpo.length;i++){
                    for(let x=0;x<data.listpo[i].detail.length;x++){
                        totpo += data.listpo[i].detail[x].price*data.listpo[i].detail[x].jumlah;
                    }
                }
                let jumro = data.listro.length;
                let totro = 0;
                for(let i=0;i<data.listro.length;i++){
                    for(let x=0;x<data.listro[i].detail.length;x++){
                        totro += data.listro[i].detail[x].price*data.listro[i].detail[x].jumlah;
                    }
                }

                const img = (data.image !== undefined) ? `/upload/${data.image}` : null;
                const tplimg = (img) ? m('a',{href:img,target:'_blank'},[
                    m('i',{class:"fas fa-image"})
                ]) : m('span','');

                return m("tr", {}, [
                    m('td', ''),
                    m('td', [
                        m('span',data.kode),
                        tplimg
                    ]),
                    m('td', data.name),
                    m('td', data.city),
                    m('td', {style: 'word-wrap: break-word;'}, data.address),
                    m('td', [
                        m("span", {"class":"tag is-info"}, `Order : ${jumpo}`),
                        m('br'),
                        m("span", {"class":"tag is-warning"}, `Retur : ${jumro}`)
                    ]),
                    m('td', [
                        m("span", {"class":"tag is-info"}, `Order : ${glob.number_format(totpo, 0, ',', '.')}`),
                        m('br'),
                        m("span", {"class":"tag is-warning"}, `Retur : ${glob.number_format(totro, 0, ',', '.')}`)
                    ]),
                    m('td', glob.number_format(data.va, 0, ',', '.')),
                    m("td", [
                        m("a", {class:"icon", strid: data._id,
                        onclick: function(){
                            m.route.set('/dc/outlet/edit/:pid/:id/:name', {pid: data._id, id: data.dcid, name: dc_data.name})
                        }}, [
                            m("i", {class:"fas fa-edit"})
                        ])
                    ])
                ])
            })
        }else{

            return m("tr", {}, [
                m('td', {align:"center",colspan:9},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return m("tr", {}, [
            m('td', {align:"center",colspan:9},"Loading New Data ...")
        ]);
    }
}

const _redraw_data = (cb)=>{
    
    mDcOutlet.loadDatas(page,limit,dcid,search).then(([outlet_datas,paging_data])=>{
    
        datas = outlet_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return m("li", [
                m("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    m.redraw();
                    _redraw_data(()=>{
                        
                        m.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return m("li", [
                m("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

var DcOutlets = {
    oninit: function(vnode){
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        
        dcid = this.id;
        dc_data = {
            id: dcid,
            name: this.name
        };
        window.scrollTo(0,0);
        mDcOutlet.loadDatas(page,limit,dcid).then(([outlet_datas,paging_data])=>{
            
            datas = outlet_datas;
            paging = paging_data;
        }).catch(()=>{});
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "outlets", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, `${self.title} Outlets`),
                    m("a", {class:"button is-link is-pulled-right", href: "#!/dc/outlet/edit/new/"+self.id+"/"+self.name}, "Add New"),
                    m('button', {style:"margin-right:1rem;",class:'button is-pulled-right', onclick:function(){
                        
                        window.location.href = '/api/distributor/outlets_v1/excel/get_all?dcid='+self.id;
                    }}, 'Export to Excel'),
                    m('div', {class: 'field is-pulled-left'}, [
                        m("p", {"class":"control has-icons-left"},[
                            m("input", {"class":"input","type":"text","placeholder":"Search",oninput: function(e){
                                
                                page = 1;
                                datas = null;
                                m.redraw();
                                search = e.target.value;
                                _redraw_data(()=>{
                                    
                                    m.redraw();
                                });
                            }}),
                            m("span", {"class":"icon is-small is-left"}, 
                                m("i", {"class":"fas fa-search"})
                            )
                        ])
                    ]),
                    m("div",{class:"is-clearfix"}),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Kode"),
                                m("th", "Name"),
                                m("th", "Kota"),
                                m("th", "Alamat"),
                                m("th", "Total PO"),
                                m("th", "Invoice"),
                                m("th", "Sisa Payment"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            handle_data()
                        ])
                    ]),
                    m('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                        m('a', {class:'pagination-previous', onclick: function(){
                            if(page > 1){

                                page--;
                                datas = null;
                                m.redraw();
                                _redraw_data(()=>{
                                    
                                    m.redraw();
                                });
                            }
                        }}, 'Previous'),
                        m('a', {class:'pagination-next', onclick: function(){
                            if(page < paging.total_page){

                                page++;
                                datas = null;
                                m.redraw();
                                _redraw_data(()=>{
                                    
                                    m.redraw();
                                });
                            }
                        }}, 'Next page'),
                        m('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcOutlets;