import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/sidebar_yoyic"
import Footer from "../../component/footer"
import Global from "../../global"

const mDcPo = require("../../models/po");

let glob = Global.getInstance();

let page = 1;
let limit = 10;
let dcid,search,type,status;
let datas = null;
let paging = [];
let dc_data;

let handle_data = ()=>{
    
    if(Array.isArray(datas)){

        if(datas.length > 0){
            
            return datas.map((data)=>{

                let total = 0;
                for(let i=0;i<data.detail.length;i++){
                    total += parseInt(data.detail[i].price)*data.detail[i].jumlah;
                }

                return m("tr", {}, [
                    m('td', data.no),
                    m('td', data.tanggal),
                    m('td', data.type),
                    m('td', data.kode),
                    m('td', data.outlet),
                    m('td', glob.number_format(total, 0)),
                    m('td', data.status),
                    m("td", [
                        m("a", {class:"icon", strid: data._id,
                        onclick: function(){
                            const xhr = new XMLHttpRequest()
                            xhr.open('GET', '/api/distributor/po/get/pdf?id='+data._id, true)
                            xhr.responseType = 'arraybuffer'
        
                            xhr.onload = function(e) {
                                const blob = new Blob([xhr.response], { type: 'application/pdf' })
                                const url = window.URL.createObjectURL(blob)
                                var a = document.createElement('a');
                                a.target="_blank";
                                a.href=url;
                                a.click();
                            }
        
                            xhr.send();
                        }}, [
                            m("i", {style:'color: red;', class:"far fa-file-pdf"})
                        ])
                    ])
                ])
            })
        }else{

            return m("tr", {}, [
                m('td', {align:"center",colspan:8},"No Data")
            ]);
        }
    }else if(datas === null){
        
        return m("tr", {}, [
            m('td', {align:"center",colspan:8},"Loading New Data ...")
        ]);
    }
}

const _redraw_data = (cb)=>{
    
    mDcPo.loadDatas(page,limit,dcid,undefined,type,status,search).then(([po_datas,paging_data])=>{
    
        datas = po_datas;
        paging = paging_data;

        if(cb !== undefined){

            cb();
        }
    }).catch(()=>{});
}

let create_pagination = ()=>{

    let pages = [];

    if((page - 1) >= 3){

        pages.push(1);
        pages.push(null);
    }

    if(page > 1){

        if((page - 2) >= 1){

            pages.push(page-2);
            pages.push(page-1);
        }else{

            pages.push(page-1);
        }
    }

    pages.push(page);

    if(page < paging.total_page){

        if((page + 2) <= paging.total_page){

            pages.push(page+1);
            pages.push(page+2);
        }else{

            pages.push(page+1);
        }
    }

    if((paging.total_page - page) >= 3){

        pages.push(null);
        pages.push(paging.total_page);
    }
    
    return pages.map((page_data)=>{
        
        if(page_data){

            return m("li", [
                m("span", {onclick: ()=>{
    
                    page = page_data;
                    datas = null;
                    m.redraw();
                    _redraw_data(()=>{
                        
                        m.redraw();
                    });
                }, class:(page==page_data) ? "pagination-link is-current" : "pagination-link"},page_data)
            ])
        }else{

            return m("li", [
                m("span",{class:"pagination-ellipsis"},"...")
            ])
        }
    });
}

var DcPo = {
    oninit: function(vnode){
        this.title = vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        
        dcid = this.id;
        dc_data = {
            id: dcid,
            name: this.name
        };
        window.scrollTo(0,0);
        mDcPo.loadDatas(page,limit,dcid).then(([po_datas,paging_data])=>{
            
            datas = po_datas;
            paging = paging_data;
        }).catch(()=>{});
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "po", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, `${self.title} | Purchase Orders`),
                    m('div', {class: 'field is-horizontal is-pulled-left'}, [
                        m('div', {class: 'field-body'}, [

                            m('div', {class: 'field'}, [
                                m("p", {"class":"control has-icons-left"},[
                                    m("input", {"class":"input","type":"text","placeholder":"Search",oninput: function(e){
                                        
                                        page = 1;
                                        datas = null;
                                        m.redraw();
                                        search = e.target.value;
                                        _redraw_data(()=>{
                                            
                                            m.redraw();
                                        });
                                    }}),
                                    m("span", {"class":"icon is-small is-left"}, 
                                        m("i", {"class":"fas fa-search"})
                                    )
                                ])
                            ]),
                            m('div', {class: 'field'}, [
                                m("p", {"class":"control has-icons-left"},[
                                    m("div", {"class":"select"}, 
                                        m("select",{onchange: function(e){
                                        
                                            page = 1;
                                            datas = null;
                                            m.redraw();
                                            type = (e.target.value) ? e.target.value : undefined;
                                            _redraw_data(()=>{
                                                
                                                m.redraw();
                                            });
                                        }},[
                                            m("option", {"value":""},"- All Type -"),
                                            m("option", {"value":"order"}, "Order"),
                                            m("option", {"value":"retur"}, "Retur")
                                        ])
                                    ),
                                    m("span", {"class":"icon is-small is-left"}, 
                                        m("i", {"class":"fas fa-box"})
                                    )
                                ])
                            ]),
                            m('div', {class: 'field'}, [
                                m("p", {"class":"control has-icons-left"},[
                                    m("div", {"class":"select"}, 
                                        m("select",{onchange: function(e){
                                        
                                            page = 1;
                                            datas = null;
                                            m.redraw();
                                            status = (e.target.value) ? e.target.value : undefined;
                                            _redraw_data(()=>{
                                                
                                                m.redraw();
                                            });
                                        }},[
                                            m("option", {"value":""},"- All Status -"),
                                            m("option", {"value":"new"}, "New"),
                                            m("option", {"value":"pay"}, "Paid")
                                        ])
                                    ),
                                    m("span", {"class":"icon is-small is-left"}, 
                                        m("i", {"class":"fas fa-money-bill"})
                                    )
                                ])
                            ])
                        ])
                    ]),
                    m("div",{class:"is-clearfix"}),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Tanggal"),
                                m("th", "Type"),
                                m("th", "Kode"),
                                m("th", "Outlet"),
                                m("th", "Total"),
                                m("th", "Status"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            handle_data()
                        ])
                    ]),
                    m('nav', {class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                        m('a', {class:'pagination-previous', onclick: function(){
                            if(page > 1){

                                page--;
                                datas = null;
                                m.redraw();
                                _redraw_data(()=>{
                                    
                                    m.redraw();
                                });
                            }
                        }}, 'Previous'),
                        m('a', {class:'pagination-next', onclick: function(){
                            if(page < paging.total_page){

                                page++;
                                datas = null;
                                m.redraw();
                                _redraw_data(()=>{
                                    
                                    m.redraw();
                                });
                            }
                        }}, 'Next page'),
                        m('ul', {class: 'pagination-list'}, [ create_pagination() ] )
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcPo;