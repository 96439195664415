import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Validator from "validator"

var AddCategory = {
    filename: '',
    oninit: function(vnode) {
        this.data = {
            name: "",
            icon: "",
            image: "",
            description: "",
            active: false,
            isroastery: false
        };
        this.error = {
            name: undefined,
            icon: undefined,
            image: undefined,
            description: undefined,
            active: false,
            isroastery: false
        };
    },
    view: function(vnode) {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenu, {title: "category"})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, "Add Category"),
                      m("form",{
                        onsubmit: function(e) {
                        e.preventDefault()
                        //User.save()
                        console.log(self.data);
                        self.error.icon = !Validator.isLength(self.data.icon, {min:4, max: 20});;
                        self.error.name = !Validator.isLength(self.data.name, {min:6, max: 60});
                        
                        console.log(self.error);
                        if(!self.error.email &&
                            !self.error.name)
                            {
                                m.request({
                                    method: "POST",
                                    url: "/api/category/insert",
                                    body: self.data
                                })
                                .then(function(data) {
                                    if(data.result)
                                    {
                                        location.href = '#!/category';
                                    }
                                    else
                                    {
                                        alert('insert category gagal');
                                    }
                                });
                            }
                    }},[

                        m("div", {class: "field"}, [
                            m("label", {class:"label"}, "Name"),
                            m("div", {class:"control has-icons-left"+((self.error.name) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.name) ? ' is-danger' : ''), type:"text", placeholder:"Name",
                                oninput: function(e){
                                    self.data.name = e.target.value;
                                }}),
                                m("span", {class: "icon is-small is-left"}, [
                                    m("i", {class:"fas fa-user"})
                                ]),
                                ((self.error.name) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.name) ? m("p", {class:"help is-danger"}, "This name is invalid") : "")
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class:"label"}, "Image File"),
                            m("div", {class:"control"}, [
                                m('div', {class: 'file has-name'}, [
                                    m('label', {class: 'file-label'}, [
                                        m('input', {class:'file-input', 
                                        onchange: function(e){
                                            
                                            var file = this.files[0];
                                            var frmdata = new FormData();
                                            frmdata.append('photos[]', file, file.name);
                                            m.request({
                                                method: "POST",
                                                url: "/api/category/image/upload",
                                                body: frmdata
                                            })
                                            .then(function(data) {
                                                if(!data.result)
                                                {
                                                    alert('upload image gagal');
                                                }
                                                else
                                                {
                                                    self.filename = file.name;
                                                    self.data.image = data.data.image;
                                                    //alert('insert customer sukses ', data.data.image);
                                                }
                                            });
                                            //console.log(file);
                                            //console.log(frmdata);
                                        },
                                        type: 'file', name: 'image'}),
                                        m('span', {class: 'file-cta'}, [
                                            m('span', {class: 'file-icon'}, [
                                                m('i', {class: 'fas fa-upload'})
                                            ]),
                                            m('span', {class:'file-label'}, 'Choose a file…')
                                        ]),
                                        m('span', {class: 'file-name'}, self.filename)
                                    ])
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class: "label"}, "Icon"),
                            m("div", {class:"control has-icons-left"+((self.error.icon) ? ' has-icons-right' : '')}, [
                                m("input", {class:"input"+((self.error.icon) ? ' is-danger' : ''), type: "text", placeholder:"icon",
                                oninput: function(e){
                                    self.data.icon = e.target.value;
                                }}),
                                m("span", {class:"icon is-small is-left"}, [
                                    m("i", {class:"fas fa-envelope"})
                                ]),
                                ((self.error.icon) ?
                                          m("span", {class:"icon is-small is-right"}, [
                                              m("i", {class:"fas fa-exclamation-triangle"})
                                          ]) : "")
                            ]),
                            ((self.error.icon) ? m("p", {class:"help is-danger"}, "This icon is invalid") : "")
                        ]),
                        m("div", {class:"field"}, [
                            m("label", {class:"label"}, "Description"),
                            m("div", {class:"control"}, [
                                m("textarea", {class:"textarea", placeholder:"Description",
                                oninput: function(e){
                                    self.data.description = e.target.value;
                                }})
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.active = e.target.checked;
                                    }}),
                                    " Active"
                                ])
                            ])
                        ]),
                        m("div", {class:"field"}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.data.isroastery = e.target.checked;
                                    }, checked: self.data.isroastery}),
                                    " Roastery"
                                ])
                            ])
                        ]),
                        m("div", {class:"field is-grouped"}, [
                            m("div", {class:"control"}, [
                                m("button", {class:"button is-link"}, "Submit")
                            ]),
                            m("div", {class:"control"}, [
                                m("a", {class:"button is-text", href: "#!/category"}, "Cancel")
                            ])
                        ])

                    ])

                      
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = AddCategory;