import m from "mithril"
import Navbar from "../../component/navbar"
import LeftMenuDc from "../../component/leftmenudc"
import Footer from "../../component/footer"
import classNames from "classnames";

var mDcMasterProduct = require("../../models/dcmasterproduct")
var mDcProduct = require("../../models/dcproduct")

function ListTable(self, cust) {
    return cust.map(function(u) {
        return m("tr", {}, [
            m('td', ''),
            m('td', u.kode),
            m('td', u.name),
            m('td', u.unit),
            m('td', u.price),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    //console.log(this.getAttribute("strid"));
                    //m.route.set('/dc/salesman/edit/:pid/:id/:name', {pid: u._id, id: u.dcid, name: self.name})
                    m.route.set('/dc/product/edit/:pid/:id/:name', {pid: u.master_product_id, id: self.id, name: self.name})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

var DcProduct = {
    oninit: function(vnode){
        let self = this;
        this.title = "DC " + vnode.attrs.name;
        this.id = vnode.attrs.id;
        this.name = vnode.attrs.name;
        this.master_product = [];
        this.onnew = false;
        this.onnewtitle = 'Add New';
        this.pilihidproduct = '';

        mDcProduct.loadList(vnode.attrs.id, function(){
        });
        mDcMasterProduct.loadList(function(){
            //console.log('mDcMasterProduct.list ', mDcMasterProduct.list);
            self.master_product = [];
            for(let i=0;i<mDcMasterProduct.list.length;i++)
            {
                mDcMasterProduct.list[i].value = mDcMasterProduct.list[i]._id;
                mDcMasterProduct.list[i].label = mDcMasterProduct.list[i].name;
                mDcMasterProduct.list[i].selected = false;

                let p = {
                    value: mDcMasterProduct.list[i]._id,
                    label: mDcMasterProduct.list[i].name,
                    selected: false
                }
                self.master_product.push(p);
            }

            let element = new Choices('#idpilihmasterproduct', {
                choices: self.master_product,
              });
              element.removeActiveItems();
    
              element.passedElement.element.addEventListener(
                'addItem',
                function(event) {
                  // do something creative here...
                  //console.log(event.detail.id);
                  //console.log(event.detail.value);
                  //self.data.id_roastery = event.detail.value;
                  self.pilihidproduct = event.detail.value;
                },
                false,
              );
        });
        
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                      m(LeftMenuDc, {title: "product", id: self.id, name: self.name})
                  ]),
                  m("div", {class:"column"}, [
                      m("h1", {class:"title"}, self.title+ " Products"),
                      m("div", {class:"field is-grouped"}, [
                        m("p", {class:"control"}, [
                              m("a", {onclick: function(e){
                                self.onnew = !self.onnew;
                                if(!self.onnew)
                                {
                                    self.onnewtitle = 'Add New';
                                }
                                else
                                {
                                    self.onnewtitle = 'Cancel';
                                }
                              },class:"button is-link"}, self.onnewtitle)
                        ]),
                        m("div", {class: classNames("field", {'is-hidden': !self.onnew}), style: 'width: 400px; '}, [
                            m("div", {class:"control"}, [
                                m('select', {id: "idpilihmasterproduct", class:'input'})                    
                            ])
                        ]),
                        m("p", {class: classNames("control", {'is-hidden': !self.onnew})}, [
                            m("a", {onclick: function(e){
                                if(self.pilihidproduct)
                                {
                                    m.route.set('/dc/product/edit/:pid/:id/:name', {pid: self.pilihidproduct, id: self.id, name: self.name})
                                }
                            }, class:"button is-link"}, "Pilih")
                        ]),
                      ]),
                      m("table", {class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", ""),
                                m("th", "Kode"),
                                m("th", "Name"),
                                m("th", "unit"),
                                m("th", "Price"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                            ListTable(self, mDcProduct.list)
                        ])
                    ])
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}
module.exports = DcProduct;