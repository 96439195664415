import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import Global from "../../global"
import { each } from "async"
//import { ENGINE_METHOD_ALL } from "constants";

var loadProduct = function(tipe, self){
    let glob = Global.getInstance();
    let roastery = glob.getProductPageRoastery();
    m.request({
        method: "GET",
        url: "/api/product/getall/"+self.limit+"/"+self.current,
        //data: { limit: 10, page: 1 },
        params: {tipe: tipe, roastery: roastery},
        withCredentials: true,
    })
    .then(function(data) {
        if(data.result)
        {
            console.log(data.data);
            products = data.data;
            let mx = Math.ceil(data.count/10);
            self.max = mx;
            let arrp = pagination(data.current, mx);
            pages = [];
            for(let i=0;i<arrp.length;i++)
            {
              let a = 'a';
              let cl = 'pagination-link';
              let pg = arrp[i];
              let href = '/product/'+self.limit+'/'+pg+'/'+self.tipe;
              if(arrp[i] == '...')
              {
                  a = 'span';
                  cl = 'pagination-ellipsis';
                  href = '';
              }  
              if(data.current == arrp[i])
              {
                  cl += ' is-current';
                  href = '';
              }
              pages.push({
                  a: a,
                  class: cl,
                  page: pg+'',
                  href: href
              });
            }
            console.log(pages);
              //console.log(`Selected page ${data.current}:`, pagination(data.current, mx));

            for(var i=0;i<products.length;i++)
            {
              products[i].selected = false;
            }
        }
        else
        {
            alert('/api/product/getall/ gagal');
        }
    });
}

var pages = [];
function productsPages(self, cust) {
    return cust.map(function(u) {
        return m("li", [
            m(u.a, {onclick: function(){
                if(u.href.length>0)
                {
                    //console.log(u.href);
                    m.route.set(u.href);
                    //loadProduct(self);
                    //m.redraw();
                    //alert('test');
                    location.reload();
                    //m.redraw();
                    //location.hash = u.href;
                }
            }, class:u.class}, m.trust(u.page))
            //m('a', {class:'pagination-link'}, m.trust('1'))
        ])
    })
}

var products = [];
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function pagination(c, m) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

function StockPages(self, cust) {
    return cust.map(function(u) {
        return m("li", [
            u.store_name + ' : ' + u.sisa + ' ' + u.satuan
        ])
    })
}

const showVariant = (dtproduct)=>{

    if(dtproduct.variants !== undefined){

        if(dtproduct.variants.length > 0){

            let vr_key = {...dtproduct.variants[0]};
            delete vr_key['enabled'];
            delete vr_key['price'];
            delete vr_key['satuan'];
            delete vr_key['value'];
            delete vr_key['kode'];
            vr_key = Object.keys(vr_key);
            
            return m('div',[
                m('p',[

                    vr_key.map((ky,idx)=>{
                        return m('u', {class:"is-capitalized"}, (idx == (vr_key.length - 1)) ? `${ky}` : `${ky} / `);
                    })
                ]),
                dtproduct.variants.map((vrdata)=>{
                    
                    return m('p',[
    
                        vr_key.map((ky,idx)=>{
                            return m('span', {class:"has-text-weight-light"}, (idx == (vr_key.length - 1)) ? `${vrdata[ky]}` : `${vrdata[ky]} / `);
                        })
                    ])
                })
            ]);
        }else{
            
            return m("button", {"class":"button is-small is-info "}, 
                "No Data"
            )
        }
    }else{

        return m("button", {"class":"button is-small is-danger"}, 
            "No Variant"
        )
    }
}

function productsInputs(self, cust) {
    //console.log('KESINI ==== 3 ', cust);
    return cust.map(function(u) {

        const is_tax = (u.is_nontax !== undefined) ? (u.is_nontax ? false : true) : true; 

        return m("tr", {
            onclick: function(e){
                //console.log(e);
                
                //console.log(this.getAttribute("strid"));
                for(var i=0;i<products.length;i++)
                {
                  products[i].selected = false;
                }
                for(var i=0;i<products.length;i++)
                {
                    if(products[i]._id == this.getAttribute("strid"))
                      products[i].selected = true;
                }
            }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
        }, [
            m("td", [
                m("label", {class:"checkbox"}, [
                    //m("input", {type: "checkbox"})
                    m('input', {onclick: function(e){
                        let pilih = false;
                        for(let i=0;i<products.length;i++)
                        {
                            if(this.getAttribute("strid") == products[i]._id)
                            {
                                if(e.target.checked)
                                {
                                    //console.log(objects[i]._id+' isinya '+e.target.value);
                                    products[i].active = true;
                                    pilih = true;
                                }
                                else
                                {
                                    products[i].active = false;
                                    pilih = false;
                                }
                            }
                        }

                        m.request({
                            method: "POST",
                            url: "/api/product/set/active",
                            //data: { limit: 10, page: 1 },
                            body: { id: this.getAttribute("strid"), active: pilih},
                            withCredentials: true,
                        })
                        .then(function(data) {
                            if(data.result)
                            {
                                console.log('OK');
                                
                            }
                            else
                            {
                                alert('update active gagal');
                            }
                        });

                    },type: 'checkbox', strid: u._id, checked: (u.active) ? 'checked' : ''})
                ])
            ]),
            m("td", [u.no]),
            // m("td", [
            //     m('figure', {class: 'image is-128x128'}, [
            //         m('img', {src: '/upload/'+u.image})
            //     ])
            // ]),
            m("td", [u.name]),
            m("td", [u.category_name]),
            m("td", {class: 'has-text-right'}, [ formatNumber(u.price)]),
            m("td", [ 
                m('ul', StockPages(self, u.sisa_stock))
            ]), //u.sisa_stock
            m("td", [
                showVariant(u)
            ]),
            m("td", [
                m("a", {class:(is_tax) ? "icon has-text-success" : "icon has-text-danger", strid: u._id,
                onclick: function(){
                    const pid = this.getAttribute("strid");
                    if(is_tax){

                        const r = confirm(`Unset Tax untuk product ${u.name}?`);
                        if (r == true) {

                            m.request({
                                method: "PUT",
                                url: `/api/products/unset_tax`,
                                body:{
                                    product_id:pid
                                },
                                withCredentials: true
                            }).then((res_tax)=>{
    
                                if(res_tax.code == 200){
    
                                    alert(`Unset Tax Sukses`);
                                    location.reload();
                                }else{
    
                                    alert(`API Response ${res_tax.code}`);
                                }
                            }).catch((err)=>{
    
                                alert(`Error Response ${err.message}`);
                            });
                        }
                    }else{

                        const r = confirm(`Set Tax untuk product ${u.name}?`);
                        if (r == true) {

                            m.request({
                                method: "PUT",
                                url: `/api/products/set_tax`,
                                body:{
                                    product_id:pid
                                },
                                withCredentials: true
                            }).then((res_tax)=>{
    
                                if(res_tax.code == 200){
    
                                    alert(`Set Tax Sukses`);
                                    location.reload();
                                }else{
    
                                    alert(`API Response ${res_tax.code}`);
                                }
                            }).catch((err)=>{
    
                                alert(`Error Response ${err.message}`);
                            });
                        }
                    }
                }}, [
                    m("i", {class:(is_tax) ? "fas fa-check" : "fas fa-close"})
                ])
            ]),
            m("td", [
                m("a", {class:"icon", strid: u._id,
                onclick: function(){
                    console.log(this.getAttribute("strid"));
                    m.route.set('/product/edit/:id', {id: this.getAttribute("strid")})
                }}, [
                    m("i", {class:"fas fa-edit"})
                ])
            ])
        ])
    })
}

function categoryInputs(selected, cust) {
    return cust.map(function(u) {
        //return m('option', {value: u._id}, u.name)
        return m('option', {value: u._id, selected: (selected == u._id) ? 'true' : ''}, u.name)
    })
}

var Products = {
    current: 1,
    limit: 10,
    max: 1,
  oninit: function(vnode) {
      let self = this;
      self.onSearch = false;
      self.roastery = false;
      self.txtsearch = '';
      self.tipe = vnode.attrs.tipe;
      self.current = parseInt(vnode.attrs.page);
      self.limit = parseInt(vnode.attrs.limit);
      self.productsearch = [];

      let glob = Global.getInstance();
      glob.setProductPageLimit(self.limit);
      glob.setProductPageOffset(self.current);
      glob.setProductPageType(self.tipe);
      self.roastery = glob.getProductPageRoastery();

      self.categories = [];
      m.request({
            method: "POST",
            url: "/api/outlets/getall",
            body: { limit: 10, page: 1 },
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                console.log(data.data);
                self.categories = [];
                self.categories.push({
                    _id: 'ALL',
                    name: 'ALL'
                });
                for(let i=0;i<data.data.length;i++)
                {
                    self.categories.push(data.data[i]);
                }
                //self.categories = data.data;
            }
            else
            {
                alert('/api/outlets/getall gagal');
            }
        });

      loadProduct(self.tipe, self);
  },
  oncreate: function(){
    
   //setTimeout(function(){ window.scrollTo(0, 0); }, 500);
  },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "products"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Product"),
                    m("div", {class:"field is-grouped"}, [
                        m("p", {class:"control"}, [
                            m("a", {class:"button is-link", onclick: function(){
                                m.route.set('/product/add');
                            }}, "Add New")
                        ]),
                        m("div", {class:"control"}, [
                            m('div', {class:'select'}, [
                                m('select', {
                                    onchange: function(e){
                                        //console.log(e);
                                        self.tipe = e.target.value;
                                        loadProduct(self.tipe, self);
                                    }}, [
                                    categoryInputs(self.tipe, self.categories)
                                ])
                            ])
                        ]),
                        m('div', {class:'field has-addons'}, [
                            m('div', {class:'control'}, [
                                m('input', {class:'input', oninput: function(e){
                                    self.txtsearch = e.target.value;
                                },type:'text', placeholder:'Find a Product'})
                            ]),
                            m('div', {class:'control'}, [
                                m('a', {class:'button is-info', onclick: function(){
                                    if(self.txtsearch.length > 2)
                                    {
                                        self.onSearch = true;

                                        m.request({
                                            method: "POST",
                                            url: "/api/product/search",
                                            body: { search: self.txtsearch.trim(), tipe: self.tipe, roastery: self.roastery },
                                            withCredentials: true,
                                        })
                                        .then(function(data) {
                                            if(data.result)
                                            {
                                                console.log(data.data);
                                                self.productsearch = data.data;
                                            }
                                            else
                                            {
                                                alert('/api/products/search gagal');
                                            }
                                        });
                                    }
                                    else if(self.txtsearch.length == 0)
                                    {
                                        self.onSearch = false;
                                    }
                                    else
                                    {
                                        alert('minimal 3 huruf, untuk melakukan pencarian');
                                    }
                                    
                                }}, 'Search')
                            ])
                        ]),
                        m("div", {class:"field", style: 'margin-left: 20px; '}, [
                            m("div", {class:"control"}, [
                                m("label", {class:"checkbox"}, [
                                    m("input", {type:"checkbox",
                                    onclick: function(e){
                                        self.roastery = e.target.checked;
                                        let glob = Global.getInstance();
                                        glob.setProductPageRoastery(self.roastery);
                                        loadProduct(self.tipe, self);
                                    }, checked: (self.roastery) ? 'checked' : ''}),
                                    " Roastery"
                                ])
                            ])
                        ]),
                    ]),
                    m("table", {style:(self.onSearch) ? 'display: none; ' : '', class: "table is-fullwidth is-narrow is-hoverable"}, [
                      m("thead", [
                          m("tr", [
                              m("th", "Active"),
                              m("th", "No"),
                            //   m("th", ""),
                              m("th", "Name"),
                              m("th", "Category"),
                              m("th", "Price"),
                              m("th", "Stock"),
                              m("th", "Variants"),
                              m("th", "Tax"),
                              m("th", "")
                          ])
                      ]),
                      m("tbody", [
                        productsInputs(self, products)
                      ])
                    ]),
                    m('nav', {style:(self.onSearch) ? 'display: none; ' : '', class: 'pagination', role:"navigation", "aria-label": 'pagination'}, [
                        m('a', {class:'pagination-previous', onclick: function(){
                            if(self.current > 1)
                            {
                                m.route.set('/product/'+self.limit+'/'+(self.current-1)+'/'+self.tipe);
                                location.reload();
                            }
                            
                        }}, 'Previous'),
                        m('a', {class:'pagination-next', onclick: function(){
                            if(self.current < self.max)
                            {
                                m.route.set('/product/'+self.limit+'/'+(self.current+1)+'/'+self.tipe);
                                location.reload();
                            }
                            
                        }}, 'Next page'),
                        m('ul', {class: 'pagination-list'}, [ productsPages(self, pages) ] )
                    ]),
                    m("table", {style:(self.onSearch) ? '' : 'display: none; ', class: "table is-fullwidth is-narrow is-hoverable"}, [
                        m("thead", [
                            m("tr", [
                                m("th", "Active"),
                                m("th", "No"),
                              //   m("th", ""),
                                m("th", "Name"),
                                m("th", "Category"),
                                m("th", "Price"),
                                m("th", "Stock"),
                                m("th", "Variants"),
                                m("th", "Tax"),
                                m("th", "")
                            ])
                        ]),
                        m("tbody", [
                          productsInputs(self, self.productsearch)
                        ])
                      ]),
                  ])
              ])
          ]),
          m("section", {class:"section"}, [
              m(Footer)
          ])
        ]);
      }
}

module.exports = Products;