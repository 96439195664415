import m from "mithril"
import moment from 'moment'

var General = {
    filename: '',
    oninit: function(vnode){
        //loadDataPotitions(vnode.attrs.id)
        let self = this;
        this.data = {
            name: 'test',
            verify: false,
            avatar: '',
            created_at: ''
        }

        let url = '/api/auth/get';
        m.request({
            method: "POST",
            url: url,
            body: {},
            withCredentials: true,
        })
        .then(function(data) {
            if(data.result)
            {
                //location.href = '/';
                self.data.avatar = data.data.avatar;
                self.data.name = data.data.username;
                self.data.email = data.data.email;
                self.data.created_at = moment(data.data.created_at).fromNow();
                self.data.verify = data.data.active;
            }
            else
            {
                alert(data.error[0].message);
            }
        });
    },
    view: function() {
        var self = this;
        return m('div', {class: 'columns'}, [
            m('div', {class: 'column is-one-fifth'}, [
                m('div', [
                    m('figure', {class: 'image is-128x128'}, [
                        m('img', {src: self.data.avatar})
                    ]),
                    m('label', {class: 'file-label'}, [
                        m('input', {class:'file-input', 
                        onchange: function(e){
                            
                            var file = this.files[0];
                            var frmdata = new FormData();
                            frmdata.append('photos[]', file, file.name);
                            m.request({
                                method: "POST",
                                url: "/api/profile/image/upload",
                                body: frmdata
                            })
                            .then(function(data) {
                                if(!data.result)
                                {
                                    alert('upload image gagal');
                                }
                                else
                                {
                                    self.filename = file.name;
                                    self.data.avatar = data.data.image;
                                    
                                    //alert('insert customer sukses ', data.data.image);
                                }
                            });
                            //console.log(file);
                            //console.log(frmdata);
                        },
                        type: 'file', name: 'image'}),
                        m('span', {class: 'file-cta'}, [
                            m('span', {class: 'file-icon'}, [
                                m('i', {class: 'fas fa-upload'})
                            ]),
                            m('span', {class:'file-label'}, 'Choose a file…')
                        ]),
                        m('span', {class: 'file-name'}, self.filename)
                    ])
                ]),
                m('div', 'since : '+self.data.created_at)
            ]),
            m('div', {class: 'column'}, [
                m('div', [
                    m('div', 'name : ' + self.data.name)
                ]),
                m('div', [
                    m('div', 'email : ' + self.data.email)
                ]),
                m('button', {class: 'button', onclick: function(){
                    alert('change password');
                }}, 'change password'),
                m('div', [
                    m('div', 'Verify : ' + self.data.verify),
                    (self.data.verify ? '' : m('a', {onclick: function(){
                        m.request({
                            method: "POST",
                            url: "/api/auth/send/email/registration",
                            body: {}
                        })
                        .then(function(data) {
                            if(!data.result)
                            {
                                alert('send email registration gagal');
                            }
                            else
                            {
                                alert('send email registration sukses');
                            }
                        });
                    }}, 'Send Verification Email'))
                ]),
            ])
        ]);
    }
}

module.exports = General;