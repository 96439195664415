import m from "mithril"

var Setting = {
    oninit: function(vnode){
        //loadDataPotitions(vnode.attrs.id)
    },
    view: function() {
        var self = this;
        return m('div', 'Setting');
    }
}

module.exports = Setting;
