import m from "mithril"
import Table from "../../component/table"
import Navbar from "../../component/navbar"
import LeftMenu from "../../component/leftmenu"
import Footer from "../../component/footer"
import moment from "moment"
import "moment/locale/id"
import Notification from "../../component/notification"

function number_format(number, decimals, dec_point, thousands_sep) {
  // http://kevin.vanzonneveld.net
  // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +     bugfix by: Michael White (http://getsprink.com)
  // +     bugfix by: Benjamin Lupton
  // +     bugfix by: Allan Jensen (http://www.winternet.no)
  // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +     bugfix by: Howard Yeend
  // +    revised by: Luke Smith (http://lucassmith.name)
  // +     bugfix by: Diogo Resende
  // +     bugfix by: Rival
  // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
  // +   improved by: davook
  // +   improved by: Brett Zamir (http://brett-zamir.me)
  // +      input by: Jay Klehr
  // +   improved by: Brett Zamir (http://brett-zamir.me)
  // +      input by: Amir Habibi (http://www.residence-mixte.com/)
  // +     bugfix by: Brett Zamir (http://brett-zamir.me)
  // +   improved by: Theriault
  // +   improved by: Drew Noakes
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'
  var n = !isFinite(+number) ? 0 : +number, 
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      toFixedFix = function (n, prec) {
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          var k = Math.pow(10, prec);
          return Math.round(n * k) / k;
      },
      s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

function pagination(currentPage, nrOfPages) {

	var pagesToShow = 3,
	    page = currentPage - pagesToShow > 0 ?  (currentPage - pagesToShow) : 1,
	    first = 0,
	    pageList = [];

		 for (let i = 0; i < (pagesToShow * 2) && page < nrOfPages; i++) {
			pageList.push(page);
                        page++;

		}
          if(pagesToShow + 2  < currentPage)
	     pageList.unshift('...');
	     pageList.unshift(1); //add first page
	
	  if(nrOfPages > 1){
	         if(nrOfPages - pagesToShow  >  currentPage)
		 pageList.push('...');
	         pageList.push(nrOfPages); //add last page
	  }

	 
    return pageList;

}

var loadTrxSessions = function(self){

  m.request({
      method: "GET",
      url: "/api/postrx/roastery/getall/"+self.limit+"/"+self.current,
      //data: { limit: 10, page: 1 },
      params: {tipe: self.tipe},
      withCredentials: true,
  })
  .then(function(data) {
      if(data.result)
      {
          let trxst = data.data;
          //console.log('trxs ', trxs);
          let mx = Math.ceil(data.count/10);
          self.max = mx;
          //console.log('data.current ', data.current);
          //console.log('mx ', mx);
          let arrp = pagination(data.current, mx);
          let ada1 = false;
          let ada1double = false;
          for(let ii=0;ii<arrp.length;ii++)
          {
              if(ada1)
              {
                  if(arrp[ii] == 1)
                  {
                      ada1double= true;
                  }
              }
              if(!ada1)
              {
                  if(arrp[ii] == 1)
                  {
                      ada1= true;
                  }
              }
          }
          if(ada1double)
          {
              arrp.splice(0, 1);
          }
          //console.log('arrp ', arrp);
          pages = [];
          for(let i=0;i<arrp.length;i++)
          {
            let a = 'a';
            let cl = 'pagination-link';
            let pg = arrp[i];
            let href = '/roasterytransactions/'+self.limit+'/'+pg+'/'+self.store;
            if(arrp[i] == '...')
            {
                a = 'span';
                cl = 'pagination-ellipsis';
                href = '';
            }  
            if(data.current == arrp[i])
            {
                cl += ' is-current';
                href = '';
            }
            pages.push({
                a: a,
                class: cl,
                page: pg+'',
                href: href
            });
          }
          //console.log(pages);
            //console.log(`Selected page ${data.current}:`, pagination(data.current, mx));

          for(var i=0;i<trxst.length;i++)
          {
              trxst[i].selected = false;
          }
          self.trxs = trxst;
          console.log('KESINI ==== 1 ', self.trxs.length);
      }
      else
      {
          console.log("/api/postrx/roastery/getall/"+self.limit+"/"+self.current);
          Notification.setBody('Failed to load page');
          Notification.setType('is-danger');
          Notification.show(function(){
              Notification.close();
          });
      }
  });
}

function trxInputs(self, trxs) {
  //console.log('KESINI ==== 2 ', trxs);
  return trxs.map(function(u) {
      let stop = moment(u.stop);
      if(stop.isValid())
      {
          stop = moment(u.stop).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a');
      }
      else
      {
          stop = '';
      }
      //console.log('ok ', u);
      //console.log('u ', ((u.cash>0) ? 'CASH : ' + formatNumber(u.cash) + '<br />' : ''));

      return m("tr", {
          onclick: function(e){
              //console.log(e);
              
              //console.log(this.getAttribute("strid"));
              for(var i=0;i<trxs.length;i++)
              {
                  trxs[i].selected = false;
              }
              for(var i=0;i<trxs.length;i++)
              {
                  if(trxs[i]._id == this.getAttribute("strid"))
                      trxs[i].selected = true;
              }
              //console.log(trxs);
          }, strid: u._id, class:""+((u.selected) ? ' is-selected' : '')
      }, [
          /*m("td", [
              m("label", {class:"checkbox"}, [
                  m("input", {type: "checkbox"})
              ])
          ]),*/
          m("td", [u.no]),
          m("td", [moment(u.start).locale('id').format('dddd, Do MMMM YYYY, h:mm:ss a')]),
          m("td", [stop]),
          m("td", [u.kasir_name]),
          m("td", [u.store_name]),
          m("td", {class: 'has-text-right'}, [ number_format(u.totalsourceqty, 0, ',', '.') ]),
          m("td", {class: 'has-text-right'}, [ number_format(u.totaltargetqty, 0, ',', '.') ]), //; formatNumber(u.total)
          m("td", {class: 'has-text-right'}, [ number_format(u.jumlah, 0, ',', '.') ]),
          m("td", [
              m("a", {class:"icon", strid: u.uuid,
              onclick: function(){
                  console.log(this.getAttribute("strid"));
                  m.route.set('/roasterytransactions/view/:id', {id: this.getAttribute("strid")})
              }}, [
                  m("i", {class:"fas fa-edit"})
              ])
          ])
      ])
  })
}

var pages = [];
function SessionsPages(self, cust) {
    return cust.map(function(u) {
        return m("li", [
            m(u.a, {onclick: function(){
                if(u.href.length>0)
                {
                    //console.log(u.href);
                    m.route.set(u.href);
                    //loadProduct(self);
                    //m.redraw();
                    //alert('test');
                    location.reload();
                    //m.redraw();
                    //location.hash = u.href;
                }
            }, class:u.class}, m.trust(u.page))
            //m('a', {class:'pagination-link'}, m.trust('1'))
        ])
    })
}

var RoasteryTransactions = {
    oninit: function(vnode) {
      let self = this;
      self.trxs = [];
      self.current = parseInt(vnode.attrs.page);
      self.limit = parseInt(vnode.attrs.limit);

      loadTrxSessions(self);
    },
    view: function() {
        let self = this;
        return m("div", [
          m("section", {class:"section"}, [
              m(Navbar)
          ]),
          m("section", {class:"section", style:"margin-top: -120px; "}, [
              m("div", {class:"columns"}, [
                  m("div", {class:"column is-one-fifth"}, [
                    m(LeftMenu, {title: "Roastery Transactions"})
                  ]),
                  m("div", {class:"column"}, [
                    m("h1", {class:"title"}, "Roastery Process"),
                    m('div', {style:'overflow-x: auto;'}, [
                        m("table", {class: "table is-fullwidth is-narrow is-hoverable", style:'font-size: 13px; '}, [
                            m("thead", [
                                m("tr", [
                                    //m("th", ""),
                                    m("th", "No"),
                                    m("th", "Mulai"),
                                    m("th", "Selesai"),
                                    m("th", "Kasir"),
                                    m("th", "Store"),
                                    m("th", "Source Qty"),
                                    m("th", "Target Qty"),
                                    m("th", "Total Proses"),
                                    m("th", "")
                                ])
                            ]),
                            m("tbody", [
                              trxInputs(self, self.trxs)
                            ])
                          ]),
                    ]),
                    m('nav', {class: 'pagination', style: 'margin-top: 30px; ', role:"navigation", "aria-label": 'pagination'}, [
                        m('a', {class:'pagination-previous', onclick: function(){
                            if(self.current > 1)
                            {
                                m.route.set('/roasterytransactions/'+self.limit+'/'+(self.current-1)+'/'+self.store);
                                location.reload();
                            }
                            
                        }}, 'Previous'),
                        m('a', {class:'pagination-next', onclick: function(){
                            if(self.current < self.max)
                            {
                                m.route.set('/roasterytransactions/'+self.limit+'/'+(self.current+1)+'/'+self.store);
                                location.reload();
                            }
                            
                        }}, 'Next page'),
                        m('ul', {class: 'pagination-list'}, [ SessionsPages(self, pages) ] )
                    ]),
                  ])
                ])
            ])
        ])
    }
}
module.exports = RoasteryTransactions;